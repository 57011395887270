.card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    animation: fadeIn 0.5s ease-out;
  
    &:hover {
      transform: translateY(-5px);
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .couponHeader {
    margin-bottom: 10px;
  
    h2 {
      font-size: 1.5em;
      color: #333;
    }
  }
  
  .couponBody {
    display: flex;
    gap: 10px;
  
    .couponInput {
      flex: 1;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      transition: border-color 0.3s;
  
      &:focus {
        border-color: #28a745;
        outline: none;
      }
    }
  
    .applyButton {
      padding: 10px 20px;
      background-color: #28a745;
      color: #fff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #218838;
      }
    }
  }
  
  .successMessage {
    color: green;
    margin-top: 10px;
  }
  