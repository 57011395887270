.parallax_banner {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.carousel_track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.carousel_slide {
  flex: 0 0 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  &.active {
    opacity: 1;
    transform: translateY(0);
    .content {
      opacity: 1;
      transform: translateY(0);
    }

    .content h2,
    .content p {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content {
  text-align: center;
  max-width: 600px;
  color: #fff;
  z-index: 10;
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  h2,
  p {
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 0.5s ease-in-out 0.5s, transform 0.5s ease-in-out 0.5s; /* Delayed animation */
  }

  h2 {
    font-size: 2.5rem;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    margin: 10px 0;
  }
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;

  .dot {
    width: 12px;
    height: 12px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;

    &.active {
      background: #fff;
    }
  }
}
