// ChevronBreadcrumbs.module.scss
.breadcrumbContainer {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .breadcrumbItem {
    display: flex;
    align-items: center;
    animation: slideIn 0.5s ease-in-out;
  
    @keyframes slideIn {
      from {
        transform: translateX(-10%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
  
    a {
      color: #333;
      text-decoration: none;
      padding: 0 5px;
      transition: color 0.3s ease;
  
      &:hover {
        color: #555;
      }
    }
  
    .separator {
      margin: 0 8px;
      color: #999;
    }
  }
  