.orderConfirmation {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
    text-align: center;
  
    .iconContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
  
      .icon {
        font-size: 3em;
        color: #28a745;
      }
    }
  
    h2 {
      font-size: 2em;
      color: #333;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 1em;
      color: #555;
    }
  
    .details {
      margin: 20px 0;
  
      p {
        margin: 5px 0;
      }
  
      strong {
        color: #000;
      }
    }
  
    .items {
      margin: 20px 0;
  
      h3 {
        margin-bottom: 10px;
        font-size: 1.5em;
        color: #444;
      }
  
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  
    button {
      padding: 12px 25px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #218838;
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  