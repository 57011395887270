.promotionCard {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px 0;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;
  max-width: calc(100% - 20px * 2);
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    font-size: 1.5rem;
    color: #0f3460;
    margin: 0;
    font-weight: 600;
  }
}

.description {
  color: #666;
  font-size: 1rem;
  margin-bottom: 16px;
}

.promotionDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .promotionType {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .promotionDate {
    color: #444;
    font-size: 0.9rem;
  }
}

.cardFooter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .editButton, .deleteButton {
    background: none;
    border: none;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
  }

  .editButton {
    background-color: #e3f2fd;
    color: #1976d2;

    &:hover {
      background-color: #bbdefb;
    }
  }

  .deleteButton {
    background-color: #ffebee;
    color: #d32f2f;

    &:hover {
      background-color: #ffcdd2;
    }
  }

  svg {
    margin-right: 8px;
  }
}
