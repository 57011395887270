.hero_section {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero_overlay {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  background: radial-gradient(circle, rgba(173, 216, 230, 0.8), rgba(255, 255, 255, 0.9));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.hero_image {
  width: 60vh;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 600px) {
  .hero_image {
    width: 90%;
    max-height: 40%;
  }
}

.hero_content {
  text-align: center;
  color:  #333333;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero_title {
  font-size: 3em;
  margin-bottom: 0.5em;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add shadow for the title */
}

.hero_subtitle {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.hero_button {
  background-color: #007bff;
  color: white !important;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.hero_button:hover {
  background-color: #0056b3;
}
