@import '../../../variables.scss';

.filters {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: rgba(255, 255, 255, 0.3); /* Translucent background */
  border-radius: 15px; /* Rounded corners for a smooth look */
  backdrop-filter: blur(10px); /* Blur effect for the glassmorphism */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Light border to enhance the glass effect */
  animation: fadeIn 0.5s ease-out; /* Subtle fade-in animation */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: $margin-medium 0;

  &:hover {
    background: rgba(255, 255, 255, 0.35); /* Slight change on hover */
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }

  .filterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
      font-size: 1.5rem;
      color: $primary-color;
    }

    .resetButton {
      background: transparent;
      border: none;
      color: $secondary-color;
      cursor: pointer;
      font-size: 0.9rem;
      text-decoration: underline;

      &:hover {
        color: darken($secondary-color, 10%);
      }
    }
  }

  .selectRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px; /* Increase spacing for a more relaxed layout */

    > * {
      flex: 1; /* Ensure all elements take up equal space */
      min-width: 120px;
    }

    /* Align input fields more uniformly */
    input, select {
      border-radius: 8px;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: border-color 0.3s ease;
      
      &:focus {
        border-color: $primary-color;
      }
    }
  }

  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .selectRow {
      flex-direction: column; /* Switch to vertical layout */
      gap: 15px; /* Increase spacing for better readability */
    }
  }
}
