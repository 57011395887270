.gradientOverlayBanner {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
  
    .slide {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 1s ease-in-out;
  
      &.active {
        opacity: 1;
      }
    }
  
    .media {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
      z-index: 1;
    }
  
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #fff;
      z-index: 2;
  
      h2 {
        font-size: 2.5rem;
        margin: 0;
        animation: fadeInUp 1.5s ease-in-out;
      }
  
      p {
        font-size: 1.2rem;
        margin: 10px 0;
        animation: fadeInUp 1.5s ease-in-out;
      }
  
      .button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #ff6f61;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;
        margin-top: 10px;
        animation: fadeInUp 1.5s ease-in-out;
      }
    }
  
    .controls {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 10px;
      z-index: 3;
  
      .dot {
        width: 12px;
        height: 12px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.3s ease;
  
        &.active {
          background: #fff;
        }
      }
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  