.contactForm {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  animation: fadeIn 0.5s ease-out;

  .glassContainer {
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    text-align: center;

    .title {
      font-size: 2em;
      margin-bottom: 20px;
      color: #333;
    }

    .formGroup {
      position: relative;
      margin-bottom: 20px;

      .input,
      .textarea,
      .select {
        width: calc(100% - 20px);
        padding: 10px;
        border: none;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.7);
        outline: none;
        font-size: 1em;
        color: #333;
        transition: background-color 0.3s, box-shadow 0.3s;
        appearance: none;
        -webkit-appearance: none; /* For Safari */

        &:focus {
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }

        &::placeholder {
          color: transparent;
        }

        &:focus + .label,
        &.filled  + .label {
          transform: translateY(-20px);
          font-size: 12px;
          color: #007bff;
        }
      }

      .select {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjE1Ij48cGF0aCBmaWxsPSIjNzk3OTc5IiBkPSJNNSAxMEw4IDEzIDEzIDVMNSAxMHoiLz48L3N2Zz4=');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 12px;
        cursor: pointer;
        width: 100%;
        &:focus {
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
      }

      .label {
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 1em;
        color: #666;
        pointer-events: none;
        transition: transform 0.3s, font-size 0.3s;

      }
    }

    .submitButton {
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
