.combinationEditor {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .generateButton {
      margin-top: 0px;
      margin-bottom: 20px;
      background-color: #4A90E2;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }
  
    .combination {
      background-color: white;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: grey solid 1px;
    .cominationRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        align-items: flex-end;
      }
    }
  
    .combinationTitle {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 16px;
        .option {
            margin: 0px;
            font-size: 16px;
        }
    }
  
    .saveButton {
      margin-top: 0px;
      margin-top: 20px;
      background-color: #4CAF50;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }
  }
  