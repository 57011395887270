.reviewContainer {
    max-width: 600px;
    margin: auto;
    text-align: center;
  
    .stars {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
  
      .star {
        font-size: 30px;
        color: #ffd700;
        cursor: pointer;
        transition: transform 0.3s;
  
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  
    .textArea {
      width: 100%;
      height: 100px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
      font-size: 14px;
      resize: none;
  
      &:focus {
        border-color: #00796b;
      }
    }
  
    .submitButton {
      background-color: #00796b;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.3s;
  
      &:hover {
        background-color: #004d40;
        transform: scale(1.05);
      }
    }
  }
  