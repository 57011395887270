.card {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
    animation: fadeIn 0.5s ease-out;
    color: #fff;
  
    &:hover {
      transform: translateY(-10px);
    }
  
    .photo {
      width: 100%;
      height: 300px;
      object-fit: cover;
      filter: brightness(0.8);
      transition: filter 0.3s;
  
      &:hover {
        filter: brightness(1);
      }
    }
  
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      padding: 20px;
      transition: background 0.3s;
  
      h2 {
        margin: 0;
        font-size: 1.8em;
      }
  
      .position {
        color: #ddd;
        margin: 10px 0;
      }
  
      .bio {
        font-size: 1em;
      }
  
      .socialLinks {
        margin-top: 10px;
  
        a {
          margin: 0 10px;
          font-size: 1.4em;
          color: #fff;
          transition: color 0.3s;
  
          &:hover {
            color: #00bfff;
          }
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  