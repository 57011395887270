$primary-color: #6a11cb;
$secondary-color: #2575fc;
$hover-color: #ffffff33; // Semi-transparent white for hover effect
$active-color: #ffffff99; // Semi-transparent white for active effect
$shadow-color: rgba(0, 0, 0, 0.15); // Shadow color

#button_8 {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(45deg, $primary-color, $secondary-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        box-shadow: 0 8px 15px $shadow-color;
    }

    &:active {
        transform: scale(0.95);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $hover-color;
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    &:hover::before {
        opacity: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $active-color;
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    &:active::after {
        opacity: 1;
    }
}