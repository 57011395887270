.trustSignals {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
  
    .trustItem {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
  
      .icon {
        font-size: 2.5em;
        color: #007bff;
        margin-bottom: 10px;
        transition: transform 0.3s;
      }
  
      p {
        font-size: 1em;
        color: #555;
        font-weight: bold;
      }
  
      &:hover .icon {
        transform: scale(1.1);
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  