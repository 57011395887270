.campaignInfoContainer {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100%;
    margin: 20px auto;
  
    .title {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: center;
      color: #333;
    }
  
    .infoRow {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
  
      .icon {
        color: grey;
        font-size: 1.2rem;
        margin-right: 12px;
      }
  
      .label {
        font-weight: bold;
        font-size: 1rem;
        color: #333;
        flex-basis: 150px;
      }
  
      .value {
        font-size: 1rem;
        color: #555;
      }
    }
  
    .divider {
      height: 1px;
      background-color: #ddd;
      margin: 8px 0;
    }
  
    .sent {
      color: #4caf50;
    }
  
    .draft {
      color: #e91e63;
    }
  }
  