@import '../../variables.scss';

.dashboard {
    background-color: $background-color;
    .content {
        width: calc(100%);
    }
    h1 {
        font-size: 2rem;
        color: $primary-color;
        margin-bottom: 20px;
    }
}