@import '../../../../../variables.scss';

.ticketCard {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    margin-bottom: 16px;
    margin-top: $margin-medium;
    &:hover {
        transform: translateY(-5px);
    }
}

.ticketHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;

    h3 {
        font-size: 1.2rem;
        color: #333;
    }

    .status {
        background-color: #f0f0f0;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 0.9rem;
        color: #777;
    }
}

.ticketBody {
    p {
        margin: 8px 0;
        font-size: 0.95rem;
        color: #555;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
            color: #777;
        }
    }
}

.ticketFooter {
    margin-top: 15px;
    p {
        font-size: 0.9rem;
        color: #777;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
            color: #777;
        }
    }

    .tags {
        margin-top: 10px;

        .tag {
            background-color: #e3f2fd;
            color: #1e88e5;
            padding: 4px 8px;
            border-radius: 3px;
            font-size: 0.8rem;
            margin-right: 5px;
        }
    }
}

// Priority Specific Styling
.low {
    color: #00c853;
}

.medium {
    color: #ffab00;
}

.high {
    color: #ff6f00;
}

.urgent {
    color: #d32f2f;
}
