@import "../../../variables.scss";


.card {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-12px);
      box-shadow: 0 16px 30px rgba(0, 0, 0, 0.2);
    }
  }
  
  .imageContainer {
    width: 100%;
    padding-top: 65%;
    position: relative;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    padding: 25px;
    text-align: left;
  }
  
  .name {
    font-size: 1.3rem;
    margin: 10px 0;
    color: #111;
  }
  
  .description {
    font-size: 1rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-size: 1.2rem;
    color: #00695c;
    font-weight: bold;
  }
  
  .discountPrice {
    font-size: 1rem;
    color: #d32f2f;
    text-decoration: line-through;
  }
  