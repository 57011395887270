.customerSupport {
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #fff;
    animation: slideIn 0.6s ease-out;
  
    h2 {
      font-size: 2em;
      margin-bottom: 10px;
      color: #fff;
    }
  
    p {
      font-size: 1.1em;
      color: #ddd;
    }
  
    .contactMethods {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
  
      .method {
        display: flex;
        align-items: center;
  
        .icon {
          font-size: 2.5em;
          margin-right: 10px;
          color: #00bfff;
          transition: transform 0.3s;
        }
  
        a {
          font-size: 1em;
          color: #00bfff;
          text-decoration: none;
        }
      }
  
      &:hover .icon {
        transform: scale(1.2);
      }
    }
  
    .workingHours {
      font-size: 1em;
      color: #bbb;
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  