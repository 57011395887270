@import '../../../variables.scss';


.bannerContainer {
    background-size: cover;
    background-position: center;
    position: relative;
    height: 300px;
    width: calc(100% - $margin-medium * 2);
    padding: $margin-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    transition: transform 0.5s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
    }
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 0;
    }
  
    .bannerContent {
      position: relative;
      z-index: 1;

    }
  
    h1 {
      font-size: 2.5em;
      margin: 0;
      animation: fadeInDown 1s;
    }
  
    h2 {
      font-size: 1.5em;
      margin: 10px 0;
      animation: fadeInUp 1s;
      color: white !important;
      margin-bottom: 20px !important;

    }
  
    .callToAction {
      padding: 10px 20px;
      background: #00796b;
      color: white;
      border-radius: 5px;
      text-decoration: none;
      transition: background 0.3s;
      &:hover {
        background: #004d40;
      }
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  