@import '../../../../variables.scss';


.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: white;
}

.label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    align-items: center;
    display: flex;
    svg {
        margin: 0px;
        padding: 0px;
        margin-right: $margin-medium;
        
    }
}

.socialMediaLinks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.socialMediaIcon {
    width: 50px;
    height: auto;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
    }
}
