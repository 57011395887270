.carouselContainer {
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
    padding: 20px;
    position: relative;
    height: 500px; /* Set a fixed height to allow scrolling */
    animation: slideIn 0.5s ease;
    box-sizing: border-box; /* Ensure padding is included in height */
  
    .carousel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; /* Wrap items into next column when overflow occurs */
      height: 100%;
      gap: 15px;
      transition: transform 0.3s ease;
      align-content: flex-start; /* Align items to the start of the flex container */
      justify-content: flex-start;
    }
  }
  