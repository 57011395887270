@import '../../../variables.scss';


.discount_stats_overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $margin-medium;
    gap:$margin-medium
}
