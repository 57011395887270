.orderSummary {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    max-width: calc(100% - 20px * 2);
}

h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.summaryRow {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 8px 0;
    color: #555;
}

.totalRow {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.separator {
    border: 0;
    height: 1px;
    background-color: #e0e0e0;
    margin: 20px 0;
}
