@import '../../../../variables.scss';

$deep-navy: #2c3e50; // Modern deep navy color for contrast
$accent-color: $deep-navy; // You can replace $accent-color with $deep-navy

.buttonAddToCart {
  background-color: $deep-navy;
  color: white;
  border: none;
  padding: 10px 24px;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  position: relative;
  overflow: hidden;

  // Button hover effect
  &:hover {
    background-color: lighten($deep-navy, 10%); // Slightly lighter navy on hover
    transform: translateY(-2px); // Slight lift on hover
  }

  // When added to cart
  &.added {
    background-color: lighten($deep-navy, 20%);
    transform: scale(1.1);
  }

  .icon {
    font-size: 20px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;
    margin-right: 10px;
  }

  // Subtle cart animation on hover
  &:hover .icon {
    transform: translateY(-5px) scale(1.2); // Moves up and scales slightly
    opacity: 0.8; // Slightly lower opacity for a smoother effect
  }

  // Button label text to follow icon
  .text {
    transition: transform 0.3s;
    color: white;
    margin: 0px;
    padding: 0px;
  }

  &:hover .text {
    transform: translateY(-2px); // Slight text movement for consistency
  }
}
