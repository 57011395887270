@import '../../../../variables.scss';

.section {
    background-color: white;
    box-shadow: $box-shadow1;
    padding: $margin-medium;
    border-radius: 8px;
    margin-top: $margin-medium;
    .row_calendar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
