/* OrderHistory.module.scss */

.orderHistory {
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      margin-bottom: 20px;
      color: #333;
      font-size: 1.8em;
    }
  
    .loading {
      text-align: center;
      font-size: 1.2em;
      color: #666;
    }
  
    .noOrders {
      text-align: center;
      font-size: 1.2em;
      color: #999;
    }
  
    .ordersTable {
      width: 100%;
      border-collapse: collapse;
  
      thead {
        background-color: #f5f5f5;
  
        th {
          padding: 12px 15px;
          text-align: left;
          font-weight: 600;
          color: #555;
          border-bottom: 1px solid #e0e0e0;
        }
      }
  
      tbody {
        tr {
          &:hover {
            background-color: #fafafa;
          }
        }
  
        td {
          padding: 12px 15px;
          border-bottom: 1px solid #e0e0e0;
          color: #555;
  
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  
    .statusBadge {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 0.9em;
      font-weight: 600;
      color: #fff;
  
      &.statusPending {
        background-color: #ff9800;
      }
  
      &.statusShipped {
        background-color: #2196f3;
      }
  
      &.statusDelivered {
        background-color: #4caf50;
      }
  
      &.statusCancelled {
        background-color: #f44336;
      }
    }
  
    .detailsLink {
      color: #007bff;
      text-decoration: none;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
  
      svg {
        margin-left: 5px;
        font-size: 0.9em;
      }
  
      &:hover {
        text-decoration: underline;
      }
    }
  }
  