#button_group2 {
    margin-bottom: 10px;
    display: inline-flex;
    border-radius: 8px;  // Slightly larger border radius for a modern look
    overflow: hidden;
    border: 1px solid #007bff;  // Border for the whole group
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  // Add shadow for elevation

    .button {
        padding: 10px 20px;
        border: none;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        flex: 1;
        border-right: 1px solid #0056b3;  // Border between buttons for visual separation
        transition: background-color 0.3s, box-shadow 0.3s;  // Smooth transition for background and shadow

        &:last-child {
            border-right: none;  // Remove the right border for the last button
        }

        &:hover {
            background-color: #0056b3;
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);  // Slightly larger shadow on hover
        }

        &.active {
            background-color: #004bb5;  // Different shade for active state
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);  // Slightly larger shadow for active state
        }
    }
}
