.returnCard {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  
    h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 1rem;
    }
  
    .statusSection {
      margin-bottom: 1.5rem;
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: #555;
        .status {
          padding: 0.3rem 0.6rem;
          border-radius: 8px;
        }
        .pending {
          background-color: #ff9800;
          color: #fff;
        }
        .approved {
          background-color: #4caf50;
          color: #fff;
        }
        .rejected {
          background-color: #f44336;
          color: #fff;
        }
        .returned {
          background-color: #2196f3;
          color: #fff;
        }
      }
      p {
        font-size: 0.9rem;
        color: #666;
      }
    }
  
    .shipmentInfo {
      margin-bottom: 1.5rem;
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 0.9rem;
        color: #666;
      }
    }
  
    .itemsSection {
      margin-bottom: 1.5rem;
  
      h3 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
  
      .itemCard {
        background-color: #f9f9f9;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
        h4 {
          font-size: 1.1rem;
          margin-bottom: 0.5rem;
          color: #333;
        }
  
        p {
          font-size: 0.9rem;
          color: #555;
          margin-bottom: 0.3rem;
        }
  
        .eligible {
          color: #4caf50;
        }
  
        .notEligible {
          color: #f44336;
        }
      }
    }
  
    .adminNotes {
      background-color: #f1f1f1;
      padding: 1rem;
      border-radius: 8px;
      margin-top: 1.5rem;
  
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 0.9rem;
        color: #555;
      }
    }
  
    .chats {
      margin-top: 1.5rem;
  
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 0.9rem;
        color: #555;
        margin-bottom: 0.3rem;
      }
    }
  }
  