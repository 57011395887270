.rippleEffectButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #45a049;
    }
  
    &:focus {
      outline: none;
    }
  
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, white 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform 0.5s, opacity 1s;
    }
  
    &:active::after {
      transform: scale(0, 0);
      opacity: 0.2;
      transition: 0s;
    }
  }
  