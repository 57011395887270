.orderError {
    padding: 20px;
    background-color: #fff3f3;
    border: 1px solid #f5c6cb;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
    text-align: center;
  
    .iconContainer {
      margin-bottom: 10px;
    }
  
    .icon {
      font-size: 3em;
      color: #f44336;
    }
  
    h2 {
      font-size: 1.5em;
      color: #d32f2f;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 1em;
      color: #555;
    }
  
    button {
      padding: 10px 20px;
      background-color: #f44336;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 10px;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #d32f2f;
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  