@import '../../../variables.scss';

.blog_post_editor {
    margin-bottom: 20px;
    .form {
        background-color: white;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        padding: $margin-medium;
        border-radius: 8px;
        margin-top: $margin-medium;



    }
    h2 {
      margin-top: 0;
    }


}
  