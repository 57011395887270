@use '../../../variables.scss' as *;

.roundButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
    border-radius: 50%;
    background-color: $primary-color;
    color: $text-light-color;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 50px;
    height: 50px;

    &:hover {
        background-color: darken($primary-color, 10%);
        transform: scale(1.05);
    }

    &:active {
        background-color: darken($primary-color, 20%);
        transform: scale(0.95);
    }

    &:disabled {
        background-color: lighten($primary-color, 30%);
        cursor: not-allowed;
        box-shadow: none;
    }
}