.customerSupport {
    padding: 30px;
    background-color: #333;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.5s ease-out;
  
    h2 {
      font-size: 1.8em;
      margin-bottom: 10px;
      color: #f0f0f0;
    }
  
    p {
      font-size: 1em;
      color: #bbb;
    }
  
    .contactMethods {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
  
      .method {
        display: flex;
        align-items: center;
  
        .icon {
          font-size: 1.8em;
          margin-right: 10px;
          color: #ffcc00;
        }
  
        a {
          font-size: 1em;
          color: #ffcc00;
          text-decoration: none;
        }
      }
    }
  
    .workingHours {
      font-size: 0.9em;
      color: #888;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  