// src/components/FileUpload.module.scss

.fileUpload {
    width: 100%;
    height: 200px;
    border: 2px dashed #007bff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &.dragging {
      background-color: #e6f7ff;
    }
  
    p {
      color: #007bff;
      font-size: 16px;
    }
  }
  
  .fileInput {
    display: none;
  }
  