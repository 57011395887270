@import '../../../variables.scss';

// Variables for easy customization
$background-color: #fff;
$overlay-color: rgba(0, 0, 0, 0.5);
$modal-width: 100%;
$modal-width-desktop: 500px;
$border-radius: 6px;

.filterButton {
  background-color: $secondary-color;
  color: $background-color;
  border: none;
  border-radius: $border-radius;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 0px;
  white-space: nowrap;
  width: auto;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  background-color: $background-color;
  border-radius: $border-radius;
  width: $modal-width;
  max-width: $modal-width-desktop;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: slideIn 0.4s ease;

  @media (min-width: 768px) {
    width: auto;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 2rem;
    line-height: 1;
    cursor: pointer;
    color: #999;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }
}

.modalContent {
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eee;

  .applyButton {
    background-color: $primary-color;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
