.container {
    margin: 0 auto;
}

.content {
    background: white;
    padding: 20px;
    border-radius: 8px;
}

.section {
    margin-bottom: 20px;
}

.label {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    color: #555;
}

.value {
    font-size: 16px;
    color: #333;
}

.commentsSection {
    margin-top: 20px;
}

.commentsList {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.comment {
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    margin-bottom: 10px;
}

.commentInputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addCommentButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
    transition: background-color 0.3s ease;
}

.addCommentButton:hover {
    background-color: #0056b3;
}

.actions {
    margin-top: 30px;
    text-align: right;
}

.saveButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.saveButton:hover {
    background-color: #218838;
}

.notFound {
    text-align: center;
    padding: 50px;
    font-size: 20px;
    color: #888;
}
