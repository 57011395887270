@import '../../variables.scss';

.cancelledOrdersPage {

    .tab {
        margin-top: $margin-medium;
    }
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
    .analytics .chart {
        flex: 1 1 calc(45% - 20px); /* Adjust to 2 columns for medium screens */
    }
}

@media (max-width: 768px) {
    .analytics .chart {
        flex: 1 1 calc(100% - 20px); /* Adjust to 1 column for small screens */
    }
}
