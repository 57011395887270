@import '../../../../variables.scss';

#input2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    text-align: left;
    margin-bottom: 5px;
    font-size: 14px;
    color: grey;
    margin-left: 5px;
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus-within {
      border-color: #007bff;
      box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
    }

    .icon_container {
      text-align: center;
      width: 40px;
      color: #888;
    }

    input {
      flex: 1;
      padding: 0px;
      border: none;
      outline: none;
      font-size: $font-size-base;
      border-radius: 4px;

      &::placeholder {
        color: #aaa;
      }

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
}
