@use '../../../variables.scss' as *;


.incentive_container {
    padding: $margin-medium;
    & .title {
        text-align: center;
        font-size: $font-size-title;
    }
    & .description {
        margin-top: $margin-small;
        text-align: center;
        font-size: $font-size-base;
        color: grey;
    }
    & .card_list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $margin-medium;
    }
}

.incentive_card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    margin: $margin-small ;
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .icon {
      font-size: 40px;
      color: #007bff;
      margin-bottom: 15px;
    }
    
    .title {
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    
    .description {
      font-size: 1em;
      color: #555;
      margin-bottom: 20px;
    }
    
    .button {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 1em;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  