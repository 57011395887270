.product_card1 {
    flex: 0 0 auto; 
    width: 300px; 
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
    }

    .image_container {
        width: 100%;
        height: 200px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease;
        }
    }

    .content {
        padding: 16px;

        h3 {
            margin: 0 0 10px;
            font-size: 1.5em;
        }

        p {
            margin: 0 0 16px;
            font-size: 1em;
            color: #666;
            text-overflow: ellipsis;
            max-width: 20px;
            overflow: hidden;
            white-space: nowrap;
        }

        .price {
            font-size: 1.2em;
            color: #333;
            font-weight: bold;
        }
    }

    &:hover .image-container img {
        transform: scale(1.1);
    }
}