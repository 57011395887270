.productEditPage {
  background-color: #f9f9f9;

  .content {
    
  }

  .section {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: calc(100% - 20px * 2);

    .inline {
      display: flex;
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &Title {
      font-size: 1.5em;
      font-weight: 600;
      color: #333;
      margin-bottom: 20px;
    }
  }

  .subSection {
    margin-bottom: 30px;

    &Title {
      font-size: 1.2em;
      font-weight: 500;
      color: #555;
      margin-bottom: 10px;
    }
  }

  .buttonContainer {
    margin-top: 20px;
    gap: 20px;
    width: 100%;
  }
}
