@import '../../../variables.scss';

.reviewEditPage {
    .content {
       margin-top: $margin-medium;
        padding: $margin-medium;
        width: calc(100%  - $margin-medium * 2);
        .reviewDetails {
            margin-top: $margin-large;
        }
       .adminActions {
        margin-top: $margin-large;
       }
       p {
        position: relative;
        padding-bottom: $margin-small; /* Space between text and separator */
        padding-top: $margin-small; /* Space above text */
        font-size: 1.1em;
        color: #333; /* Slightly darker color for readability */
        
        &:not(:last-child) {
          margin-bottom: $margin-medium;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(26, 26, 46, 0.3), rgba(0, 0, 0, 0));
          }
        }
      }
    }
    h1 {
      text-align: center;
      margin-bottom: 20px;
      color: #333333;
    }
    
    h2 {
        margin-bottom: 10px;
        color: #0d47a1;
    }
   
  
        .buttonContainer {
          display: flex;
          gap: 10px;
          margin-top: 20px;
  
          button {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 40px;
          }
        }
}
  