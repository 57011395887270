.ratingFilterContainer {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    animation: bounceIn 0.5s ease;
  
    h3 {
      margin-bottom: 10px;
      color: #333;
    }
  
    .ratingList {
      display: flex;
      gap: 5px;
    }
  
    .star {
      cursor: pointer;
      font-size: 24px;
      color: #ffd700;
      transition: transform 0.3s ease, color 0.3s ease;
  
      &:hover {
        transform: scale(1.2);
        color: #ffc107;
      }
    }
  }
  