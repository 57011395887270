.discountCodeCondition {
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 100%;

    h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: #333;
    }

    
}