@import '../../../variables.scss';


.edit_discount_code {
    .content {
        width: calc(100%);
    }  
}

.discount_code_form {
    padding: $margin-medium;
    border-radius: 10px;
    background-color: #fff;
    width: calc(100% - $margin-medium * 2);
    margin-top: $margin-medium ;
    box-shadow: $box-shadow1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    h2 {
      margin-bottom: 10px;
    }
    h3 {
      margin-top: $margin-medium;
    }
    
   
}