@import '../../../variables.scss';


$input-padding: 12px;
$placeholder-color: #999;
$border-radius: 25px; /* Rounded corners for a modern look */

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px; /* Adjust the max-width as needed */
  margin: 0 auto;
  box-shadow: 0 4px 10px $box-shadow-color; /* Shadow for elevation */
  border-radius: $border-radius; /* Smooth rounded corners */
  transition: box-shadow $transition-duration ease, transform $transition-duration ease;

  &:hover, &:focus-within {
    box-shadow: 0 8px 20px $accent-color; /* Enhanced shadow on focus */
    transform: translateY(-2px); /* Slight lift effect */
  }

  .searchInput {
    width: 100%;
    height: 40px;
    font-size: $font-size-base;
    border: none;
    border-radius: $border-radius;
    outline: none;
    box-shadow: none; /* Remove default input shadow */
    background-color: white; /* White background for contrast */
    transition: background-color $transition-duration ease, box-shadow $transition-duration ease;
    text-indent: $margin-medium;
    &::placeholder {
      color: $placeholder-color;
      opacity: 0.7;
    }

    &:focus {
      box-shadow: 0 0 5px $accent-color; /* Focus outline for accessibility */
      outline: none; /* Remove default outline */
    }
  }
}
