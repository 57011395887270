@import '../../../../variables.scss';

$border-color: #ccc;
$input-height: 40px;
$input-padding: 8px;
$label-color: #999;
$animation-duration: 0.3s;
$font-size-base: 1rem;
$background-hover-color: #f0f8ff; /* Light blue background on hover */
$focus-border-color: #e83e8c;

.priceRangeInput {
  margin-top: $margin-medium;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 10px $box-shadow-color; /* Shadow for elevation */
        border-radius: 6px; /* Smooth rounded corners */
        transition: box-shadow $transition-duration ease, transform $transition-duration ease;
        background-color: white;
  transition: box-shadow $animation-duration ease, border-color $animation-duration ease;
  width: 200px;
  overflow: hidden;

  &:hover, &:focus-within {
      border-color: $focus-border-color;
      box-shadow: 0 0 5px rgba($focus-border-color, 0.5);
  }

  .input {
    width: 100%;
    padding: 0 $input-padding;
    font-size: $font-size-base;
    height: $input-height;
    border: none;
    outline: none;
    background-color: transparent; /* Ensure input background is transparent */
    color: #333;

    &::placeholder {
      color: $label-color;
      opacity: 0.7;
    }

    &:focus {
      outline: none; /* Remove outline on focus */
    }
  }

  .separator {
    padding: 0 $input-padding;
    font-size: 0.9rem;
    color: $label-color;
  }
}
