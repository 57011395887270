.badgeContainer {
  display: flex;
  justify-content: center;
  gap: 40px; /* Increased gap for better spacing */
  padding: 30px; /* More padding for breathing space */
  background-color: white; /* Light background for contrast */
  border-radius: 12px; /* Softer edges for the container */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05); /* Subtle shadow */
}

.badge {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: transform 0.4s ease, box-shadow 0.4s ease; /* Smoother hover effect */
  padding: 10px 20px;
  background: linear-gradient(135deg, #1a73e8 0%, #5c9ded 100%); /* Modern blue gradient */
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  
  &:hover {
    transform: translateY(-8px); /* Raised hover effect */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */

    .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(0); /* Animate tooltip to appear */
    }
  }

  .icon {
    font-size: 40px; /* Slightly larger icon size */
    color: #fff; /* White icon for better contrast */
    transition: color 0.3s ease; /* Smooth icon color transition */
  }

  p {
    margin: 15px 0 0; /* Adjusted spacing */
    font-size: 18px;
    color: #fff; /* White text for contrast */
    font-weight: 600; /* Bold text for readability */
  }

  .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    background-color: rgba(0, 0, 0, 0.75); /* Dark semi-transparent tooltip */
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, transform 0.4s ease; /* Smooth transition */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Add shadow for the tooltip */
    z-index: 1;
  }
}
