@import "../../../variables.scss";



.card {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    }
  }
  
  .imageContainer {
    width: 100%;
    padding-top: 70%;
    position: relative;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    padding: 15px;
    text-align: left;
  }
  
  .name {
    font-size: 1.4rem;
    margin: 8px 0;
    color: #222;
  }
  
  .description {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 8px;
  }
  
  .priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-size: 1.1rem;
    color: #00897b;
    font-weight: bold;
  }
  
  .discountPrice {
    font-size: 0.95rem;
    color: #e74c3c;
    text-decoration: line-through;
  }
  