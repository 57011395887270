.orderSearchBar {
    position: relative;
    max-width: 100%;
    margin: auto;
    padding: 16px;
    background-color: #f9fafb;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 20px;

    .searchButton {
        padding: 10px 16px;
        background-color: #4a90e2;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #357ab8;
        }
    }

    .suggestionsList {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-height: 150px;
        overflow-y: auto;
        z-index: 10;
        margin-top: 4px;
        padding: 0;

        .suggestionItem {
            padding: 10px 16px;
            font-size: 1rem;
            color: #333;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #f0f4f8;
            }
        }
    }
}
