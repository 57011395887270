.billingCard {
    padding: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
  
    h2 {
      margin-bottom: 20px;
      font-size: 1.6em;
      color: #444;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      label {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: 0.9em;
        color: #666;
  
        input[type='checkbox'] {
          margin-right: 10px;
        }
      }
  
      input {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border-color 0.3s;
  
        &:focus {
          border-color: #28a745;
          outline: none;
        }
      }
  
      .doubleInput {
        display: flex;
        justify-content: space-between;
  
        input {
          width: 48%;
        }
      }
  
      button {
        padding: 12px;
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #218838;
        }
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  