.discount_stats_card {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .icon {
        font-size: 24px;
        color: #3498db;
        margin-right: 20px;
    }

    .details {
        h3 {
            margin: 0;
            font-size: 18px;
            color: #333;
        }

        p {
            margin: 5px 0 0;
            font-size: 16px;
            color: #666;
        }
    }
}
