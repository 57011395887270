


#button_6 {
    display: inline-block;
    padding: 10px 20px;
    color: #3498db;
    background-color: white;
    border: 2px solid #3498db;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease, border-color 0.3s ease;
    position: relative;
    overflow: hidden;
    &:hover {
        color: white !important;
        border-color: white;
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300%;
        height: 300%;
        background: #3498db;
        transition: all 0.3s ease;
        border-radius: 50%;
        z-index: 0;
        transform: translate(-50%, -50%) scale(0);
    }
    &:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    span {
        position: relative;
        z-index: 1;
        padding: 0px;
        margin: 0px;
    }
}