.productTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.productHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin: 0;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    color: #888;
  }
}

.priceAndRating {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  .productPrice {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ff5722;  // Product price color
  }

  .productRating {
    display: flex;
    align-items: center;

    .starIcon {
      color: #fdd835;
      margin-right: 0.2rem;
    }

    .ratingValue {
      font-size: 1rem;
      color: #888;
      margin-left: 0.5rem;
    }
  }
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: #555;
  margin-top: 0.5rem;
}

.underline {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-top: 0.75rem;
}
