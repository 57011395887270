.variantSelector {
    display: flex;
    flex-direction: column;
    gap: 1rem;  // Increase spacing for a cleaner layout
    padding: 1rem 0;
    width: 100%;
    max-width: 100%;  // Ensure the selector does not exceed container width
  }
  
  .variant {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0;
  
    &Name {
      font-size: 1.2rem;
      color: #333;
      font-weight: 600;  // Make the variant name bold for emphasis
      margin-bottom: 0.5rem;
      letter-spacing: 0.02em;  // Slightly space out letters for a modern feel
    }
  
    .options {
      display: flex;
      gap: 12px;  // Add more space between options
      flex-wrap: wrap;  // Ensure options wrap to new lines if needed
    }
  
    .option {
      padding: 0.75rem 1.5rem;  // Add more padding for larger buttons
      background: #f8f8f8;  // Lighter background color for a modern, clean look
      border: 1px solid #ddd;  // Subtle border to define the option boundaries
      border-radius: 8px;  // More rounded corners for a sleek feel
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);  // Add subtle shadow for depth
      color: #333;
      &:hover {
        background-color: #0056b3;  // A darker shade of blue for hover
        color: #fff;
        transform: translateY(-3px);  // A subtle lift effect
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  // Enhance shadow on hover
      }
  
      &:active {
        transform: scale(0.98);  // Slight "press" effect on click
      }
    }
  
    .selected {
      background-color: #007bff;  // Use the brand color for the selected state
      color: white;
      border: 1px solid #0056b3;  // Slightly darker border for better contrast
      box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);  // Add a soft glow for the selected option
    }
  }
  