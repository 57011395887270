.option_group {
    margin-bottom: 20px;
  
    h4 {
      margin-bottom: 10px;
      font-size: 1.2em;
      color: #666;
    }
  
    .radio_group {
      display: flex;
      gap: 10px;
    }
  
    .radio_label {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 10px 15px;
      border: 1px solid #ddd;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #f1f1f1;
      }
  
      .radio_input {
        margin-right: 10px;
      }
    }
  }
  