@import '../../variables.scss';

.loginPage {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.formContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f4f4f4;
  animation: slideInFromLeft 0.8s ease-in-out;
  .loginForm {
    min-width: 600px;
    width: 100%;
  }
}

.imageContainer {
  flex: 1;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
    animation: zoomIn 15s ease-in-out infinite alternate;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@media (max-width: 768px) {
  .loginPage {
    flex-direction: column;
  }

  .formContainer,
  .imageContainer {
    flex: none;
    width: 100%;
    min-height: 50vh;
  }

  .formContainer {
    padding: 0px;
  }
}
