@import '../../..//variables.scss';

.socialMediaContainerV2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 0, 150, 0.2), rgba(0, 204, 255, 0.2));
  border-radius: 10px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(135deg, rgba(255, 0, 150, 0.3), rgba(0, 204, 255, 0.3));
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
}

.socialMediaLinkV2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 10%;
  background-color: #444;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: rotate(10deg) scale(1.1);
    background-color: #ff6f61;
  }
}

.socialMediaIconV2 {
  width: 28px;
  height: 28px;
  fill: white;
}
