.selectorContainer {
  
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
      color: #333;
    }
  
    .swatches {
      display: flex;
      gap: 10px;
    }
  
    .swatch {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      transition: transform 0.3s, box-shadow 0.3s;
  
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
  
      &.selected {
        box-shadow: 0 0 0 3px black;
  
        .checkmark {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 16px;
        }
      }
    }
  }
  