.returnAdminNote {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 20px;
  
    h2 {
      margin-bottom: 1rem;
      color: #333;
    }
  
    .adminNoteTextarea {
      width: calc(100% - 12px * 2);
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: vertical;
      font-size: 16px;
      &:focus {
        border-color: #4a90e2;
        outline: none;
      }
    }
    .separation {
        width: 100%;
        margin: 20px 0px;
    }
   
  
    .newMessageContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .sendButton {
        align-self: flex-end;
        padding: 10px 20px;
        background-color: #4a90e2;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: darken(#4a90e2, 10%);
        }
  
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }
  }
  
  