@import '../../../../variables.scss';

.tableContainer {
    width: calc(100% - 20px * 2);
    margin-top: 20px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: $box-shadow1;
  }
  
  .searchBar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  
  .searchInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
  }
  
  .productTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .productTable th,
  .productTable td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .productTable th {
    background-color: #343a40;
    color: white;
  }
  
  .discountInput {
    width: 100px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .saveButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .saveButton:hover {
    background-color: #218838;
  }
  