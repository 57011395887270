.blogPage {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
  
    .heroImage {
      width: 100%;
      height: 400px;
      overflow: hidden;
      border-radius: 12px 12px 0 0;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
  
        &:hover {
          transform: scale(1.05); /* Subtle zoom effect on hover */
        }
      }
    }
  
    .contentContainer {
      padding: 40px;
      background-color: white;
      border-radius: 0 0 12px 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      margin-top: -20px;
  
      .title {
        font-size: 2.5rem;
        color: #333;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
      }
  
      .blogContent {
        font-size: 1.1rem;
        line-height: 1.8;
        color: #555;
        text-align: justify;
  
        p {
          margin-bottom: 20px;
        }
  
        h2, h3 {
          color: #2c3e50;
          margin-top: 30px;
          margin-bottom: 10px;
        }
  
        img {
          width: 100%;
          border-radius: 10px;
          margin: 20px 0;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
  
        ul, ol {
          margin-left: 40px;
        }
  
        a {
          color: #3498db;
          text-decoration: underline;
          transition: color 0.3s ease;
  
          &:hover {
            color: #2980b9;
          }
        }
      }
    }
  }
  