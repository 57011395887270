.calendarContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .navButton {
        background-color: #007BFF;
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
    }
    .navButton:hover {
        background-color: #0056b3;
    }
}

.weekRow {
    display: flex;
  }
  
.dayCell {
    flex: 1;
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    &.emptyCell {
      background-color: #f9f9f9;
    }
    &:last-child {
      border-right: none;
    }
}
  
.dayCell:hover {
    background-color: #f0f0f0;
}

.weekRow:last-child .dayCell {
    border-bottom: none;
}

.selectedCell {
    background-color: #007BFF;
    color: white;
}