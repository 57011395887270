@import '../../../../variables.scss';

$modern-teal: #4db6ac; // Modern teal color

.buyButton {
  background: linear-gradient(135deg, $modern-teal, lighten($modern-teal, 20%));
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  overflow: hidden !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.4s ease;
  outline: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  width: 100%;

  &:hover {
    transform: translateY(-3px) scale(1.05); /* Adds a slight lift effect */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    background: linear-gradient(135deg, lighten($modern-teal, 10%), lighten($modern-teal, 30%)); /* Subtle color change on hover */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(77, 182, 172, 0.4); /* Teal focus outline */
  }

  &::before, &::after {
    content: '';
    position: absolute;
    border-radius: 50px;
    z-index: -1;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1); /* Smooth easing */
  }

  &::before {
    height: 250%;
    width: 250%;
    background: rgba(255, 255, 255, 0.25);
    top: -75%;
    left: -75%;
    opacity: 0;
    transform: scale(0.8); /* Starts smaller */
  }

  &::after {
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    top: 0;
    left: 0;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1); /* Smooth background transition */
  }

  &:hover::before {
    opacity: 1;
    transform: scale(1); /* Expands on hover smoothly */
    height: 200%;
    width: 200%;
    top: -50%;
    left: -50%;
    overflow: hidden;
  }

  &:hover::after {
    height: 250%;
    width: 250%;
    transform: scale(1.2); /* Adds a nice expanding effect */
    top: -60%;
    left: -60%;
    background: rgba(255, 255, 255, 0.15);
    overflow: hidden;
  }
}
