@import '../../../variables.scss';

$color-1: #0f3460;  // Main headings, primary text, key information
$color-2: #1a1a2e;  // Main background areas
$color-3: #e94560;  // Accent elements, alerts, important notifications
$color-border: hsl(0, 0%, 88%);  // Borders, dividers, and outlines
$color-4: #4bc0c0;  // Secondary action buttons, links, highlights
$color-5: rgba(153, 102, 255, 1);  // Specific elements, charts, graphics

.reviewTable {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    height: auto;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    align-items: center;

    table {
        width: 100%;
        border-collapse: collapse;
        color: $color-1;
        table-layout: auto;

        th, td {
            padding: 15px 10px;
            text-align: left;
            border-bottom: 1px solid $color-border;
        }

        th {
            background-color: $primary-color;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            &:nth-child(1) {
              border-top-left-radius: 10px;
            }
            &:nth-last-child(1) {
              border-top-right-radius: 10px;
            }
        }

        tbody tr {
            cursor: pointer;
            transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

            &:hover {
                background-color: lighten($color-border, 30%);
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }
        }

        tbody tr:nth-child(odd) {
            background-color: #fafafa;
        }

        td {
            font-size: 14px;
            &:first-child {
                font-weight: bold;
            }

            &.review-status {
                &.pending {
                    color: $color-3;
                }
                &.completed {
                    color: $color-4;
                }
                &.failed {
                    color: darken($color-3, 10%);
                }
            }
        }
    }

    // Responsive adjustments
    @media (max-width: 768px) {
        width: 100%;
        padding: 10px;

        table, thead, tbody, th, td, tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            margin: 0 0 10px 0;
            background-color: #fff;
            padding: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }

        td {
            border: none;
            padding: 10px;
            position: relative;
            padding-left: 50%;

            &:before {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 40%;
                padding-right: 10px;
                white-space: nowrap;
                content: attr(data-label);
                font-weight: bold;
            }
        }
    }
}

// Styling for buttons inside table cells
td {
    button {
        border: none;
        outline: none;
        padding: 8px 15px;
        margin: 0 5px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s ease, transform 0.2s ease;

        svg {
            margin-right: 5px;
            font-size: 18px;
        }

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
    }

    .edit_btn {
        background-color: $color-4;
        color: white;

        &:hover {
            background-color: darken($color-4, 10%);
        }
    }

    .delete_btn {
        background-color: $color-3;
        color: white;

        &:hover {
            background-color: darken($color-3, 10%);
        }
    }
}
