.tabs {
    background-color: #2d2f36;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    margin-bottom: 20px;
  
    .tabList {
      display: flex;
      margin-bottom: 20px;
      border-bottom: 2px solid #4bc0c0;
  
      .tab {
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        color: #a5a5a5;
        display: flex;
        align-items: center;
  
        &.active {
          background-color: #4bc0c0;
          color: #ffffff;
          border-bottom: 2px solid #ffffff;
        }
  
        &:hover:not(.active) {
          background-color: #3a3f47;
          color: #ffffff;
        }
  
        &:not(:last-child) {
          border-right: 1px solid #4bc0c0;
        }
  
        svg {
          margin-right: 8px;
        }
  
        span {
          font-size: 1em;
        }
      }
    }
  
    .tabContent {
      background-color: #3a3f47;
      padding: 20px;
      border-radius: 8px;
      transition: opacity 0.3s ease;
    }
  }