@import '../../../../variables.scss';


.customerInfo {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h2 {
        margin-bottom: 20px;
        color: #333;
        font-size: 1.8em;
      }
  
      .editButton {
        background-color: $secondary-color;
        color: #fff;
        border: none;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1em;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: background-color 0.3s ease;
        margin: 0px;
  
        &:hover {
          background-color: darken($secondary-color, 10);
        }
  
        svg {
          font-size: 1.1em;
        }
      }
    }
  
    .infoContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 20px;
    }
  
    .infoSection {
      background-color: #f9f9f9;
      padding: 15px;
      border-radius: 8px;
  
      h3 {
        margin-bottom: 15px;
        color: #555;
        font-size: 1.3em;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 5px;
      }
  
      .infoRow {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
  
        label {
          font-weight: bold;
          color: #333;
          margin-bottom: 5px;
        }
  
        input,
        select {
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1em;
          width: 100%;
          box-sizing: border-box;
  
          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
  
        span {
          color: #555;
          font-size: 1em;
          margin: 0px;
        }
  
        .error {
          color: #f44336;
          font-size: 0.9em;
          margin-top: 5px;
        }
      }
    }
  
    .buttonContainer {
      margin-top: 20px;
      text-align: right;
  
      .saveButton {
        background-color: $accent-color;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1em;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: background-color 0.3s ease;
        margin: 0px;
        &:hover {
          background-color: darken($accent-color, 10);
        }
  
        &:disabled {
          background-color: #6c757d;
          cursor: not-allowed;
        }
  
        svg {
          font-size: 1.1em;
        }
      }
    }
  }
  