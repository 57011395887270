.policySettingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f8fc;
    min-height: 100vh;
}

.editorContainer {
    width: 100%;
    max-width: calc(100% - 20px * 2);
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    .editButton {
        background-color: #007bff;
        color: #ffffff;
        padding: 10px 15px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }
    }
}

.policySettingPage :global(.richTextEditorV1) {
    width: 100%;
    min-height: 300px;
    margin-top: 10px;
}

.saveButton {
    background-color: #28a745;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #218838;
    }
}
