.bannerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 300px;
    position: relative;
    color: white;
  
    .bannerImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
      filter: blur(5px);
      z-index: 0;
      transform: scale(1.1);
      transition: transform 0.5s ease-in-out;
  
      &:hover {
        transform: scale(1.2);
      }
    }
  
    .bannerContent {
      position: relative;
      z-index: 1;
      text-align: center;
      backdrop-filter: blur(5px);
      padding: 20px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      animation: fadeIn 1s;
  
      h1 {
        font-size: 2.5em;
        margin: 0;
      }
  
      h2 {
        font-size: 1.5em;
        margin: 10px 0;
      }
  
      .callToAction {
        padding: 10px 20px;
        background: #00796b;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        transition: background 0.3s;
  
        &:hover {
          background: #004d40;
        }
      }
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  