.avatar_container {
    display: flex;
    align-items: center;
}
  
.avatar_image_container {
    position: relative;
}
  
.avatar_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
  
.status_icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff; // Optional: to create a white border around the status icon
}
  
.status_icon  {
    &.online {
        background-color: #4caf50;
    }
    &.offline{
        background-color: #f44336;
    }
    &.busy {
        background-color: #ff9800;
    }
}
  
  
.avatar_info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
  
.avatar_name {
    font-weight: bold;
    font-size: 16px;
}
  
.avatar_description {
    font-size: 14px;
    color: #999;
}