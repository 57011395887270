@import '../../../variables.scss';

.dropdownSortContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 200px;
    font-family: Arial, sans-serif;
    margin: 0px;
    .label {
      margin-bottom: 5px;
      color: #333;
      font-weight: bold;
      font-size: 16px;
    }
  
    .dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 20px;
      border-radius: 8px;
      border: 1px solid #ccc;
      background: #fff;
      color: #333;
      cursor: pointer;
      transition: border 0.3s ease, box-shadow 0.3s ease;
  
      p {
        margin: 0;
        font-size: 16px;
      }
  
      &:hover {
        border-color: $accent-color;
        box-shadow: 0 0 5px $accent-color(0, 123, 255, 0.5);
      }
  
      
    }
  
    .arrow {
      border: solid transparent;
      border-width: 5px 5px 0 5px;
      border-top-color: darken($color: $accent-color, $amount: 20);
      transition: transform 0.3s ease;
  
      &.up {
        transform: rotate(180deg);
      }
  
      &.down {
        transform: rotate(0deg);
      }
    }
  
    .optionsList {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      max-height: 200px;
      overflow-y: auto;
      z-index: 1000;
      animation: fadeIn 0.3s ease;
  
      .optionItem {
        padding: 10px 15px;
        cursor: pointer;
        transition: background 0.3s ease;
  
        &:hover {
          background: #f1f1f1;
        }
  
        p {
          margin: 0;
          color: #333;
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  