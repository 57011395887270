.chatRoom {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    .messages {
        flex: 1;
        padding: 20px;
        overflow-y: auto;
        background-color: #fff;
        border-bottom: 1px solid #e0e0e0;
    }

    .message {
        margin-bottom: 15px;
        padding: 10px 15px;
        border-radius: 8px;
        max-width: 70%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        position: relative;

        &.adminMessage {
            background-color: #e3f2fd;
            margin-left: auto;
            text-align: right;

            .sender {
                color: #1e88e5;
                font-weight: bold;
            }
        }

        &.customerMessage {
            background-color: #eeeeee;

            .sender {
                color: #333;
                font-weight: bold;
            }
        }

        .content {
            margin: 5px 0;
            color: #555;
            font-size: 0.95rem;
        }

        .timestamp {
            font-size: 0.8rem;
            color: #888;
            text-align: right;
        }
    }

    .typingIndicator {
        margin-top: 10px;
        font-style: italic;
        color: #777;
    }

    .inputArea {
        display: flex;
        padding: 10px;
        background-color: #fff;
        border-radius: 0 0 8px 8px;

        input {
            flex: 1;
            padding: 10px;
            font-size: 1rem;
            border: none;
            border-radius: 20px;
            background-color: #f0f0f0;
            margin-right: 10px;
            outline: none;

            &:focus {
                background-color: #e0e0e0;
            }
        }

        .sendButton {
            background-color: #1e88e5;
            border: none;
            padding: 10px 15px;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                font-size: 1.2rem;
            }

            &:hover {
                background-color: #1565c0;
            }
        }
    }
}
