@import '../../../../variables.scss';

.contactContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    margin-top: $margin-medium;
    gap: $margin-medium;
}

.contactTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.contactItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contactIcon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.contactText {
    font-size: 1rem;
}

.contactLink {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
        color: #ff6f61;
    }
}
