@import '../../../variables.scss';

.trackingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $margin-medium;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: $margin-large;
  }
  
  .stepper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    position: relative;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 25%;
  
    .stepIcon {
      background-color: #e4e5e9;
      color: #fff;
      font-size: 1.5rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      transition: background-color 0.3s ease;
  
      &.active {
        background-color: #5a67d8; // Active step color (purple-blue)
      }
    }
  
    .stepLabel {
      font-size: 0.9rem;
      color: #666;
      text-align: center;
      transition: color 0.3s ease;
  
      &.active {
        color: #333;
      }
    }
  
    .line {
      position: absolute;
      top: 1.5rem;
      right: -50%;
      height: 0.25rem;
      background-color: #e4e5e9;
      width: 100%;
      transition: background-color 0.3s ease;
  
      &.active {
        background-color: #5a67d8;
      }
    }
  
    &:last-child .line {
      display: none;
    }
  }
  
  .trackOrder {
    margin-top: 1.5rem;
  
    a {
      font-size: 1rem;
      color: #5a67d8;
      text-decoration: none;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      background-color: #f7f7f9;
      border: 1px solid #5a67d8;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: #5a67d8;
        color: #fff;
      }
    }
  }
  