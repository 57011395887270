.notesContainer {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: calc(100% - 20px * 2);
}

.notesSection {
    margin-bottom: 20px;
}

h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
}

p {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.5;
}

.notesTextarea {
    width: calc(100% - 10px * 2);
    padding: 10px;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    resize: vertical;
    margin-bottom: 10px;
}

.saveButton {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;

    &:hover {
        background-color: #218838;
    }
}
