.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
  .button {
    background-color: rgba(52, 152, 219, 0.8); // Slightly transparent blue
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    border-radius: 5px; // Rounded corners
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Light shadow

    &:disabled {
      background-color: rgba(204, 204, 204, 0.8); // Slightly transparent grey for disabled state
      cursor: not-allowed;
      box-shadow: none; // Remove shadow for disabled state
    }

    &:hover:not(:disabled) {
      background-color: rgba(41, 128, 185, 0.9); // Darker blue on hover
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); // Slightly stronger shadow on hover
    }
  }

  span {
    margin: 0 10px;
    color: #333; // Darker color for the span
  }
}