.slider_selector {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .slider {
    width: 150px;
    height: 5px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s, transform 0.2s;
  }
  
  .slider:hover::-webkit-slider-thumb {
    background: #555;
    transform: scale(1.1);
  }
  
  .quantity_display {
    font-size: 18px;
  }
  