.carousel {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .carousel_slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade_in {
    opacity: 1;
  }
  
  .fade_out {
    opacity: 0;
  }
  
  .carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .carousel_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity here */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel_content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    max-width: 800px;
    padding: 20px;
  }
  
  .carousel_content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .carousel_content p {
    z-index: 2;
    color: white !important;
    font-size: 1.25rem;
    margin-bottom: 30px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .carousel_button {
    padding: 10px 20px;
    background-color: #ff5722;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .carousel_button:hover {
    background-color: #e64a19;
  }
  