
$color-1: #0f3460;  //Use for main headings, primary text, and key information that should stand out. Use for primary action buttons to make them prominent.
$color-2: #1a1a2e; // Use for main background areas to give a modern and sleek look. Use for secondary text that should be less prominent than primary text.
$color-3: #e94560; //Use for accent elements such as alerts, important notifications, or error messages. Use to highlight links or important information that needs to catch the user’s eye.
$color-border: hsl(0, 0%, 88%); // Use for borders, dividers, and outlines to provide subtle separation between elements.
$color-4: #4bc0c0; //Use for secondary action buttons to differentiate from primary buttons. Use for links, highlights, and interactive elements to provide a fresh, modern look.
$color-5: rgba(153, 102, 255, 1); //Use for highlighting specific elements, charts, or graphics to add a vibrant touch.  Use for icons and smaller UI elements that need a bit of color to stand out.
$background-color: white;

// Typography
$font-family-base: 'Roboto', sans-serif;
$font-size-base: 16px;
$font-size-lg: 18px;
$font-size-sm: 14px;
$font-weight-normal: 400;
$font-weight-bold: 700;

// Spacing
$spacing-unit: 8px;
$spacing-sm: $spacing-unit * 0.5; // 4px
$spacing-md: $spacing-unit; // 8px
$spacing-lg: $spacing-unit * 2; // 16px

// Margin
$margin-small: 8px;
$margin-medium: 16px;
$margin-large: 24px;
$margin-extra-large: 32px; 

// Border Radius
$border-radius-base: 8px;
$border-radius-sm: 4px;
$border-radius-lg: 16px;

// Box Shadow
$box-shadow-base: 0 4px 8px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 6px 12px rgba(0, 0, 0, 0.2);

// Transitions
$transition-base: 0.3s ease-in-out;

// Z-Index
$z-index-dropdown: 1000;
$z-index-navbar: 900;
$z-index-overlay: 1100;

// Buttons
$button-padding: 10px 20px;
$button-border-radius: $border-radius-base;
$button-font-weight: $font-weight-bold;
$button-transition: background-color $transition-base, color $transition-base;

.dashboard {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    flex-direction: row;
    justify-content: space-between;
    .content {
        padding: 10px;
        transition: margin-left 0.3s ease;
        margin: 0px;
        width: calc(100% - 10px * 2);
        &.collapsedContent {
          margin-left: 60px; // Adjust this value based on the collapsed width of the sidebar
        }
      
        &:not(.collapsedContent) {
          margin-left: 230px; // Adjust this value based on the expanded width of the sidebar
        }
    }
}
