.blogSectionV3 {
    display: flex;
    column-count: 2;
    column-gap: 20px;
    border-radius: 10px;
  }
  
  .blogCard {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    break-inside: avoid;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .blogImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blogContent {
    padding: 20px;
  
    h3 {
      margin: 0;
      font-size: 1.5rem;
      color: #333;
    }
  
    p {
      margin: 10px 0;
      color: #777;
    }
  }
  