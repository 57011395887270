@import '../../variables.scss';


.promotion_dashboard {
    .content {
        width: calc(100%);
        margin-top: $margin-medium
    };
    .add_button {
        margin-top: $margin-medium;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $margin-medium ;
    };
    .promotion_list_section {
        margin-top: $margin-medium;

        h2 {
            margin-bottom: 20px;
        }
    };
}
