


#button_7 {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.1);
    }
    .icon {
        margin-right: 8px;
    }
    
}
