.sliderContainer {
    position: relative;
    width: 100%;
    height: 20px;
    margin: 20px 0;
    cursor: pointer;
}

.sliderTrack {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    transform: translateY(-50%);
    border-radius: 2px;
}

.sliderThumb {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.3s;
}

.sliderThumb:hover {
    background-color: #0056b3;
}

.label {
    color: grey;
}