@import '../../variables.scss';

.discount_code_page {
  .content {
    width: calc(100%);
  }
  .add_button {
    margin-top: $margin-medium;
    display: flex;
    flex-direction: row-reverse;
  }
}
  
  
  