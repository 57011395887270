.cancelButton {
    background-color: #f5f5f5;
    color: #ff1744;
    border: 2px solid #ff1744;
    border-radius: 3px;
    padding: 9px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    outline: none;
}

.cancelButton:hover {
    background-color: #ff1744;
    color: #ffffff;
}

.cancelButton:disabled {
    background-color: #e0e0e0;
    color: #9e9e9e;
    border-color: #bdbdbd;
    cursor: not-allowed;
}