@use '../../../variables.scss' as *;

.tabContainer {
  width: 100%;
  max-width: 600px; /* Adjust width as needed */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tabList {
  display: flex;
  border-bottom: 2px solid #ccc;
  margin: 0;
  padding: 0;
}

.tab {
  flex: 1;
  padding: 10px 20px;
  cursor: pointer;
  position: relative; /* Needed for the pseudo-element */
  transition: color 0.3s ease, background-color 0.3s ease;
  color: grey;
  text-align: center;
  font-weight: 500;
  background-color: #f9f9f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &::before {
    content: '';
    position: absolute;
    bottom: -2px; /* Aligns with the bottom border */
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &:hover::before {
    background-color: #000; /* Hover border color */
  }

  &:hover {
    color: #000;
    background-color: #e0e0e0;
  }
}

.tab.active {
  color: #000;
  background-color: #fff;
  margin-bottom: -2px;
  border-bottom: 2px solid #000; /* Active tab underline color */
}

.tabContent {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}