.pageEditor {
    display: flex;  // Use flexbox to align component list and editor area
    height: 100vh;  // Make the editor take the full height of the viewport
    
    .componentList {
        width: 150;  // Fixed width for the component list on the left
        flex-shrink: 0;  // Prevent the component list from shrinking
    }

    .editorArea {
        flex-grow: 1;  // Make the editor area take the remaining space
        gap: 1rem;
        background-color: white;
        overflow-y: auto;  // Add scrolling for content that exceeds the viewport

        .droppableArea {
            display: grid;
            grid-template-columns: repeat(12, 1fr);  // 12 columns for larger screens
            gap: 1rem;
            grid-auto-rows: minmax(100px, auto);  // Dynamic row height based on content
        }
    }

    .componentWrapper {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        cursor: pointer;
        grid-column: span 6;  // Default to span 6 columns on larger screens
    }

    .modalTitle {
        text-align: center;
        padding: 1rem;
        margin: 0;
        border-radius: 8px 8px 0 0; /* Rounded top edges */
        color: #333; /* Darker text for contrast */
        font-weight: bold;
        margin-bottom: 20px;
    }
    .editForm {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;  
    }
}

/* Media Query for smaller screens */
@media (max-width: 1200px) {
    .pageEditor {
        flex-direction: column;
        flex-direction: row;
        .componentList {
            width: 150;  // Make the component list take full width
            height: auto;
            
        }
        .editorArea {
            .droppableArea {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 100%;  // Ensure the area never exceeds the screen width
                margin: 0;  // Ensure no margin that could cause overflow
                padding: 0;  // Reset any padding that could affect width
                box-sizing: border-box;  // Make sure padding is included in width calculation
                .componentWrapper {
                    width: calc(100%);  // Each component takes full width on smaller screens
                    box-sizing: border-box;
                }
            }
        }
        
        
    }
}
