@import '../../../variables.scss';

$transition-duration: 0.3s;

.emailSender {
    background-color: white;
    background-size: cover;
    padding: $margin-medium;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - $margin-medium * 2);
    margin: 20px auto;
    animation: fadeIn $transition-duration ease-in-out;

    h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }
    
    .formGroup {
        margin-bottom: 15px;
        
        label {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
        }

        input, textarea {
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 5px;
            transition: border-color $transition-duration ease-in-out;
            resize: none;
            text-indent: $margin-medium;
            height: $input-height;
            padding: 0px;
            &:focus {
                border-color: $primary-color;
                outline: none;
                box-shadow: 0 0 5px rgba($primary-color, 0.5);
            }
        }

        textarea {
            height: 150px;
            padding-top: $margin-small;
        }
    }

    .sendButton {
        display: inline-flex; /* Change from 'flex' to 'inline-flex' to align to the left */
        justify-content: flex-start; /* Ensure content stays aligned left */
        align-items: center;
        background-color: $accent-color;
        color: #fff;
        padding: 8px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color $transition-duration ease-in-out;
        margin: 0px;
        margin-top: 10px; /* Optional: Add some margin if needed for spacing */
        
        &:hover {
            background-color: darken($primary-color, 10%);
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
