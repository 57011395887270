@import '../../../variables.scss';

.payment_settings_page {
}

.payement_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: $margin-small;
}