@import '../../../../variables.scss';

.statsContainer {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    margin-top: $margin-medium;
}

.title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.statRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    font-size: 18px;
    color: #555;
}

.statLabel {
    font-weight: 500;
    color: #777;
}

.statValue {
    font-weight: 600;
    color: #222;
}

.divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 10px 0;
}
