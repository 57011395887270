.gradientHoverButton {
    background-image: linear-gradient(45deg, #ff6b6b, #f94d6a);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    }
  
    &:focus {
      outline: none;
    }
  }
  