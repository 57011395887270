@import '../../../../variables.scss';


.formContainer {
    margin-top: $margin-medium;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: $box-shadow1;
    max-width: 100%;
  }
  
  .formGroup {
    margin-bottom: 15px;
    
    label {
      display: block;
      font-weight: 600;
      margin-bottom: 5px;
      color: #333;
    }
    
    .input,
    .select,
    .textarea,
    .checkbox {
      width: calc(100% - 12px *2);
      padding: 8px 12px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
      color: #333;
      background-color: #fafafa;
      transition: border-color 0.3s ease;
      
      &:focus {
        border-color: #007bff;
        outline: none;
      }
    }
    
    .checkbox {
      width: auto;
      margin-right: 10px;
    }
    
    .textarea {
      min-height: 100px;
      resize: vertical;
    }
  }
  
  .submitButton {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    
    &:hover {
      background-color: #0056b3;
    }
  }
  
  
  
  
  @media (max-width: 768px) {
    .formContainer {
      padding: 15px;
    }
    
    .formGroup {
      label {
        font-size: 14px;
      }
      
      .input,
      .select,
      .textarea,
      .checkbox {
        font-size: 14px;
      }
    }
    
    .submitButton {
      font-size: 14px;
    }
  }
  