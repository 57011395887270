@import '../Dashboard.module.scss';

.dashboard {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    height: 100%;
    .orders_array {
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

