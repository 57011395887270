


#button_5 {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(45deg, #ff6b6b, #f06595);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
    }
}
