.notificationsPage {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    
    h1 {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  
  .notificationsGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  