.glassForm {
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    animation: fadeScaleIn 0.6s ease-out;
  
    h2 {
      margin-bottom: 25px;
      font-size: 1.7em;
      color: #fff;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      input {
        padding: 10px;
        margin-bottom: 15px;
        border: none;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.2);
        color: #fff;
        transition: background-color 0.3s, box-shadow 0.3s;
  
        &:focus {
          background-color: rgba(255, 255, 255, 0.3);
          box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
          outline: none;
        }
      }
  
      button {
        padding: 12px;
        background-color: #fff;
        color: #000;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  
    @keyframes fadeScaleIn {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  