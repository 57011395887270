@import '../../../../variables.scss';

.label {
    text-align: left;
    margin-bottom: 0.5rem;
}

.tagInputContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    width: calc(100% - 16px);
    margin: 0px;
}

.tagInputContainer:hover,
.tagInputContainer:focus-within {
    box-shadow: 0 4px 8px rgba($primary-color, 0.1), 0 6px 20px rgba($primary-color, 0.2);
    border-radius: 6px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    display: flex;
    align-items: center;
    background-color: #007BFF;
    color: white;
    padding: 5px 10px;
    border-radius: 16px;
    margin: 4px;
    font-size: 14px;
}

.tagClose {
    margin: 0px;
    margin-left: 8px;
    cursor: pointer;
    color: white;
}

.input {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 14px;
    min-width: 100px;
}

.input::placeholder {
    color: #ccc;
}