.couponContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    background-color: #f3f4f6;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    animation: fadeIn 0.5s ease-out;
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: scale(0.95);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  
  .inputGroup {
    position: relative;
    flex: 1;
  
    input {
      width: calc(100% - 10px * 2);
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background: transparent;
      transition: border-color 0.3s, box-shadow 0.3s;
      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
      }
      &:focus + .label,
      &:not(:placeholder-shown) + .label {
        transform: translateY(-20px);
        font-size: 0.85em;
        color: #007bff;
        margin-top: -10px;
      }
    }
  
    .label {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      background-color: #f3f4f6;
      padding: 0 5px;
      pointer-events: none;
      transition: all 0.3s;
    }
  }
  
  .applyButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  
  .successMessage {
    color: green;
    margin-left: 10px;
  }
  