.card {
    padding: 25px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeInUp 0.6s ease-out;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
    }
  
    .title {
      font-size: 1.8em;
      color: #444;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .lineItem {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: 1em;
      color: #666;
    }
  
    .total {
      display: flex;
      justify-content: space-between;
      font-size: 1.4em;
      font-weight: bold;
      margin-top: 25px;
      color: #000;
    }
  
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  