.tableContainer {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #e0e0e0;
    
    th, td {
      padding: 15px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }
  
    th {
      background-color: #f4f4f4;
      font-weight: bold;
      text-align: left;
    }
  
    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }
    
    a {
      color: #007bff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  
    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
  
  .actions {
    display: flex;
    gap: 10px;
    
    button {
      padding: 8px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &.edit {
        background-color: #007bff;
        color: #fff;
      }
  
      &.remove {
        background-color: #dc3545;
        color: #fff;
      }
  
      &:hover {
        opacity: 0.9;
      }
    }
  }
  