@use '../../../variables.scss' as *;

.pricing_container {
    display: flex;
    flex-direction: column;
    margin-top: $margin-medium;

    .pricing_header {
        text-align: center;
        margin-bottom: $margin-medium;

        h6 {
            font-size: 16px;
            color: #007bff;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 36px;
            margin-bottom: 10px;
            color: #333;
        }

        p {
            font-size: 18px;
            color: #666;
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .pricing_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
}

.pricing_card {
    background: linear-gradient(135deg, #ffffff 0%, #f7f7f7 100%);
    border-radius: 12px;
    padding: 30px 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-width: 280px;
    margin: $margin-small;
    height: 360px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease forwards;
    animation-delay: calc(0.1s * var(--delay-index));

    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    }

    .header {
        margin-bottom: 20px;

        h3 {
            font-size: 22px;
            color: #333;
            margin-bottom: 5px;
            font-weight: 600;
        }
    }

    .price {
        font-size: 26px;
        color: #007bff;
        margin-bottom: 20px;
        font-weight: 700;
    }

    .features {
        list-style: none;
        padding: 0;
        margin-bottom: 30px;

        li {
            margin-bottom: 12px;
            font-size: 15px;
            color: #555;
        }
    }

    .button {
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 24px;
        padding: 12px 25px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
        box-shadow: 0 4px 12px rgba(0, 123, 255, 0.4);
        position: relative;
        top: 20px;

        &:hover {
            background-color: #0056b3;
            transform: translateY(-3px);
            box-shadow: 0 8px 16px rgba(0, 123, 255, 0.6);
        }
    }    
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 768px) {
    .pricing_card {
        min-width: 90%;
        margin-bottom: 20px;
    }
}
