.contactForm {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;

  .formGroup {
    position: relative;
    margin-bottom: 20px;

    .input,
    .textarea,
    .select {
      width: calc(100% - 20px);
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
      font-size: 16px;
      transition: border-color 0.3s, box-shadow 0.3s;
  
      &:focus + .label,
      &.filled  + .label {
        transform: translateY(-20px);
        font-size: 12px;
        color: #007bff;
      }
  
      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
      }
    }

    .select {
      background-color: #fff;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjE1Ij48cGF0aCBmaWxsPSIjNzk3OTc5IiBkPSJNNSAxMEw4IDEzIDEzIDVMNSAxMHoiLz48L3N2Zz4=');
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 12px;
      appearance: none;
      -webkit-appearance: none; /* For Safari */
      width: 100%;
    }

    .label {
      position: absolute;
      left: 12px;
      top: 12px;
      font-size: 14px;
      color: #999;
      pointer-events: none;
      transition: transform 0.3s, font-size 0.3s;
    }
  }

  .submitButton {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }

    &:active {
      background-color: #004085;
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
