@import "../../../variables.scss";



.card {
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-18px);
      box-shadow: 0 24px 48px rgba(0, 0, 0, 0.2);
    }
  }
  
  .imageContainer {
    width: 100%;
    padding-top: 55%;
    position: relative;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    padding: 35px;
    text-align: left;
  }
  
  .name {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #000;
  }
  
  .description {
    font-size: 1.2rem;
    color: #222;
    margin-bottom: 15px;
  }
  
  .priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-size: 1.4rem;
    color: #00332b;
    font-weight: bold;
  }
  
  .discountPrice {
    font-size: 1.2rem;
    color: #c62828;
    text-decoration: line-through;
  }
  