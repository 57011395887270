.metric {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    margin-right: 20px;
    text-align: center;

    h2 {
      margin: 0;
      font-size: 1.2em;
      color: #1a1a2e;
    }

    p {
      margin: 10px 0;
      font-size: 2em;
      font-weight: bold;
      color: #0f3460;
    }

    .comparison {
      font-size: 0.85em;
      color: red;
    }

    &:last-child {
      margin-right: 0;
    }
}