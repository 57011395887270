.ticketsPage {
  background-color: #f4f5f7;
  min-height: 100vh;

  .content {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }
}
