@import '../../../variables.scss';

.registrationForm {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  min-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.8s ease-in-out;
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 20px;
    
    .loginButton {
      background: none;
      border: none;
      color: $primary-color;
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .inputGroup {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .separatorRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

    p {
      margin: 0 10px;
      font-size: 14px;
      color: #888;
    }

    .separatorLine {
      flex: 1;
      height: 1px;
      background-color: #ddd;
    }
  }

  .error {
    color: $danger-color;
    text-align: center;
    margin-bottom: 20px;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
