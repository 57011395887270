.parallaxContainer {
  perspective: 1px;
  height: 100%;
  overflow-x: hidden;
}

.parallaxItem {
  position: relative;
  height: 300px;
  margin: 40px 0;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  &:hover .parallaxBackground {
    transform: translateZ(1px) scale(1.1);
  }
}

.parallaxBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  transition: transform 0.3s ease;
}

.productInfo {
  position: relative;
  z-index: 1;
  padding: 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 10px;
}

h3 {
  margin: 10px 0;
}

p {
  font-size: 16px;
  line-height: 1.5;
}
