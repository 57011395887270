/* CustomerSupportTickets.module.scss */

@import '../../../../variables.scss';

.customerSupportTickets {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8em;
  }

  .loading,
  .noTickets {
    text-align: center;
    color: #666;
    font-size: 1.2em;
    padding: 40px 0;
  }

  .ticketsTable {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: #f5f5f5;

      th {
        padding: 12px 15px;
        text-align: left;
        font-weight: 600;
        color: #555;
        border-bottom: 1px solid #e0e0e0;
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #fafafa;
        }
      }

      td {
        padding: 12px 15px;
        border-bottom: 1px solid #e0e0e0;
        color: #555;
        vertical-align: middle;

        &:last-child {
          text-align: right;
        }

        svg {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
  }

  .statusBadge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    font-weight: 600;
    color: #fff;

    &.statusOpen {
      background-color: #ff9800;
    }

    &.statusPending {
      background-color: #2196f3;
    }

    &.statusResolved {
      background-color: #4caf50;
    }

    &.statusClosed {
      background-color: #9e9e9e;
    }
  }

  .viewButton {
    color: $primary-color;
    text-decoration: none;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 4px;

    svg {
      font-size: 0.9em;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .readIcon {
    color: #9e9e9e;
  }

  .unreadIcon {
    color: $primary-color;
  }
}
