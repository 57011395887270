@import '../../variables.scss';

.categoriesPage {
    
    h1 {
      text-align: center;
      margin-bottom: 20px;
      color: #333333;
    }
  
    .categoryList {
      margin-top: $margin-medium;
    }
}
  