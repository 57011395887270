.trustSignals {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.6s ease-out;
    color: #fff;
    text-align: center;
  
    .trustItem {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
  
      .icon {
        font-size: 2.5em;
        color: #fff;
        margin-bottom: 10px;
        transition: transform 0.3s;
      }
  
      p {
        font-size: 1em;
        color: #ddd;
        font-weight: bold;
      }
  
      &:hover .icon {
        transform: scale(1.2);
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  