@import '../../../variables.scss';

.customizableTabHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - $margin-medium * 2);
  padding: $margin-small $margin-medium;
  background-color: #fff;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px; /* Adjust size for motif spacing */
  opacity: 0.9;
  
  &.headerFixed {
    position: fixed;
    top: 0;
  }

  &.headerSticky {
    position: sticky;
    top: 0;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto; /* Allow the container to grow */
  }
  .shopNameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto; /* Allow the container to grow */
    font-size: 1.2em;
    font-weight: bold;
    white-space: nowrap; /* Prevent text wrapping */
  }

  .navItems {
    display: flex;
    gap: 20px;
    width: calc(100% - $margin-medium * 2);
    justify-content: center;
    flex: 1 1 auto; /* Allow the container to grow more */
    .navItem {
      text-decoration: none;
      color: inherit;
      font-weight: bold;
      position: relative;

      &:hover {
        color: #007bff;
      }
    }

    .navItemWithFlyout {
      position: relative;

      &:hover .flyoutMenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  .iconContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 auto; /* Allow the container to grow */
  }

  .icon {
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }
  }

  .menuIcon {
    font-size: 1.5em;
    cursor: pointer;
  }

  .flyoutMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% - $margin-medium * 2);
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: $margin-medium;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.3s ease;

    &.flyoutMenuVisible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    .flyoutItem {
      flex: 1 0 20%;
      margin: 10px 0;
      text-decoration: none;
      color: inherit;
      transition: color 0.3s;

      &:hover {
        color: #007bff;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &:nth-child(2) {
        margin-top: $margin-small;
    }
  }

  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1001;

    &.open {
      transform: translateX(0);
    }

    .drawerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #ddd;
    }

    .drawerContent {
      padding: 1rem;
    }

    .navItem {
      padding: 0.5rem 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chevron {
        transition: transform 0.3s ease;
      }

      &.open .chevron {
        transform: rotate(90deg);
      }
    }

    .subNav {
      padding-left: 1rem;
      .subNavItem {
        padding: 0.5rem 0;
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;

    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
}
