.cardContainer {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
  
    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
      height: 12px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
      border: 3px solid transparent;
      background-clip: padding-box;
      transition: background-color 0.3s ease;
  
      &:hover {
        background: #555;
      }
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
  }
  
  .cardsWrapper {
    display: flex;
    gap: 20px;
  }
  
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 0 0 auto;
    width: 300px;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  
    &:hover {
      background-color: #f0f0f0; /* Slight change on hover */
      transform: translateY(-5px); /* Slight lift on hover */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    }
  }
  
  .productImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .productInfo {
    padding: 20px;
    text-align: center;
  
    h3, p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
  h3 {
    margin: 10px 0;
    font-size: 20px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
  }
  
  .price {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    color: #00796b;
    font-weight: bold;
  }
  