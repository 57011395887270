@import '../../../../variables.scss';

.product_list {
    display: flex;
    flex-direction: column;
    margin-top: $margin-small;
    overflow: hidden;
    width: calc(100% - 20px * 2);
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    .search_bar {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 14px;
    }

    .product_list_container {
        display: flex;
        overflow-x: auto;
        /* Make the container take full available width */
        width: 100%;
        /* Add some spacing between items */
        gap: 10px;
        
        /* Hide scrollbar, only if necessary */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        
        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }
    }
}
