.blogSectionV2 {
    display: flex;
    overflow-x: auto;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding-bottom: 30px;
  }
  
  .blogCard {
    min-width: 300px;
    margin-right: 20px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  }
  
  .blogImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .blogCard:hover .blogImage {
    transform: scale(1.1);
  }
  
  .blogContent {
    padding: 20px;
  
    h3 {
      margin: 0;
      font-size: 1.5rem;
      color: #333;
    }
  
    p {
      margin: 10px 0;
      color: #777;
    }
  }
  