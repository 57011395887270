@import '../../../../variables.scss';

.orderCustomerInfo {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(100% - 40px);    
    margin: 0 auto;
    margin-top: $margin-medium;
    margin-bottom: 20px;
    h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }
    h4 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .editButton {
            margin: 0px;
        }
    }
}


.section {
    margin-bottom: 20px;
}

.label {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
}

.separator {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    margin: 20px 0;
}

.editButton, .saveButton {
    background-color: #1890ff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-left: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #40a9ff;
        transform: scale(1.05);
    }

    &:focus {
        outline: none;
    }
}

.adressHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.inputField {
    width: calc(100% - 10px * 2);
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.addressForm {
    margin-top: 10px;
}