@use '../../../variables.scss' as *;

.incentiveContainer {
    padding: $margin-medium;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title {
        font-size: $font-size-title;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: $margin-small;
    }

    .description {
        font-size: $font-size-base;
        color: grey;
        max-width: 600px;
        margin-bottom: $margin-medium;
    }

    .cardList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: $margin-medium;
    }
}

.incentiveCard {
    padding: 20px;
    border-radius: 12px;
    background-color: #f8f9fa;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 150px;

    .incentiveIcon {
        font-size: 50px;
        color: $primary-color;
        margin-bottom: 15px;
        transition: color 0.3s ease;
    }

    .incentiveTitle {
        font-size: $font-size-small;
        font-weight: 500;
        color: 'grey';
        transition: color 0.3s ease;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        background-color: $primary-color;
        
        .incentiveIcon {
            color: #fff;
        }

        .incentiveTitle {
            color: #fff;
        }
    }
}
