.addressForm {
    background-color: #f9fafb;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 100%;
    margin: auto;
    color: #333;
    margin-top: 20px;
  
    h4 {
      margin-bottom: 20px;
      font-size: 1.8rem;
      font-weight: 600;
      color: #333;
      text-align: left;
    }
  
    .formGroup {
      margin-bottom: 16px;
    }
  
    .sameAsShipping {
      margin: 16px 0;
      font-size: 1rem;
      display: flex;
      align-items: center;
  
      label {
          color: #555;
          display: flex;
          align-items: center;
      }
  
      input[type="checkbox"] {
          margin-right: 8px; // Adds space between the checkbox and the label text
          transform: scale(1.2);
          cursor: pointer;
      }
  }
  }
  