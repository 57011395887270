$color-1: #0f3460;  // Main text
$color-2: #e94560;  // Accent color for important elements (like pending orders)
$color-3: #4bc0c0;  // Completed and confirmed status
$color-border: hsl(0, 0%, 88%);  // Borders and dividers
$color-4: rgba(153, 102, 255, 1);  // Shipped status



.campaignList {
  background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    margin: 10px auto;
    overflow: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.table th, .table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: $color-1;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.table tr {
  transition: background-color 0.3s;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.actions {
  display: flex;
  gap: 10px;
}

button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

button.view {
  background-color: #2196f3; /* Blue */
  color: white;
}

button.view:hover {
  background-color: darken(#2196f3, 10%);
}

button.edit {
  background-color: #ffb74d; /* Orange */
  color: white;
}

button.edit:hover {
  background-color: darken(#ffb74d, 10%);
}

button.send {
  background-color: #66bb6a; /* Green */
  color: white;
}

button.send:hover {
  background-color: darken(#66bb6a, 10%);
}

button.delete {
  background-color: #e57373; /* Red */
  color: white;
}

button.delete:hover {
  background-color: darken(#e57373, 10%);
}
