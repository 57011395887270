@use '../../../variables.scss' as *;

$alert-background-color: #dff0d8;
$alert-border-color: #d0e9c6;
$alert-text-color: #3c763d;
$icon-color: #3c763d;
$close-icon-color: #a94442;
$animation-duration: 0.4s;

.success_alert {
  display: flex;
  align-items: center;
  background-color: $alert-background-color;
  border: 1px solid $alert-border-color;
  border-radius: 8px;
  padding: 16px;
  margin: 10px 0;
  color: $alert-text-color;
  font-size: 1rem;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn $animation-duration ease forwards;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .success_alert_check_icon {
    margin-right: 12px;

    .icon {
      color: $icon-color;
      font-size: 24px;
      animation: pulse $animation-duration infinite alternate;
    }
  }

  h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .success_alert_message {
    margin: 4px 0 0;
    font-size: 0.95rem;
    line-height: 1.4;
  }

  .success_alert_close_button {
    margin-left: auto;
    font-size: 1.2rem;
    cursor: pointer;
    color: $close-icon-color;
    transition: color 0.3s ease;

    &:hover {
      color: darken($close-icon-color, 10%);
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
