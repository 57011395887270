/* CustomerInfoForm.module.scss */

.customerInfoForm {
    background-color: #f9fafb;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 100%;
    margin: auto;
    color: #333;
    margin-top: 20px;
  
    h4 {
      margin-bottom: 20px;
      font-size: 1.8rem;
      font-weight: 600;
      color: #333;
      text-align: left;
    }
  }
  
  .formGroup {
    margin-bottom: 16px;
  
    .error {
      color: #e74c3c;
      font-size: 0.85rem;
      margin-top: 4px;
      display: block;
    }
  }

  
  