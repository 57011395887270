.slide_over {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 80%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    transition: bottom 0.3s ease-in-out;
    z-index: 100;
  }
  
  .slide_over.open {
    bottom: 0;
  }
  
  .slide_over_content {
    height: 100%;
    overflow-y: auto;
  }
  
  .close_button {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
    background-color: white; /* Add a background color for the shadow to be visible */
    border-radius: 50%; /* Make the button circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition on hover */
    width: 30px;
    height: 30px;
    &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
        transform: translateY(-2px); /* Slight lift effect on hover */
    }
}
