.categoryFilterContainer {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    animation: slideIn 0.5s ease;
  
    h3 {
      margin-bottom: 10px;
      color: #333;
    }
  
    .categoryList {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .categoryItem {
      padding: 10px 15px;
      border-radius: 5px;
      background: #f1f1f1;
      cursor: pointer;
      transition: background 0.3s ease, transform 0.3s ease;
  
      &.selected {
        background: #007bff;
        color: #fff;
        transform: scale(1.05);
      }
  
      &:hover {
        background: #e1e1e1;
      }
    }
  }
  