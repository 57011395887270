.bannerContainer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: white;
    text-align: center;
  
    &:hover .bannerOverlay {
      opacity: 1;
    }
  
    .bannerOverlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
  
      h1, h2 {
        margin: 0;
      }
  
      h1 {
        font-size: 2.5em;
        animation: fadeInDown 1s;
      }
  
      h2 {
        font-size: 1.5em;
        margin: 10px 0;
        animation: fadeInUp 1s;
      }
  
      .callToAction {
        padding: 10px 20px;
        background: #00796b;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        transition: background 0.3s;
  
        &:hover {
          background: #004d40;
        }
      }
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  