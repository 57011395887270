@import '../../variables.scss';

.registerPage {
  display: flex;
  min-height: 100vh;
  background-color: #f4f4f4;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
}

.formContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 0;
  animation: slideInFromLeft 0.8s ease-in-out;

  @media (max-width: 768px) {
    border-radius: 8px;
    padding: 20px;
    box-shadow: none;
    animation: fadeIn 0.8s ease-in-out;
  }
}

.imageContainer {
  flex: 1;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
  animation: slideInFromRight 0.8s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;

    @media (max-width: 768px) {
      border-radius: 0 0 8px 8px;
      height: 250px;
    }
  }

  @media (max-width: 768px) {
    order: -1;
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
