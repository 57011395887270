// src/styles/global.scss
@import '~react-quill/dist/quill.snow.css';

:root {
  /* Primary Colors */
  --primary-color: #4A90E2;
  --secondary-color: #50E3C2;
  
  /* Neutral Colors */
  --background-color: #F5F7FA;
  --surface-color: #FFFFFF;
  --text-primary-color: #333333;
  --text-secondary-color: #666666;
  
  /* Accent Colors */
  --accent-color-1: #FF5A5F;
  --accent-color-2: #F8E71C;
  
  /* Additional Colors */
  --success-color: #7ED321;
  --info-color: #50BFE6;
}

* {
  padding: 0px;
  margin: 0px;
}

body {
  background-color: var(--background-color);
  color: black;
}
