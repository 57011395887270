// SizeSelectorV1.module.scss

.selectorContainer {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0;
  font-family: 'Arial', sans-serif;

  h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
  }

  .sizeDropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 15px * 2);
    padding: 10px 15px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    .selectedSize {
      font-size: 1em;
      color: #333;
      padding: 0px;
      margin: 0px;
    }

    .dropdownIcon {
      font-size: 0.8em;
      color: #777;
      transition: transform 0.3s ease;
      padding: 0px;
      margin: 0px;
    }
  }

  .dropdownList {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    .dropdownItem {
      padding: 10px 15px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: #f1f1f1;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }

  .sizeGuide {
    text-align: left;
    margin-top: 10px;
    font-size: 0.9em;
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #0056b3;
    }
  }
}
