.historySection {
    padding: 40px 20px;
    background-color: #f0f0f0;
    border-radius: 15px;
    animation: fadeIn 0.5s ease-out;
  
    .title {
      text-align: center;
      font-size: 2.5em;
      margin-bottom: 20px;
      color: #333;
    }
  
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
  
      .gridItem {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
        animation: zoomIn 0.5s ease-out;
  
        &:hover {
          transform: translateY(-10px);
        }
  
        .date {
          font-size: 1em;
          color: #007bff;
          margin-bottom: 10px;
        }
  
        .image {
          width: 100%;
          height: auto;
          margin-bottom: 10px;
          border-radius: 5px;
        }
  
        .event {
          font-size: 1.5em;
          color: #333;
          margin-bottom: 10px;
        }
  
        .description {
          color: #666;
          font-size: 1em;
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    @keyframes zoomIn {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  