@import '../../../variables.scss';

.shipping_settings_page {
  }
  
  .shippingMethod {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    gap: $margin-small;
  }