/* ReturnRequestStatus.module.scss */

$return-bg-color: #ffffff;
$return-border-color: #e0e0e0;
$return-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
$return-border-radius: 8px;
$return-padding: 24px;
$return-gap: 16px;
$primary-color: #4a90e2; // Modern soft blue
$primary-color-hover: darken($primary-color, 10%);
$font-color: #333;
$font-size-heading: 1.5em;
$font-size-label: 1em;
$transition-duration: 0.3s;
$disabled-button-bg: #cccccc;
$disabled-button-text: #666666;

.returnRequestUpdate {
    width: calc(100% - 20px * 2 - 4px);
    margin-top: 20px;
    padding: $return-padding;
    background-color: $return-bg-color;
    border-radius: $return-border-radius;
    box-shadow: $return-box-shadow;
    flex-direction: column;
    h2 {
        font-size: $font-size-heading;
        margin-bottom: 16px;
        color: $font-color;
    }
}



.saveButton {
    padding: 12px 24px;
    background-color: #4a90e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken(#4a90e2, 10%);
    }

    &:disabled {
        background-color: $disabled-button-bg;
        color: $disabled-button-text;
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(#4a90e2, 0.3);
    }
}