@use '../../../variables.scss' as *;
 
.blog_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
}
.blog_card {
        background: white;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 100%;
        overflow: hidden;
        transition: transform 0.3s;
        &:hover {
            transform: translateY(-10px);
        
        }
    .image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    .content {
        padding: 20px;
    }
    .title {
        margin: 0 0 10px;
        font-size: 24px;
    }
    .summary {
        font-size: 16px;
        color: #666;
    }
    .meta {
        font-size: 14px;
        color: #aaa;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
}
  