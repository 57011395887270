@import '../../../variables.scss';

$color-1: #0f3460;  // Main headings, primary text, key information
$color-2: #1a1a2e;  // Main background areas
$color-3: #e94560;  // Accent elements, alerts, important notifications
$color-border: hsl(0, 0%, 88%);  // Borders, dividers, and outlines
$color-4: #4bc0c0;  // Secondary action buttons, links, highlights
$color-5: rgba(153, 102, 255, 1);  // Specific elements, charts, graphics

.productTable {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    align-self: center;
    align-items: center;
    table {
      width: 100%;
      border-collapse: collapse;
      color: $color-1;
      overflow: hidden;
      table-layout: fixed;
      th {
        padding: 15px;
        text-align: left;
        background-color: $primary-color;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        border-bottom: 1px solid $color-border;
        &:nth-child(1) {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
  
      td {
        padding: 15px;
        text-align: left;
        border-bottom: 1px solid $color-border;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        border-bottom: 1px solid $color-border;
        &:first-child {
          font-weight: bold;
        }
  
        &.order-status {
          &.pending {
            color: $color-3;
          }
          &.confirmed, &.completed {
            color: $color-4;
          }
          &.shipped {
            color: $color-5;
          }
          &.cancelled {
            color: $color-3;
          }
        }
  
        &.payment-status {
          &.pending {
            color: $color-3;
          }
          &.completed {
            color: $color-4;
          }
          &.failed {
            color: $color-3;
          }
        }
      }
  
      tbody tr {
        cursor: pointer;
        transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  
        &:hover {
          background-color: #f5f5f5;
          transform: translateY(-3px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        }
      }
  
      tbody tr:nth-child(odd) {
        background-color: #fafafa;
      }
  
      .productImage {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
  
    @media (max-width: 768px) {
      table, thead, tbody, th, td, tr {
        display: block;
      }
  
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
  
      tr {
        margin: 0 0 1rem 0;
      }
  
      td {
        border: none;
        position: relative;
        padding-left: 50%;
  
        &:before {
          position: absolute;
          top: 15px;
          left: 10px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
          content: attr(data-label);
          font-weight: bold;
        }
      }
    }
  }

  td {
    button {
      border: none;
      outline: none;
      padding: 8px 12px;
      margin: 0 5px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease, transform 0.2s ease;
      
      svg {
        margin-right: 5px;
        font-size: 18px;
      }
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  
    .edit_btn {
      background-color: $color-4;  // Secondary action color (e.g., for edit)
      color: white;
  
      &:hover {
        background-color: darken($color-4, 10%);
      }
    }
  
    .delete_btn {
      background-color: $color-3;  // Accent color (e.g., for delete)
      color: white;
  
      &:hover {
        background-color: darken($color-3, 10%);
      }
    }
  }
  