@import '../../../variables.scss';

.container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;

  h2 {
    margin-bottom: 16px;
    color: #333;
  }

  .newMessageTextarea {
    width: calc(100% - 12px * 2);
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-size: 1rem;
    &:focus {
      border-color: #4a90e2;
      outline: none;
    }
  }

  .chatHistory {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 4px;
    background-color: #f9f9f9;
  }

  .chatMessage {
    margin-bottom: 10px;

    &.adminMessage {
      text-align: right;

      .chatMessageHeader {
        color: #4a90e2;
      }

      .chatMessageBody {
        background-color: #e6f2ff;
        display: inline-block;
        padding: 8px 12px;
        border-radius: 12px 12px 0 12px;
      }
    }

    &.customerMessage {
      text-align: left;

      .chatMessageHeader {
        color: #333;
      }

      .chatMessageBody {
        background-color: #f1f1f1;
        display: inline-block;
        padding: 8px 12px;
        border-radius: 12px 12px 12px 0;
      }
    }
  }

  .chatMessageHeader {
    font-size: 1rem;
    margin-bottom: 4px;
  }

  .chatMessageBody {
    font-size: 1em;
    color: #333;
  }

  .newMessageContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .sendButton {
      display: inline-flex; /* Align button size to its content */
      align-items: center;
      background-color: $accent-color;
      color: #fff;
      padding: 8px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color $transition-duration ease-in-out;
      width: auto; /* Ensure the button only takes as much width as its content */
      justify-content: flex-start; /* Align button to the left */
      margin: 0; /* Ensure no additional margins affect the layout */
      margin-top: 10px;
      max-width: 50px;
      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}
