@import '../../../variables.scss';

.details {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
    margin: 20px auto;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}

.detailItem {
    font-size: 1rem;
    margin: 10px 0;

    strong {
        color: $primary-color;
    }
}

.separator {
    border: none;
    border-top: 1px solid $border-color;
    margin: 10px 0;
}


.refuse_section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
    margin: 20px auto;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}


.buttonContainer {
    margin-top: 20px;
    gap: 20px;
    width: 100%;
}