@import '../../../../variables.scss';

.orderStatus {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(100% - 40px);    
    margin: 0 auto;
    margin-top: $margin-medium;
    h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }
}

.section {
    margin-bottom: 20px;
}

.button1 {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.button1:hover {
    background-color: #0056b3;
}


