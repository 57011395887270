@import '../../../variables.scss';


.reviewCard {
    background-color: #fff;
    padding: $margin-medium;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $margin-large;
    width: calc(100% - $margin-medium * 2);
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
  
    .productInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      h3 {
        font-size: 1.5rem;
        margin-top: 1rem;
        color: #333;
      }
    }
  
    .productImage {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
    }
  
    .rating {
      display: flex;
      gap: 0.5rem;
      margin: 1rem 0;
  
      .star {
        cursor: pointer;
        transition: color 0.3s;
      }
    }
  
    .reviewText {
      width: 100%;
      height: 100px;
      padding: $margin-medium;
      border-radius: 8px;
      border: 1px solid #ccc;
      margin-bottom: 1rem;
      font-size: 1rem;
      resize: none;
      outline: none;
      transition: border-color 0.3s ease;
      width: calc(100% - $margin-medium * 2);

      &:focus {
        border-color: #5a67d8;
      }
    }
  
    .submitButton {
      background-color: #5a67d8;
      color: #fff;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;
      margin: 0px;
      &:hover {
        background-color: #434190;
      }
    }
  }
  