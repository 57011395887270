@import '../../../variables.scss';

$modal-width: 400px;
$transition-duration: 0.3s;
$background-color: #fff;
$overlay-color: rgba(0, 0, 0, 0.5);
$button-radius: 5px;

.filterButton {
  background-color: $primary-color;
  color: $background-color;
  border: none;
  border-radius: $button-radius;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform $transition-duration ease, background-color $transition-duration ease;
  margin: 0px;
  white-space: nowrap;
  width: auto;
  &:hover {
    background-color: darken($primary-color, 10%);
    transform: translateY(-3px);
  }
}

.modal {
  position: fixed;
  top: 0;
  right: -$modal-width;
  width: $modal-width;
  height: 100%;
  background-color: $background-color;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: right $transition-duration ease;
  z-index: 10000;
  overflow-y: auto;
  z-index: 10;

  &.open {
    right: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration ease;
  z-index: 10;


  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalHeader {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.modalBody {
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  .filterSection {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
    }

    .options {
      .optionLabel {
        display: block;
        margin-bottom: 10px;

        input {
          margin-right: 10px;
        }
      }
    }
  }
}

.modalFooter {
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;

  .applyButton,
  .clearButton {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: $button-radius;
  }

  .applyButton {
    background-color: $primary-color;
    color: white;
  }

  .clearButton {
    background-color: #f8f9fa;
    color: $primary-color;
  }
}
