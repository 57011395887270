@use '../../../variables.scss' as *;

.avatar_container {
    display: flex;
    align-items: center;
  }
  
  .avatar_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  
  .avatar_info {
    display: flex;
    flex-direction: column;    
  }
  
  .avatar_name {
    font-weight: bold;
    font-size: 16px;
  }
  
  .avatar_description {
    font-size: 14px;
    color: #999;
  }