.cartSummary {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
  }

  .itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 25px;

    .card {
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.85);
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease, transform 0.3s ease;

      &:hover {
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
        transform: translateY(-5px);
      }

      .cardImage {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        margin-right: 20px;
        object-fit: cover;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      .cardDetails {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .cardTitle {
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 8px;
          color: #333;
        }

        .cardQuantity,
        .cardPrice {
          font-size: 0.95rem;
          color: #666;
        }

        .cardPrice {
          font-weight: bold;
          color: #007bff;
        }
      }
    }
  }

  .summaryDetails {
    font-size: 1.1rem;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .subtotal,
    .total,
    .taxes,
    .shipping {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      &:first-child {
        border-top: none;
      }

      &.total {
        font-size: 1.3rem;
        font-weight: bold;
        color: #333;
      }
    }
  }

  .checkoutButton {
    width: 100%;
    padding: 15px;
    background-color: #ff6f61;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: #e65c50;
      transform: translateY(-3px);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}
