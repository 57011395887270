@use '../../../variables.scss' as *;

.productCategoryContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: calc((50vw - $margin-small ) / 2);
  gap: $margin-small;
  width: 100%;
}

.productCategoryWrapper {
  &.big {
    grid-column: 1 / 2; /* Big item spans one column */
    grid-row: span 2; /* Big item spans two rows */
    height: calc(50vw - $margin-small); /* Full height of two rows */
  }

  &.small {
    grid-column: 2 / 3;
  }

  &:nth-child(3n + 1) {
    grid-column: 1 / 2;
    grid-row: span 2;
    height: calc(50vw - $margin-small); /* Full height of two rows */
  }

  &:nth-child(3n + 2),
  &:nth-child(3n + 3) {
    grid-column: 2 / 3;
    height: calc((50vw - $margin-small) / 2); /* Half of the big square */
  }
}

.productCategoryCard {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: 12px;
  }

  .overlay {
    background-color: rgba($color: #000000, $alpha: 0.2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .content {
    z-index: 2;
    position: absolute;
    bottom: $margin-medium;
    left: 0;
    width: 100%;
    padding: $margin-medium;

    h5 {
      font-size: $font-size-heading;
      color: white;
    }

    p {
      margin-top: $margin-small;
      font-size: $font-size-heading;
      color: white;
    }
  }

  &:hover img {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 600px) {
  .productCategoryContainer {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .productCategoryWrapper {
    &.big {
      grid-column: 1 / 2;
      grid-row: auto;
      width: 100%;
      height: calc(100vw - $margin-small);
    }

    &.small {
      grid-column: 1 / 2;
      grid-row: auto;
      width: 100%;
      height: calc(50vw);
    }
  }
}
