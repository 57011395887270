// BasicBreadcrumbs.module.scss
.breadcrumbContainer {
    display: flex;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
  
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  
  .breadcrumbItem {
    a {
      color: #007bff;
      text-decoration: none;
      transition: color 0.3s ease;
  
      &:hover {
        color: #0056b3;
      }
    }
  
    .separator {
      margin: 0 8px;
      color: #666;
    }
  }
  