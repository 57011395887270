@import "../../../variables.scss";



.card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .image {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    .cardContent {
      padding: 10px;
      text-align: center;

      .productName {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }

      .productDescription {
        font-size: 14px;
        color: #777;
        margin: 5px 0;
      }

      .productPrice {
        font-size: 16px;
        color: #007bff;
      }
    }
  }