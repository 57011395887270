.incentiveContainer {
    background: #f4f4f4;
    padding: 40px;
    border-radius: 10px;
    text-align: left;
    transition: background 0.3s ease;
  
    &:hover {
      background: #e0e0e0;
    }
  
    h2 {
      font-size: 2em;
      color: #333;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 1.2em;
      color: #666;
      margin-bottom: 30px;
    }
  
    .itemsContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        width: 45%;
        transition: transform 0.3s ease;
  
        &:hover {
          transform: translateY(-10px);
        }
  
        .icon {
          font-size: 2em;
          color: #00796b;
          margin-right: 15px;
        }
  
        .textContent {
          h3 {
            font-size: 1.5em;
            color: #333;
          }
  
          p {
            font-size: 1em;
            color: #666;
          }
        }
      }
    }
  }
  