.colorPickerContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.colorPickerLabel {
    margin-right: 10px;
    font-size: 16px;
    color: #666;
}

.colorPickerInput {
    border: 2px solid #ccc;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;

    // Remove the default styles for the color input
    &::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    &::-webkit-color-swatch {
        border: none;
    }
}
