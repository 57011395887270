.tableContainer {
    margin: 20px auto;
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    th, td {
        padding: 16px;
        border-bottom: 1px solid #e0e0e0;
    }

    th {
        background-color: #f4f4f4;
        font-weight: 600;
        color: #555;
    }

    tbody tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    tbody tr:hover {
        background-color: #f1f1f1;
    }
}

.actionButtons {
    display: flex;
    gap: 8px;

    button {
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        font-size: 0.9rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .editButton {
        background-color: #007bff;
        color: white;

        &:hover {
            background-color: #0056b3;
        }
    }

    .deleteButton {
        background-color: #dc3545;
        color: white;

        &:hover {
            background-color: #c82333;
        }
    }

    .enableButton {
        background-color: #28a745;
        color: white;

        &:hover {
            background-color: #218838;
        }
    }

    .disableButton {
        background-color: #ffc107;
        color: white;

        &:hover {
            background-color: #e0a800;
        }
    }
}

.enabled {
    color: #28a745;
    font-weight: bold;
}

.disabled {
    color: #dc3545;
    font-weight: bold;
}
