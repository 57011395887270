@import '../../../variables.scss';

.product_category {
    max-width: 100%;  
}  

.horizontal_scroll_view {
    display: flex;
    overflow-x: scroll;
    gap: 16px;
    padding: 16px;
    scroll-behavior: smooth;
    flex-wrap: nowrap; 
    white-space: nowrap; 
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

#scroll_bar_category {
    height: 5px;
    background-color: black;
}

.product_category_card {
    flex: 0 0 auto; 
    width: 300px; 
    height: 300px; 
    display: flex;
    flex-direction: column-reverse;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 0;
      transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
      border-radius: 12px;
    }
  
    .overlay {
      background-color: rgba(#000000, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  
    .content {
      z-index: 2;
      position: absolute;
      bottom: $margin-medium;
      left: 0;
      width: 100%;
      padding: $margin-medium;
  
      h5 {
        font-size: $font-size-heading;
        color: white;
      }
  
      p {
        margin-top: $margin-small;
        font-size: $font-size-heading;
        color: white;
      }
    }
  
    &:hover img {
      transform: scale(1.2);
    }
  }
