.historySection {
    border-radius: 15px;
    animation: fadeIn 0.5s ease-out;
    padding: 2rem;
    background-color:  white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: fadeIn 0.5s ease-out;
    .title {
      font-size: 1.8rem; // Slightly larger than default
      font-weight: 600; // Bold
      text-transform: uppercase; // Optional: Uppercase for emphasis
      color: #333; // Darker color for better readability
      border-bottom: 2px solid #e0e0e0; // Optional: Adds a clean underline
      padding-bottom: 10px; // Space between title and content
      margin-bottom: 20px; // Space between the title and the next element
      text-align: center;
    }
  
    .timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .timelineItem {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        margin: 10px 0;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: calc(100% - 20px * 2);
        position: relative;
        animation: slideIn 0.5s ease-out;
  
        &:before {
          content: '';
          position: absolute;
          left: -20px;
          top: 10px;
          height: 100%;
          width: 2px;
          background-color: #007bff;
        }
  
        .date {
          font-size: 1em;
          color: #007bff;
          margin-bottom: 10px;
        }
  
        .content {
          .image {
            width: 100%;
            height: auto;
            max-width: 150px;
            float: left;
            margin-right: 20px;
            border-radius: 5px;
          }
  
          .event {
            font-size: 1.5em;
            color: #333;
            margin-bottom: 10px;
          }
  
          .description {
            color: #666;
            font-size: 1em;
          }
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  