.carouselContainer {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  .prevButton,
  .nextButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 24px;
    z-index: 1;

    svg {
      padding: 0;
      margin: 0;
      left: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .prevButton {
    left: 10px;
  }

  .nextButton {
    right: 10px;
  }

  .carousel {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 20px 0;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE 10+ */

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
    }
  }

  .productCard {
    flex: 0 0 auto;
    width: 250px;
    margin: 0 10px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    .productImage {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }

    .productInfo {
      padding: 15px;
      text-align: center;

      .productName {
        font-size: 18px;
        margin: 10px 0;
        color: #333;
      }

      .productPrice {
        font-size: 16px;
        color: #00796b;
      }
    }
  }
}

.scrollIndicatorContainer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.1); // Light background for the track
  border-radius: 2px;
  overflow: hidden;
}

/* Dynamic Scroll Indicator */
.scrollIndicator {
  height: 100%;
  background-color: #000; // Black color for the indicator
  border-radius: 2px;
  transition: width 0.3s ease;
}
