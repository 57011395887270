@mixin responsive($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

.masonryGrid {
  column-count: 4;
  column-gap: 20px;
  padding: 20px;

  @include responsive(1200px) {
    column-count: 3;
  }

  @include responsive(900px) {
    column-count: 2;
  }

  @include responsive(600px) {
    column-count: 1;
  }
}

.masonryItem {
  background: #fff;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  min-width: 200px;
  transition: background-color 0.3s ease, opacity 0.3s ease;

  &:hover {
    background-color: #f0f0f0; /* Subtle background color change */
    opacity: 0.9; /* Slightly lower opacity */
  }
}

.productImage {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 1px solid #ddd;
}

.productInfo {
  padding: 20px;
  text-align: center;
}

h3 {
  margin: 10px 0;
  font-size: 20px;
  color: #333;
}

p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.price {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  color: #00796b;
  font-weight: bold;
}
