/* CustomerWishlist.module.scss */

@import '../../../../variables.scss';

.customerWishlist {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      color: #333;
      font-size: 1.8em;
    }

    .addButton {
        margin: 0px;
      background-color: $primary-color;
      color: #fff;
      border: none;
      padding: 10px 16px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1em;
      display: flex;
      align-items: center;
      gap: 8px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      svg {
        font-size: 1.2em;
      }
    }
  }

  .loading,
  .emptyWishlist {
    text-align: center;
    color: #666;
    font-size: 1.2em;
    padding: 40px 0;
  }

  .searchContainer {
    margin-top: 20px;

    .searchBox {
      position: relative;
      margin-bottom: 20px;

      .searchIcon {
        position: absolute;
        top: 12px;
        left: 12px;
        color: #888;
      }

      input {
        width: 100%;
        padding: 10px 12px 10px 36px;
        border: 1px solid #ccc;
        border-radius: 8px;
        font-size: 1em;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
        }
      }
    }

    .searchResults {
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 10px;

      .searchResultItem {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;

        &:last-child {
          border-bottom: none;
        }

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
        }

        .productInfo {
          flex: 1;

          .productName {
            display: block;
            font-weight: bold;
            color: #333;
          }

          .productPrice {
            color: #888;
          }
        }

        .addProductButton {
          margin: 0px;
          background-color: $primary-color;
          color: #fff;
          border: none;
          padding: 6px 12px;
          border-radius: 8px;
          cursor: pointer;
          font-size: 0.9em;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken($primary-color, 10%);
          }
        }
      }
    }
  }

  .wishlistItems {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }

  .wishlistItem {
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    align-items: center;
    width: calc(50% - 10px);

    @media (max-width: 768px) {
      width: 100%;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 20px;
    }

    .itemInfo {
      flex: 1;

      .productName {
        display: block;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
      }

      .productPrice {
        color: $primary-color;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .addedAt {
        color: #888;
        font-size: 0.9em;
      }
    }

    .removeButton {
      margin: 0px;
      background-color: transparent;
      color: #f44336;
      border: none;
      cursor: pointer;
      font-size: 1.2em;
      transition: color 0.3s ease;

      &:hover {
        color: darken(#f44336, 10%);
      }
    }
  }
}
