@use '../../../variables.scss' as *;

.logo_cloud {
    padding: $margin-medium;
    .logo_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        gap: $margin-medium;
        transition: transform 0.3s ease-in-out;
    }

    // Add animation on hover for the entire cloud
    &:hover {
        .logo_card {
            transform: translateY(-5px);
            transition: transform 0.3s ease-in-out;
        }
    }
}

.logo_card {
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $padding-small;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: $margin-medium;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05); // Slight scaling effect
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); // Elevated shadow
    }

    img {
        height: 100px;
        width: 100px;
        object-fit: contain;
        align-self: center;
        transition: transform 0.3s ease-in-out;

        // Add subtle animation to image
        &:hover {
            transform: rotate(10deg); // Slight tilt effect on hover
        }
    }

    // Text or title below the logo
    .logo_title {
        margin-top: $margin-small;
        font-size: 12px;
        color: lightblue;
        text-align: center;
    }
}
