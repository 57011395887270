@use '../../../variables.scss' as *;

.button_google_auth {
    display: inline-flex;  // Use inline-flex for better alignment control
    align-items: center;  // Center the items vertically
    background-color: white;
    border: none;
    border: 1px solid lightgrey;
    color: black;
    cursor: pointer;
    border-radius: 6px;  // Increased border radius for a softer look
    transition: background-color 0.3s, box-shadow 0.3s;  // Added box-shadow transition
    padding: 10px 20px;  // Adjusted padding for better spacing
    text-align: center;
    font-size: $font-size-base;
    margin-top: $margin-large;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  // Added a subtle shadow

    .google_icon {  // Added a class for the Google icon
        margin-right: 10px;  // Spacing between icon and text
    }

    &:hover {
        background-color: #f5f5f5;  // Slightly darker grey for hover effect
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  // Enhanced shadow on hover
    }

    &:focus {
        outline: none;  // Remove default focus outline
        box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.5);  // Add custom focus outline
    }
}
