.toggleButton {
  position: relative;
  padding: 12px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.98);
  }
}

.cartSummary {
  position: fixed;
  right: 0;
  top: 0;
  width: 350px;
  height: 100%;
  background: linear-gradient(135deg, #ffffff, #f4f4f4);
  box-shadow: -10px 0 30px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out, box-shadow 0.3s ease;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow-y: auto;
  z-index: 1000;

  &.open {
    transform: translateX(0);
    box-shadow: -10px 0 50px rgba(0, 0, 0, 0.3);
  }

  .content {
    padding: 25px;

    .itemsList {
      margin-bottom: 25px;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e0e0e0;

        .itemImage {
          width: 60px;
          height: 60px;
          border-radius: 10px;
          margin-right: 15px;
          object-fit: cover;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .itemDetails {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .itemName {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 5px;
            color: #333;
          }

          .itemQuantity,
          .itemPrice {
            font-size: 0.9rem;
            color: #666;
          }

          .itemPrice {
            font-weight: bold;
            color: #007bff;
          }
        }
      }
    }

    .priceDetails {
      font-size: 1rem;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .subtotal,
      .taxes,
      .shipping,
      .total {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        font-size: 1rem;
        color: #333;

        &.total {
          font-size: 1.3rem;
          font-weight: 700;
          color: #007bff;
        }
      }
    }

    .checkoutButton {
      width: 100%;
      padding: 15px;
      background-color: #28a745;
      color: #ffffff;
      border: none;
      border-radius: 8px;
      font-size: 1.1rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: #218838;
        transform: translateY(-3px);
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }
}
