



.recentActivity {
    background-color: #2d2f36;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    margin-bottom: 20px;
  
    h3 {
      margin: 0 0 15px 0;
      font-size: 1.5em;
      color: #4bc0c0;
      border-bottom: 2px solid #4bc0c0;
      padding-bottom: 5px;
    }
  
    .list {
      list-style: none;
      padding: 0;
      margin: 0;
  
      .item {
        padding: 10px 15px;
        margin-bottom: 10px;
        background-color: #3a3f47;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #4bc0c0;
          color: #ffffff;
        }
      }
  
      li:last-child {
        margin-bottom: 0;
      }
    }
}