@use '../../../variables.scss' as *;

#button_2 {
    background: none;
    color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
    &:hover {
        color: #0056b3;
      }
    
      &:focus {
        outline: none;
      }
    
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #007bff;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }
    
      &:hover:before {
        visibility: visible;
        transform: scaleX(1);
      }
    
      &:active {
        color: #004085;
      }
}