@import '../../../variables.scss';


.testimonialV2 {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .testimonialCarousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .testimonialCard {
    min-width: 100%;
    box-sizing: border-box;
    padding: $margin-medium;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;

    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 10px;
      object-fit: cover;
    }
  
    .name {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 5px;
      color: #333;
    }
  
    .role {
      font-size: 1rem;
      margin-bottom: 10px;
      color: #777;
    }
  
    .text {
      font-size: 1rem;
      color: #555;
    }
  }
  
  .carouselControls {
    position: absolute;
    top: 50%;
    width: calc(100% - $margin-small * 2);
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    padding: 0px $margin-small;
  }
  
  .controlButton {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  