
.input_wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    transition: border-color 0.3s ease;
    width: 100%;
    max-width: 300px;
}

.input_wrapper:hover,
.input_wrapper:focus-within {
    border-color: #007BFF;
}

.icon_container {
    display: flex;
    align-items: center;
    margin-right: 8px;
    color: #007BFF;
}

.input {
    border: none;
    outline: none;
    flex: 1;
    padding: 8px;
    font-size: 16px;
}

.input::placeholder {
    color: #ccc;
}