.card {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background: white;
  
    h2 {
      margin-bottom: 20px;
      font-size: 28px;
    }
  
    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
  
      button {
        background: none;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        transition: color 0.3s;
  
        &.active {
          color: #00796b;
        }
  
        &:hover {
          color: #004d40;
        }
      }
    }
  
    .tabContent {
      text-align: left;
      margin-bottom: 20px;
  
      p {
        margin-bottom: 10px;
      }
  
      .features,
      .specs {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 5px;
        }
      }
    }
  
    .price {
      font-size: 24px;
      color: #00796b;
    }
  }
  