@import '../../../../variables.scss';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: $secondary-color;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    outline: none;
  
    &:hover {
      background-color: darken($color: $secondary-color, $amount: 20);
      transform: translateY(-3px);
    }
  
    &.added {
      background-color: #28a745;
    }
  
    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 24px;
  
      .icon {
        transition: transform 0.3s;
        color: white
      }
  
      &:hover .icon {
        transform: scale(1.2);
      }
    }
  }
  
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 24px;
  
    .icon {
      transition: transform 0.3s;
    }
  
    &:hover .icon {
      transform: scale(1.2);
    }
  }
  