.historySection {
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 15px;
    animation: fadeIn 0.5s ease-out;
  
    .title {
      text-align: center;
      font-size: 2.5em;
      margin-bottom: 20px;
      color: #333;
    }
  
    .timeline {
      display: flex;
      overflow-x: auto;
      padding-bottom: 20px;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */
      
      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
  
      .timelineItem {
        min-width: 250px;
        background-color: #f9f9f9;
        border-radius: 10px;
        padding: 20px;
        margin: 0 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        flex-shrink: 0;
        animation: slideIn 0.5s ease-out;
  
        .date {
          font-size: 1em;
          color: #007bff;
          margin-bottom: 10px;
        }
  
        .content {
          .image {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            border-radius: 5px;
          }
  
          .event {
            font-size: 1.5em;
            color: #333;
            margin-bottom: 10px;
          }
  
          .description {
            color: #666;
            font-size: 1em;
          }
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  