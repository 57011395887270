@use '../../../variables.scss' as *;

.badges_list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 10px;
}

.badge_container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: #ffffff;
    transition: transform 250ms cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 250ms cubic-bezier(0.25, 0.1, 0.25, 1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 12px;
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
    }
}

.badge {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    display: inline-block;
    white-space: nowrap;
    padding: 6px 10px;
}

.delete_icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    font-size: 16px;
    color: #ff5c5c;
    cursor: pointer;
    margin-left: 8px;
    transition: color 250ms ease;

    &:hover {
        color: #ff0000;
    }
}
