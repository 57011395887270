@import '../../variables.scss';

$primary-color: #1a1a2e;
$secondary-color: #16213e;
$accent-color: #0f3460;
$hover-color: #e94560;
$text-color: #ffffff;
$text-muted: #a5a5a5;
$transition-speed: 0.3s;

.sidebar {
  max-width: 250px;
  background-color: $primary-color;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  transition: width $transition-speed ease;
  overflow-x: hidden;

  &.collapsed {
    width: 60px;
    
    .icon_expanded {
      margin-right: 0; // Remove margin when collapsed
    }
  }

  .header {
    padding: 20px;
    background-color: $secondary-color;
    text-align: center;
    
    h3 {
      margin: 0;
      color: $text-color;
      font-size: 1.2em;
      font-weight: 500;
    }
    .collapseBtn {
      margin-top: 10px;
      background: none;
      border: none;
      color: #ecf0f1;
      cursor: pointer;
      font-size: 1.2rem;
      text-transform: uppercase;
      padding: 0px;

      &:focus {
        outline: none;
      }
    }
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      position: relative;
      cursor: pointer;
      color: white;
      list-style: none;
      
      a {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        color: $text-color;
        text-decoration: none;
        font-size: 0.95em;
        transition: all $transition-speed ease;
        
        .icon {
          font-size: 1.2em;
        }

        .icon_expanded {
          margin-right: 10px; // Add margin when expanded
        }
        
        &:hover {
          background-color: $hover-color;
          border-radius: 6px;
        }
      }
      
      &.active > a,
      & > a.active {
        background-color: $hover-color;
        color: $text-color;
      }
      
      ul.collapse {
        background-color: $accent-color;
        padding-left: 5px;
        max-height: 0;
        overflow: hidden;
        transition: max-height $transition-speed ease;
        
        li {
          a {
            padding: 10px 20px;
            font-size: 0.85em;
          }
        }
      }
      
      &.expanded ul.collapse {
        max-height: 300px; // Adjust based on submenu content
      }
    }
  }
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  transition: margin-left $transition-speed ease;
}

.collapsed {
  .sidebar {
    width: 80px;
    
    .header h3 {
      display: none;
    }
    
    .menu a {
      padding: 15px 10px;
      
      i {
        margin-right: 0;
      }
      
      span {
        display: none;
      }
    }
    
    .menu ul.collapse {
      padding-left: 10px;
      
      li a {
        padding: 10px 10px;
      }
    }
  }
  
  .main-content {
    margin-left: 80px;
  }
}
