.couponContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-width: calc(100% - 20px * 2);
    width: 100%;
}

.couponCode, .discountAmount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
}

span {
    font-size: 0.9rem;
    color: #777;
}

.couponCode h3 {
    color: #007BFF; /* Blue for coupon code */
}

.discountAmount h3 {
    color: #28A745; /* Green for discount */
}
