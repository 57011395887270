@use '../../../variables.scss' as *;


.testimonial_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(173,216,230,1) 100%);
    padding: 50px;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(173,216,230,1) 100%);
}


.testimonial_card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    flex: 1 1 auto;
    & .author-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    img {
      height: 30px;
      width: 30px;
      border-radius: 100%;
    }
    h3 {
      margin: 0 0 10px;
      height: 20px;
      text-align: center;
      margin: 0px;
      padding: 0px;
      margin-left: $margin-small;
    }
  
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-top: $margin-medium;

    }
  
    &.small {
      width: 200px;
    }
  
    &.medium {
      width: 300px;
    }
  
    &.large {
      width: 400px;
    }
  }

