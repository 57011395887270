.orderItemCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.itemContent {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    width: 100%;
}

.imageWrapper {
    flex-shrink: 0;
}

.itemImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
}

.detailsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 2;
    min-width: 200px;
}

.itemName {
    font-size: 1.2rem;
    font-weight: bold;
}

.itemDescription {
    font-size: 0.9rem;
    color: #666;
}

.optionsWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-grow: 3;
    flex-wrap: wrap;
}

.dropdown {
    display: flex;
    flex-direction: column;
}

.itemActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    flex-grow: 1;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;

}

/* Modern floating labels */
.quantityWrapper, .unitPriceWrapper {
    position: relative;
    width: 150px;
}

.inputField {
    width: 100%;
    padding: 10px 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    outline: none;
    transition: border-color 0.3s ease;
}

.inputField:focus {
    border-color: #1890ff;
}

.label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 0.8rem;
    color: #888;
    pointer-events: none;
    transition: all 0.2s ease;
}

/* When input is focused or filled */
.inputField:focus + .label, 
.inputField:not(:placeholder-shown) + .label {
    top: -8px;
    left: 5px;
    font-size: 0.7rem;
    color: #1890ff;
    background: #fff;
    padding: 0 5px;
}

/* Styling for the total price */
.totalPrice {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 15px;
}

.removeItemButton {
    margin: 0px;
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ff7875;
    }
}

/* Responsive tweaks for smaller screens */
@media (max-width: 768px) {
    .itemContent, .itemActions {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .optionsWrapper {
        width: 100%;
    }

    .totalPrice {
        font-size: 1rem;
    }
}
