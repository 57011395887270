@import '../../../variables.scss';

.fileList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
  padding: 0;

  .fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    cursor: grab;

    &:hover {
      background-color: #f0f8ff;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    &.dragging {
      background-color: #007bff;
      color: #fff;
      box-shadow: 0 4px 12px rgba(0, 123, 255, 0.4);
      transform: scale(1.02);
    }

    .imagePreview {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .fileDetails {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fileName {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-right: 20px;
      }

      .deleteButton {
        background: none;
        border: none;
        color: #d32f2f;
        cursor: pointer;
        font-size: 14px;
        transition: color 0.2s ease;

        &:hover {
          color: #b71c1c;
        }
      }
    }
  }
}
