.productSelection {
    background-color: #f9fafb;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 100%;
    margin: auto;
    color: #333;
    margin-top: 20px;
  
    h4 {
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
        text-align: left;
      }
  }
  
  .productList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .productItem {
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    .productInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 8px;
    }
  
    .productName {
      font-size: 1.1rem;
      font-weight: 500;
      color: #333;
    }
  }
  
  .variantItem {
    display: flex;
    padding: 8px 0;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 2px solid #ddd;
    display: flex;
    justify-content: space-between;
    width: calc(100% - ( 40px + 8px) );
    .variantName {
      font-size: 1rem;
      color: #666;
    }
  }
  
  .addButton {
    background-color: #4a90e2;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #357ab8;
    }
  }
  