@import '../../../../variables.scss'; 

$transition-duration: 0.3s;

.paymentInfo {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: calc(100% - $margin-medium * 2);
    margin: 20px auto;
    animation: fadeIn $transition-duration ease-in-out;
    h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }
    
}

.button1 {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.button1:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
