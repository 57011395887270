@import '../../../variables.scss';

.wishlist {
    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  
    .wishlistItems {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  
    .wishlistItem {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
      padding: 1.5rem;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      }
    }
  
    .productImage {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 1.5rem;
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  
    .productInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
  
      h3 {
        font-size: 18px;
        margin-bottom: 0.5rem;
        color: #333;
      }
  
      .price {
        font-size: 14px;
        color: #27ae60;
        margin-bottom: 1rem;
      }
  
      .actions {
        display: flex;
        align-items: center;
        gap: 16px;
  
        .addToCartButton {
          background-color: #3498db;
          color: white;
          border: none;
          border-radius: 8px;
          padding: 10px 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 14px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #2980b9;
          }
  
          .cartIcon {
            margin-right: 10px;
          }
        }
  
        .removeButton {
          display: flex;
          align-items: center;
          background: none;
          border: none;
          color: #e74c3c;
          font-size: 14px;
          cursor: pointer;
          transition: color 0.3s ease;
          margin: 0px;
          text-align: center;
          padding: 10px 20px;
          &:hover {
            color: #c0392b;
          }
  
          .removeIcon {
            font-size: 20px;
            margin-right: 5px;
          }
        }
  
        .outOfStock {
          color: #e74c3c;
          font-size: 1rem;
        }
      }
    }
  
    .emptyMessage {
      text-align: center;
      font-size: 1.25rem;
      color: #888;
    }
  }
  