@import '../../../variables.scss';


.edit_promotion {
    width: 100%;
    .content {
        width: calc( 100% );
       
    }
    .form {
        background-color: white;
        box-shadow: $box-shadow1;
        padding: $margin-medium;
        border-radius: 8px;
        margin-top: $margin-medium;
    }
    .checkbox {
        margin-top: $margin-medium;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .row_calendar {
        display: flex;
        flex-direction: row;
        gap: 20px
    }
    h3 {
        margin-top: $margin-medium;
    }
}
