
  
  .modalContent {
    background-color: white;
    border-radius: 10px;
    max-width: calc(100% - 20px * 2);
    width: 100%;
    text-align: left;
    padding: 20px;
    h2 {
      margin-bottom: 20px;
    }
  
    .dropZone {
      border: 2px dashed #3498db;
      border-radius: 10px;
      padding: 40px;
      cursor: pointer;
      position: relative;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #f0f0f0;
      }
  
      p {
        font-size: 1.2rem;
        color: #666;
  
        span {
          color: #3498db;
          cursor: pointer;
        }
      }
  
      .fileInput {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  
    .previewContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
  
      .preview {
        margin: 10px;
        text-align: center;
  
        .previewImage {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
  
        p {
          font-size: 0.9rem;
          margin-top: 5px;
        }
      }
    }
  
    .error {
      color: #e74c3c;
      margin-top: 10px;
    }
  
    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
  
      .uploadButton {
        background-color: #3498db;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background-color: #2980b9;
        }
      }
  
      .cancelButton {
        background-color: #e74c3c;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background-color: #c0392b;
        }
      }
    }
  }
  