.container {
  margin: 30px 0px;
}

.blogSectionV1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  .title {
    font-size: 1.8rem; // Slightly larger than default
    font-weight: 600; // Bold
    text-transform: uppercase; // Optional: Uppercase for emphasis
    color: #333; // Darker color for better readability
    border-bottom: 2px solid #e0e0e0; // Optional: Adds a clean underline
    padding-bottom: 10px; // Space between title and content
    margin-bottom: 20px; // Space between the title and the next element
  }
  
  .blogCard {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  }
  
  .blogImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blogContent {
    padding: 20px;
  
    h3 {
      margin: 0;
      font-size: 1.5rem;
      color: #333;
    }
  
    p {
      margin: 10px 0;
      color: #777;
    }
  }
  