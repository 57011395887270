@import '../../../variables.scss';

.subscriber_list {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling */
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;

  
  /* Scrollable table on smaller screens */
  @media screen and (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border: 0;
    tbody {
      display: block;
      width: 100%;
    }
  }
  
  th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    /* Stack table cells vertically on smaller screens */
    @media screen and (max-width: 768px) {
      display: block;
      text-align: left;
      position: relative;
      border-bottom: 1px solid #ddd;
      width: calc(100% - 15px * 2);
      box-sizing: content-box;
      &:before {
        content: attr(data-label);
        position: absolute;
        left: 15px;
        width: calc(100% - 30px);
        white-space: nowrap;
        font-weight: bold;
      }
    }
  }

  th {
    background-color: $primary-color;
    color: white;
    font-weight: bold;
    
    /* Make header cells block elements on smaller screens */
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  tr {
    transition: background-color 0.3s;

    /* Make table rows block elements on smaller screens */
    @media screen and (max-width: 768px) {
      display: block;
      margin-bottom: 0px;
      border-bottom: 2px solid #ddd;
      width: 100%;
    }
  }

  tr:hover {
    background-color: #f1f1f1;
  }
}

.actions {
  display: flex;
  gap: 10px;

  /* Ensure actions are aligned properly on smaller screens */
  @media screen and (max-width: 768px) {
    justify-content: flex-end;
    padding: 10px 0;
  }
}

button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;

  &.edit {
    background-color: #28a745;
    color: white;
  }

  &.edit:hover {
    background-color: #218838;
  }

  &.delete {
    background-color: #dc3545;
    color: white;
  }

  &.delete:hover {
    background-color: #c82333;
  }
}
