.returnItemDetails {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    margin-top: 20px;

    h4 {
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
        text-align: left;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .item {
        background-color: #f8f9fa;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        .itemHeader {
            font-size: 1.1rem;
            font-weight: 600;
            color: #333;
            margin-bottom: 10px;
        }

        .itemBody {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            .inputGroup {
                display: flex;
                flex-direction: column;
                gap: 4px;
                flex: 1;
                min-width: 200px;

                label {
                    font-size: 0.9rem;
                    color: #666;
                }

                select {
                    padding: 8px;
                    font-size: 0.9rem;
                    border: 1px solid #ddd;
                    border-radius: 6px;
                    transition: border-color 0.3s ease;
                    background-color: #ffffff;

                    &:focus {
                        border-color: #4a90e2;
                        outline: none;
                    }
                }
            }
        }
    }
}
