.galleryContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 50vw;
  max-height: calc(50vw * 1.3);
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.image {
  width: 100%;
  height: 100%;
  min-width: 300px; /* Minimum width to ensure visibility */
  min-height: 200px; /* Minimum height to ensure visibility */
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  transition: transform 0.5s ease-in-out;
}

.image:hover {
  transform: scale(1.05);
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
  z-index: 1;
}

.prevButton:hover,
.nextButton:hover {
  background: rgba(0, 0, 0, 0.7);
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .galleryContainer {
    border-radius: 0; /* Optional: Remove border radius for a cleaner look on mobile */
  }

  .prevButton,
  .nextButton {
    padding: 8px;
    font-size: 20px;
  }

  .image {
    min-width: 200px; /* Adjusted minimum values for smaller screens */
    min-height: 150px;
  }

  .image:hover {
    transform: none; /* Disable zoom on hover for mobile for better UX */
  }
}
