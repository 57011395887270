.categoryV2 {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 40px 0px;
    max-width: 100%;
  }
  
  .categoryCard {
    flex: 0 0 auto;
    width: 200px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  
    .categoryImage {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
  
    .categoryContent {
      padding: 20px;
      text-align: center;
  
      h5 {
        margin: 0;
        font-size: 1rem;
        color: #333;
      }
    }
  }
  