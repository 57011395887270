@import '../../../variables.scss';

.newsletterV2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 10px;
    background: url('../../../../public/background-image/background-1.png') no-repeat center center/cover;
    color: white;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .newsletterContent {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  
    h2 {
      margin: 0 0 10px;
      font-size: 2rem;
      animation: textFadeIn 1s ease-in-out 0.5s forwards;
      opacity: 0;
    }
  
    p {
      margin: 0 0 20px;
      font-size: 1.2rem;
      animation: textFadeIn 1s ease-in-out 0.7s forwards;
      opacity: 0;
    }
  
    input {
      padding: 10px;
      border: none;
      border-radius: 5px;
      margin-right: 10px;
      font-size: 1rem;
    }
  
    button {
      margin-top: $margin-medium;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background: #ff6f61;
      color: white;
      cursor: pointer;
      font-size: 1rem;
      transition: background 0.3s, transform 0.3s;
      animation: textFadeIn 1s ease-in-out 0.9s forwards;
      opacity: 0;
  
      &:hover {
        background: #d74ef1;
        transform: scale(1.1);
      }
    }
  }
  
  @keyframes textFadeIn {
    to {
      opacity: 1;
    }
  }
  