@import '../../../variables.scss';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 4px 10px $box-shadow-color;
    transition: background-color $transition-duration, transform $transition-duration, box-shadow $transition-duration;
    color: #fff;
    background-color: $danger-color;
    margin: 0px;
    &:hover {
      transform: translateY(-2px); // Slight lift effect
      box-shadow: 0 6px 15px $accent-color; // Deeper shadow on hover
      background-color: darken($danger-color, 10%);
    }
  
    &:active {
      transform: translateY(0); // Button returns to original position
      box-shadow: 0 4px 8px $box-shadow-color; // Less shadow on click
    }
  }