.adminList {
  background-color: #f9fafa;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  .table {
    margin-top: 20px;
      width: 100%;
      border-collapse: collapse;
      background: white;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

      th, td {
          padding: 15px;
          text-align: left;
          font-size: 0.95rem;
      }

      th {
          background-color: #4a90e2;
          color: #ffffff;
          font-weight: 500;
      }

      tbody tr {
          transition: background-color 0.2s ease;
      }

      tbody tr:hover {
          background-color: #f2f8fd;
      }
  }

  .profile {
      display: flex;
      align-items: center;

      .avatar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-right: 10px;
      }
  }

  .status {
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 0.85rem;
      font-weight: 500;
      color: #ffffff;

      &.active {
          background-color: #2ed573;
      }

      &.inactive {
          background-color: #ff4757;
      }
  }

  .actions {
      display: flex;
      gap: 8px;

      .button {
          padding: 6px 12px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.85rem;
          font-weight: 500;
          transition: background-color 0.3s;

          &.edit {
              background-color: #4a90e2;
              color: #ffffff;

              &:hover {
                  background-color: #357ab8;
              }
          }

          &.delete {
              background-color: #ff4757;
              color: #ffffff;

              &:hover {
                  background-color: #e63946;
              }
          }
      }
  }
}
