@import '../../../../variables.scss';

.paymentMethod_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px; // Adjusted from $margin-medium for standalone use
    min-width: 500px;
    background-color: white;
    flex: 1 1 0; // Allows the form to grow and shrink
    max-width: 100%; // Ensures it doesn't exceed the container width
  }
  
  
  .toggle_switch {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .toggle_switch input {
    margin-right: 10px;
  }
  
  .button_container {
    margin-top: 20px;
  }
  