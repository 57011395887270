@import "../../../variables.scss";



.masonryCard {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.1);
    }

    .image {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
      transition: transform 0.3s ease; /* Smooth image scale transition */

      .masonryCard:hover & {
        transform: scale(1.02); /* Slightly scale the image for depth effect */
      }
    }

    .masonryContent {
      padding: 10px;

      .productName {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        margin: 5px 0;
      }

      .productPrice {
        font-size: 16px;
        color: #007bff;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }