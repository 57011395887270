$color-1: #0f3460;  // Main text
$color-2: #e94560;  // Accent color for important elements (like pending orders)
$color-3: #4bc0c0;  // Completed and confirmed status
$color-border: hsl(0, 0%, 88%);  // Borders and dividers
$color-4: rgba(153, 102, 255, 1);  // Shipped status


.discountCodeList {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    margin: 10px auto;
    overflow: hidden;

    table {
        width: 100%;
        border-collapse: collapse;

        th, td {
            padding: 15px;
            border-bottom: 1px solid $color-border;
            text-align: left;
        }

        th {
            background-color: $color-1;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.05em;
        }

        td {
            font-size: 14px;
            color: $color-1;

            &:first-child {
                font-weight: bold;
            }
        }

        tbody tr {
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease;

            &:hover {
                background-color: lighten($color-border, 30%);
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }

            &:nth-child(odd) {
                background-color: #fafafa;
            }
        }

        .activeStatus {
            display: flex;
            align-items: center;
            justify-content: center;

            .activeIcon {
                color: $color-3;  // Green for active
                font-size: 16px;
            }

            .inactiveIcon {
                color: $color-2;  // Red for inactive
                font-size: 16px;
            }
        }
    }
}
