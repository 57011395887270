// ReturnHistoryReview.module.scss
.returnHistory {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: #333;
    }
}

.returnTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    thead {
        background-color:  #f5f5f5;

        th {
            color: #666;
            padding: 10px;
            text-align: left;
            font-weight: bold;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: #f1f1f1;
            }
            
            td {
                padding: 10px;
                border-bottom: 1px solid #e0e0e0;
                vertical-align: top;

                ul {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
