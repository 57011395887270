@import '../../../../variables.scss';


.category_selection {
    margin-top: $margin-medium;
    margin-bottom: 20px;
    .category_list {
      padding: 0;
      list-style: none;
    }
  
    .category_item {
      margin: 10px 0;
    }
  
    .category_header {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  
    .toggle_button {
      background: none;
      border: none;
      font-size: 1.5em;
      margin-right: 10px;
      cursor: pointer;
    }
  
    .add_button {
      margin-left: auto;
      background-color: #007bff;
      border: none;
      color: white;
      padding: 5px 10px;
      cursor: pointer;
    }
    .remove_button {
      margin-left: auto;
      background-color: red;
      border: none;
      color: white;
      padding: 5px 10px;
      cursor: pointer;
    }
  
    .add_button:hover {
      background-color: #0056b3;
    }
  
    .subcategory_list {
      padding-left: 20px;
    }
  
    .chevron {
      margin-left: 10px;
      color: green;
    }
  }
  