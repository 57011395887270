@import "../../../variables.scss";

.productCard {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .productImage {
    width: 100%;
    height: 200px;
    object-fit:contain;
  }

  .productInfo {
    padding: 20px;
    text-align: center;

    .productName {
      font-size: 1.2em;
      font-weight: bold;
      color: $primary-color;
      margin: 10px 0;
    }

    .productPrice {
      font-size: 1.1em;
      color: $accent-color;
    }

    .productCategory {
      font-size: 0.9em;
      color: $primary-color;
      margin-top: 5px;
    }

    .productButton {
      margin-top: 15px;
      padding: 10px 20px;
      background: $primary-color;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: darken($primary-color, 10%);
      }
    }
  }
}
