// src/components/CancellationDetails/CancellationDetails.module.scss
@import '../../../variables.scss';

.cancellationDetails {
    padding: 0px $margin-medium;
    width: calc(100% - $margin-medium * 2);
    display: flex;
    flex-direction: column;

    h1, h2 {
        color: $primary-color;
    }

    .details {
        margin-bottom: 20px;

        div {
            margin: 5px 0;
            strong {
                color: $secondary-color;
            }
        }
    }

    .refuseSection {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    .emailSection {
        textarea {
            width: 100%;
            height: 100px;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid $border-color;
            border-radius: 5px;
        }
    }

    
    .row {
        display: flex;
        flex-direction: row; /* Default to row */
        justify-content: space-between;
        flex: 1;
        gap: 20px;
    
        @media screen and (max-width: 768px) { /* Adjust the max-width as needed */
            flex-direction: column; /* Switch to column on small screens */
            gap: 0px;
        }
    }
}
