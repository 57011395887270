@import '../../../variables.scss';

.pageList {
  margin: 0 auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      background-color: #fff;
      padding: $margin-small $margin-medium;
      margin: $margin-small 0;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: transform 0.3s, box-shadow 0.3s;
      min-height: 50px;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      }

      span {
        font-size: 1.1em;
        font-weight: 500;
        color: #333;
        transition: color 0.3s;

        &:hover {
          color: #007bff;
        }
      }
    }
  }
}
