@import '../../../../variables.scss';

.orderItemsContainer {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    max-width: calc(100% - 20px * 2);
    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
    }
}

.button1 {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.button1:hover {
    background-color: #0056b3;
}
