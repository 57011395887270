@import '../variables.scss';

.container {
    padding: 40px 20px;
    background-color: #f4f5f7;
}

.componentWrapper {
    margin-bottom: 40px;

    .sectionTitle {
        font-size: 1.5rem;
        color: #333;
        margin-bottom: 20px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
    }
}

.header {
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: 2.5rem;
    color: #343a40;
    margin-bottom: 20px;
    animation: fadeInDown 1s ease-out;
  }

  p {
    font-size: 1.2rem;
    color: #6c757d;
    max-width: 800px;
    margin: 0 auto;
    animation: fadeInUp 1s ease-out;
  }
};

.card {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;

    h5 {
        font-size: 1.2rem;
        color: #007bff;
        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        background-color: #007bff;

        h5 {
            color: #fff;
        }
    }
}

.icon {
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(51%) sepia(18%) saturate(3396%) hue-rotate(178deg) brightness(100%) contrast(98%);
}


.container_element {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $margin-medium;
  padding: $padding-large;
  border-radius: 16px;
  margin: $margin-medium auto;
  max-width: 1200px;
}

.element {
  max-width: calc(100% - $margin-medium * 2) ;
  flex: 1 1 calc(50% - $margin-medium);
  background-color: white;
  border-radius: 12px;
  padding: $padding-medium;
  margin-bottom: $margin-medium;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  h3 {
    font-size: 1.5rem;
    color: $primary-color;
    margin-bottom: $margin-small;
  }
}

@media screen and (max-width: 768px) {
  .container_element {
    flex-direction: column;
  }

  .element {
    flex: 1 1 100%;
    margin-bottom: $margin-small;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}