@import '../../../variables.scss';

.socialMediaContainerV3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
  }
}

.socialMediaLinkV3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #555;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.2);
    background-color: #ff6f61;
  }
}

.socialMediaIconV3 {
  width: 32px;
  height: 32px;
  fill: white;
}
