.analyticsPage {
    padding: 20px;
    background-color: #f4f6f8;
    min-height: 100vh;
}

.title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.bigNumber {
    font-size: 2rem;
    font-weight: bold;
    color: #36a2eb;
}

.list {
    list-style: none;
    padding: 0;
}

.list li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
}

.chartContainer {
    height: 250px;
    width: 100%;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }
}
