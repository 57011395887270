@import '../../../../variables.scss';


.adminOperations {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
    margin: 20px auto;
    transition: box-shadow 0.3s ease-in-out;
    h2 {
        margin-bottom: 10px;
        color: #0d47a1;
    }
    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}

.field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

}

.actions {
    display: flex;
    justify-content: flex-end;

    .saveButton {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }

        &:focus {
            outline: none;
        }
    }
}
