@use '../../../variables.scss' as *;

.sectionHeader1 {
    padding: $margin-medium;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .label {
        background-color: $secondary-color;
        padding: 10px;
        border-radius: 16px;
        color: white;
        font-size: $font-size-base;
        text-align: center;
        white-space: nowrap;
        display: inline-block;


    }
    & .title {
        text-align: center;
        font-size: $font-size-title;
        margin-top: $margin-medium;
    }
    & .description {
        margin-top: $margin-small;
        text-align: center;
        font-size: $font-size-base;
        color: grey;
    }
}