$border-color: #ccc;
$focus-border-color: #007BFF;
$input-height: 40px;
$input-padding: 8px;
$label-color: #ccc;
$animation-duration: 0.3s;
$font-size-base: 14px;

.input1 {
    position: relative;
    width: 100%;
    height: 60px;
    input {
        margin-top: 20px;
        width: calc(100% - $input-padding);
        height: $input-height;
        border: 1px solid $border-color;
        border-radius: 4px;
        outline: none;
        font-size: $font-size-base;
        transition: border-color $animation-duration, box-shadow $animation-duration;
        box-shadow: 0 0 0 0 transparent;
        height: $input-height;
        text-indent: 8px;
        padding: 0px;
        &:focus {
            border-color: $focus-border-color;
            box-shadow: 0 0 8px rgba($focus-border-color, 0.3);
        }

        &:focus + label,
        &:not(:placeholder-shown) + label {
            top: 0px;
            left: 0;
            color: $focus-border-color;
        }
    }

    label {
        position: absolute;
        top: calc(50% + 10px);
        left: $input-padding;
        transform: translateY(-50%);
        font-size: $font-size-base;
        color: $label-color;
        pointer-events: none;
        transition: all $animation-duration;
    }
}
