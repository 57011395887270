@use '../../../variables.scss' as *;

.pagination_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;

  .pagination_list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pagination_item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    font-size: 13px;
    border: 1px solid lightgrey;
    border-left: none;
    background: #fff;
    transition: background 0.3s, box-shadow 0.3s;

    &:nth-child(1) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-left: 1px solid lightgrey;
    }

    &:nth-last-child(1) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  .text {
    color: grey;
    margin-right: $margin-large;
  }
}