@import '../../../../variables.scss';

.refuseCancellationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    padding: $margin-medium;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - $margin-medium * 2);
    margin: 1rem auto;
    h2 {
        color: #0f3460;
        margin-bottom: 20px;
    }
}
.refuseSelect, .refuseTextarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.refuseSelect:focus, .refuseTextarea:focus {
    border-color: #007BFF;
    outline: none;
}

.refuseTextarea {
    height: 100px;
    resize: none;
    margin-top: 16px;
    width: calc(100% - 10px * 2);
}

.buttonCancel {
    width: 100%;
    background-color: #dc3545;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.buttonCancel:hover {
    background-color: #c82333;
}
