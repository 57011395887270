.customerSupport {
  padding: 2rem;
  background-color:  white;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-out;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--primary-color, #222);
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    color: var(--text-color, #555);
    margin-bottom: 2rem;
  }

  .contactMethods {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    margin: 2rem 0;

    .method {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        font-size: 2rem;
        color: var(--accent-color, #0078d7);
      }

      a {
        font-size: 1rem;
        color: var(--accent-color, #0078d7);
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: var(--accent-hover-color, #005a9e);
        }
      }
    }
  }

  .workingHours {
    font-size: 0.875rem;
    color: var(--subtext-color, #888);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
