.eligibleItemsTable {
    width: 100%;
    max-width: calc(100% - 20px * 2);
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    overflow-x: auto;
  
    h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 20px;
      text-align: left;
      font-weight: 600;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      font-family: 'Arial', sans-serif;
      table-layout: auto;
  
      thead {
        background-color: #f5f5f5;
        color: #333;
  
        th {
          padding: 12px 20px;
          text-align: left;
          font-weight: 600;
          font-size: 0.9rem;
          color: #666;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
  
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f9f9f9;
          }
  
          &:hover {
            background-color: #f0f0f0;
            transition: background-color 0.3s ease;
          }
        }
  
        td {
          padding: 16px 20px;
          font-size: 0.9rem;
          color: #555;
          text-align: left;
          vertical-align: middle;
        }
  
        td:last-child {
          text-align: center;
        }
      }
  
      /* Add a light border around the table */
      th, td {
        border-bottom: 1px solid #eee;
      }
    }
  
    /* Checkbox styles */
    input[type="checkbox"] {
      position: relative;
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      
      &:checked {
        border-color: #4caf50;
        background-color: #4caf50;
      }
  
      &:checked::before {
        content: "✔";
        position: absolute;
        top: -2px;
        left: 3px;
        color: white;
        font-size: 14px;
        font-weight: bold;
      }
  
      &:hover {
        border-color: #4caf50;
      }
    }
  
    /* Responsive styling for mobile devices */
    @media (max-width: 768px) {
      table, thead, tbody, th, td, tr {
        display: block;
      }
  
      tr {
        margin-bottom: 15px;
      }
  
      th {
        display: none;
      }
  
      td {
        display: flex;
        justify-content: space-between;
        padding: 12px 15px;
        border-bottom: 1px solid #eee;
        position: relative;
      }
  
      td::before {
        content: attr(data-label);
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.75rem;
        color: #999;
      }
    }
  }
  