@import '../../../../../variables.scss';

.section {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    margin-bottom: 16px;
    margin-top: $margin-medium;
    &:hover {
        transform: translateY(-5px);
    }
}