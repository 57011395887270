// TabbedDescription.module.scss

.tabbedContainer {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  
    .tabHeader {
      display: flex;
      border-bottom: 1px solid #ddd;
    }
  
    .tab {
      flex: 1;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      transition: color 0.3s ease, background-color 0.3s ease;
  
      &:hover {
        background-color: #f0f0f0;
      }
    }
  
    .tabContent {
      padding: 20px;
      background-color: #fff;
  
      p {
        margin: 0;
        line-height: 1.5;
        white-space: pre-line; // Allows new lines in attributes
      }
    }
  }
  