@use '../../../variables.scss' as *;

#button1 {
    background: none;
    background-color: #002147;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: $font-size-base;
    &:hover {
        background-color: #335580;
    }
    .content {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
    }
    .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-right: 5px;


    }
}
