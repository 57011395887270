@import '../../../../../variables.scss';

.placeholdersEditor {
    margin-top: 20px;

    .title {
        font-size: 18px;
        color: #333;
        margin-bottom: 10px;
    }

    .placeholderRow {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;

        .input {
            flex: 1;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
            color: #333;
            transition: border-color 0.3s ease, box-shadow 0.3s ease;

            &:focus {
                border-color: #007bff;
                box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
            }
        }

        .deleteButton {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            cursor: pointer;
            color: #e74c3c;

            &:hover {
                color: #c0392b;
            }
        }
    }

    .addButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        color: #007bff;
        border: 1px solid #007bff;
        border-radius: 4px;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: #007bff;
            color: #fff;
        }

        svg {
            margin-right: 5px;
        }
    }
}
