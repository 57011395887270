@import '../../../../variables.scss';

$border-color: #ccc;  // Light grey border color
$focus-border-color: #666;  // Darker grey for focus state
$input-padding: 8px;
$animation-duration: 0.3s;
$font-size-base: 1rem;
$input-height: 40px;
$label-color: #333;  // Dark grey for label color
$input-background: #f7f7f7;  // Light grey background for inputs

.input4 {
    position: relative;
    min-width: 200px;
    z-index: 1 !important;

    .label {
        text-align: left;
        font-size: $font-size-base;
        font-weight: 600;  // Updated to medium weight
        color: $label-color;
        pointer-events: none;
        transition: color $animation-duration;
        margin-bottom: $input-padding;
    }

    .input {
        width: calc(100%);  // Adjusted to account for both side paddings
        border: 1px solid $border-color;
        border-radius: 4px;
        outline: none;
        height: $input-height;
        font-size: $font-size-base;
        background-color: $input-background;  // Light grey background
        transition: border-color $animation-duration, box-shadow $animation-duration, background-color $animation-duration;
        padding: 0 $input-padding;
        margin: 0;

        &:focus {
            border-color: $focus-border-color;
            box-shadow: 0 0 5px rgba($focus-border-color, 0.25);
            background-color: #fff;  // White background on focus
        }
    }

    input:focus + .label {
        color: $focus-border-color;
    }
}
