@import '../../../variables.scss';


.galleryContainer {
    width: 100%;
    max-width: 600px;
    margin: auto;
    text-align: center;
  
    .mainImage {
      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  
    .thumbnails {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  
    .thumbnail {
      margin: 0 5px;
      cursor: pointer;
      border: 2px solid transparent;
      border-radius: 5px;
      transition: border-color 0.3s;
  
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px;
      }
  
      &.active,
      &:hover {
        border-color: $secondary-color;
      }
    }
  
    .lightbox {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      overflow-y: auto;
  
      .lightboxContent {
        max-width: 80%;
        max-height: 80%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  