.qaList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 800px;
    margin: auto;
    animation: fadeIn 0.5s ease-out;
  }
  
  .qaItem {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: max-height 0.3s ease, box-shadow 0.3s ease;
  
    &.expanded {
      max-height: 300px;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }
  
    .cardHeader {
      padding: 15px 20px;
      font-size: 1.2em;
      color: #007bff;
      cursor: pointer;
      background-color: #f7f7f7;
      border-bottom: 1px solid #ddd;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #e7e7e7;
      }
    }
  
    .cardBody {
      padding: 20px;
      display: none;
  
      p {
        margin: 0;
        color: #555;
      }
    }
  
    &.expanded .cardBody {
      display: block;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  