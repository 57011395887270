.avatar_group {
    display: flex;
    align-items: center;
    .avatar {
        border-radius: 50%;
        border: 2px solid #fff;
        margin-left: -10px;
        object-fit: cover;
    }
    &_more {
        background-color: #ccc;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
    }
}
  
 
  
  