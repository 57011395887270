@import '../../../variables.scss';

.loginForm {
    min-width: 500px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.6s ease-in-out;

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-size: 1.8rem;
        color: $primary-color;
        margin-bottom: 20px;
        text-align: center;
    }

    .registerText {
        font-size: 0.9rem;
        margin-bottom: 20px;
        text-align: center;

        .registerButton {
            background: none;
            border: none;
            color: $primary-color;
            cursor: pointer;
            text-decoration: underline;
            transition: color 0.3s ease;

            &:hover {
                color: darken($primary-color, 10%);
            }
        }
    }

    .inputGroup {
        width: 100%;
    }

    .buttonContainer {
        width: 100%;
        margin-top: 20px;

        button {
            width: 100%;
        }
    }

    .forgotPassword {
        margin-top: 15px;
        font-size: 0.9rem;
        color: $primary-color;
        cursor: pointer;
        text-align: center;
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
            color: darken($primary-color, 10%);
        }
    }

    .separationRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;

        p {
            margin: 0 10px;
            font-size: 0.9rem;
        }

        .separationLine {
            flex-grow: 1;
            height: 1px;
            background-color: #e0e0e0;
        }
    }

    .error {
        color: $danger-color;
        font-size: 0.9rem;
        margin-bottom: 10px;
        text-align: center;
        animation: shake 0.3s ease;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    20%, 60% {
        transform: translateX(-10px);
    }
    40%, 80% {
        transform: translateX(10px);
    }
}
