.companyOverview {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
    .image {
      max-width: 150px;
      border-radius: 10px;
      margin-right: 20px;
    }
  
    .content {
      .title {
        font-size: 2em;
        margin-bottom: 10px;
        color: #333;
      }
  
      .description {
        font-size: 1em;
        color: #666;
        margin-bottom: 10px;
      }
  
      .mission {
        font-style: italic;
        color: #007bff;
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  