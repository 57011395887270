@use '../../../variables.scss' as *;

.featureSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: $padding-medium;
  margin: 0px auto;
  border-radius: 12px;
  max-width: calc(100% - $padding-medium * 2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto;
  .textContainer {
    margin-bottom: $margin-medium;

    h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: $margin-small;
    }

    p {
      font-size: 1rem;
      color: #555;
    }
  }

  .featuresGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $margin-medium;
    width: 100%;
  }
}

.featureCard {
  background-color: #fff;
  border-radius: 12px;
  padding: $padding-medium;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
    z-index: 0;
    transition: height 0.3s ease;
  }

  &:hover:before {
    height: 100%;
    opacity: 0.1;
  }

  .featureIcon {
    font-size: 40px;
    color: #6a11cb;
    margin-bottom: $margin-small;
  }

  .featureTitle {
    font-size: 1.5rem;
    margin-bottom: $margin-small;
    color: #333;
  }

  .featureDescription {
    font-size: 1rem;
    color: #777;
  }
}
