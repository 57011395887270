@import '../../../variables.scss';


.product_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
    user-select: none;
    margin: 10px;
    position: relative;
    opacity: 0.5; // Default opacity for unselected items

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &.selected {
        border-color: #3498db;
        opacity: 1; // Full opacity for selected items
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .product_info {
        text-align: center;

        h3 {
            margin: 0;
            font-size: 16px;
            color: #333;
        }

        p {
            margin: 5px 0;
            font-size: 14px;
            color: #666;
        }
    }

    .radio_button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        color: #3498db;
    }
}
