


#buttonGroup1 {
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        margin: 0px;
        cursor: pointer;
        background-color: #007bff;
        color: white;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #0056b3;
        }
      
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
        }
        &:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:nth-last-child(1) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}
  
  