@import "../../../variables.scss";

.carouselCard {
    /* Adjust for 3 cards per row with gaps */
   width: calc(33.33% - 15px); /* Ensure max width matches flex base size */
   margin-bottom: 15px; /* Space between rows */
   background: #fff;
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   overflow: hidden;
   cursor: pointer;
   transition: transform 0.3s ease, box-shadow 0.3s ease;

   &:hover {
     transform: scale(1.05);
     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
   }

   .image {
     width: 100%;
     height: 150px;
     object-fit: cover;
   }

   .carouselContent {
     padding: 10px;
     text-align: center;

     .productName {
       font-size: 16px;
       font-weight: bold;
       color: #333;
     }

     .productPrice {
       font-size: 16px;
       color: #007bff;
     }
   }
 }

 @keyframes slideIn {
   from {
     transform: translateY(10px);
     opacity: 0;
   }
   to {
     transform: translateY(0);
     opacity: 1;
   }
 }