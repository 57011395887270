.availabilityFilterContainer {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease;
  
    h3 {
      margin-bottom: 10px;
      color: #333;
    }
  
    .availabilityList {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .availabilityItem {
      display: flex;
      align-items: center;
  
      input[type="checkbox"] {
        margin-right: 8px;
        cursor: pointer;
        transform: scale(1.2);
        transition: transform 0.2s ease;
      }
  
      label {
        cursor: pointer;
        font-size: 1em;
        color: #555;
        transition: color 0.3s ease;
  
        &:hover {
          color: #007bff;
        }
      }
    }
  }
  