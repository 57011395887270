@import '../../variables.scss';

.blog_post_page {
    margin: 0 auto;
    border-radius: 8px;
  }
  
  .analytics {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: $margin-medium;
  
    .analytics_item {
      flex: 1;
      padding: 20px;
      margin: 0 10px;
      background-color: #f9f9f9;
      border-radius: 8px;
      text-align: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
      h3 {
        margin: 0 0 10px 0;
      }
  
      p {
        font-size: 24px;
        margin: 0;
      }
    }
  }
  
  .blog_post_list {
    width: calc(100% - $margin-small * 2);
    margin-left: $margin-small;
    .blog_post_item {
      margin-bottom: 20px;
      padding: 10px;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  
      h3 {
        margin-top: 0;
      }
  
      .actions {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  

  .blog_post_list_add_button {
    display: flex;
    flex-direction:row-reverse;
    margin: $margin-small;
    margin-bottom: $margin-medium;

  }