.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .gridItem {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: box-shadow 0.3s, transform 0.3s;
  
    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  
    .icon {
      font-size: 36px;
      color: #00796b;
      margin-bottom: 10px;
    }
  
    h4 {
      margin: 10px 0;
      font-size: 18px;
      color: #333;
    }
  
    p {
      font-size: 14px;
      color: #666;
    }
  }
  