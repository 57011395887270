.stepIndicator {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.6s ease-out;
    color: #fff;
  
    .step {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s;
  
      &.active {
        .number {
          background-color: #00bfff;
          color: white;
        }
        transform: scale(1.1);
      }
  
      .number {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        font-size: 1.2em;
      }
  
      .label {
        font-size: 1em;
        color: #ddd;
      }
  
      .line {
        flex-grow: 1;
        height: 2px;
        background-color: #ddd;
        margin-left: 10px;
        margin-right: 10px;
  
        .progress {
          height: 100%;
          background-color: #00bfff;
          transition: width 0.3s;
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  