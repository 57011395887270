/* Main container for the message list */
.messageList {
    max-height: 400px;
    overflow-y: auto;
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  /* Individual message item */
  .messageItem {
    padding: 20px;
    margin-bottom: 1rem;
    border-radius: 6px;
    font-size: 0.95rem;
    line-height: 1.4;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
    max-width: calc(100% - 20px * 2);
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effect */
    cursor: pointer;
  }
  /* Hover effect for desktop only */
@media (min-width: 768px) {
    .messageItem:hover {
      transform: translateY(-3px); /* Slight upward movement */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* More prominent shadow */
    }
  }
  /* Message from admin styling */
  .adminMessage {
    background-color: #e3f2fd; /* Light blue */
    align-self: flex-start;
  }
  
  /* Message from customer styling */
  .customerMessage {
    background-color: #e8f5e9; /* Light green */
    align-self: flex-end;
  }
  
  /* Header section for each message */
  .messageHeader {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  /* Sender text */
  .sender {
    font-weight: bold;
    color: #333;
  }
  
  /* Timestamp styling */
  .timestamp {
    font-style: italic;
    color: #888;
  }
  
  /* Message content styling */
  .messageContent {
    color: #333;
  }
  