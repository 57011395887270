@import '../../../variables.scss';

.product_list {
    display: flex;
    flex-direction: column;
    margin-top: $margin-small;

    .title {
        font-size: 18px;
        color: #333;
        margin-bottom: 10px;
    }
    .search_bar {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 14px;
    }

    .product_list_container {
        display: flex;
        overflow-x: auto;
    }
}
