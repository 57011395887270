@import '../../../variables.scss';

.categoryForm {
    width: calc(100%);
    .content {
      margin-top: $margin-medium;
      padding: $margin-medium;
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 8px;
    }
    h2 {
      margin-bottom: 10px;
      color: #007bff;
    }
  }