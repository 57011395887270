.notifications {
    background-color: #2d2f36;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff !important;
    margin-bottom: 20px;
  
    .title  {
      margin: 0 0 15px 0;
      font-size: 1.5em;
      color: #4bc0c0 !important;
      border-bottom: 2px solid #4bc0c0;
      padding-bottom: 5px;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
  
      li {
        padding: 10px 15px;
        margin-bottom: 10px;
        background-color: #3a3f47;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease;
        color: white !important;
  
        &.System {
          border-left: 5px solid #ff4757;
        }
  
        &.User {
          border-left: 5px solid #1e90ff;
        }
  
        &.Message {
            border-left: 5px solid #1e90ff;
        }

        &.Order {
          border-left: 5px solid #2ed573;
        }
  
        &.Product {
          border-left: 5px solid #ffa502;
        }
  
        &.Promotion {
          border-left: 5px solid #eccc68;
        }

        &.Refund {
            border-left: 5px solid #ff6b81; // Pinkish Red
        }
    
        &.ReturnRequest {
            border-left: 5px solid #70a1ff; // Light Blue
        }
    
        &.Alert {
            border-left: 5px solid #ff6348; // Coral
        }

        &:hover {
          background-color: #4bc0c0;
          color: #ffffff;
        }
      }
  
      li:last-child {
        margin-bottom: 0;
      }
    }
  
    .message {
      flex-grow: 1;
      margin-left: 10px;
    }
  
    .time {
      font-size: 0.85em;
      color: #a5a5a5;
      margin-right: 10px;
    }
  }