@use '../../../variables.scss' as *;

.forget-password-form {
    width: calc(100% - $margin-medium * 2);
    padding: $margin-medium;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    font-family: 'Arial', sans-serif;
    h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #333;
    }
    p {
        color: grey;
    }
    .error {
        color: red;
        text-align: center;
        margin-bottom: 10px;
        margin: $margin-small;
    }

    .input-group {
        margin-top: $margin-large * 2;
    }

    .button-container {
        background: none;
        margin-top: $margin-large;
    }
    .counter {
        margin-top: $margin-small;
        font-size: $font-size-small;
        color: grey;
    }
    
}