// GradientBreadcrumbs.module.scss
.breadcrumbContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    background: linear-gradient(90deg, #ff6a00, #ee0979);
    border-radius: 5px;
    animation: bounceIn 0.5s ease;
  
    @keyframes bounceIn {
      0%,
      20%,
      40%,
      60%,
      80%,
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }
      60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
      }
    }
  }
  
  .breadcrumbItem {
    a {
      color: #fff !important;
      text-decoration: none;
      padding: 0 8px;
      transition: opacity 0.3s ease;
  
      &:hover {
        opacity: 0.8;
      }
    }
  
    .separator {
      margin: 0 8px;
      color: #fff;
    }
  }
  