@import '../../../../../variables.scss';

.editorContainer {
}

.title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.subTitle {
    color: grey;
    margin-bottom: $margin-small;
}

.newLinkSection {
    margin-bottom: 20px;
}

.newLinkInput {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    
    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
    }
}

.addButton {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}

.linksList {
    margin-top: 20px;
}

.linkItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.deleteButton {
    background-color: #e74c3c;
    color: white;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c0392b;
    }
}
