@import "../../../variables.scss";



.card {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-15px);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    }
  }
  
  .imageContainer {
    width: 100%;
    padding-top: 60%;
    position: relative;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    padding: 30px;
    text-align: left;
  }
  
  .name {
    font-size: 1.4rem;
    margin: 12px 0;
    color: #000;
  }
  
  .description {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 12px;
  }
  
  .priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-size: 1.3rem;
    color: #004d40;
    font-weight: bold;
  }
  
  .discountPrice {
    font-size: 1.1rem;
    color: #b71c1c;
    text-decoration: line-through;
  }
  