.chatRoom {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 500px;
    width: 100%;
    background-color: #f9fafb;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  /* Message list styling */
  .messageList {
    flex: 1;
    padding: 1rem;
    overflow-y: auto;
    background-color: #ffffff;
  }
  
  .messageItem {
    display: block;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    font-size: 0.95rem;
    line-height: 1.4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
    max-width: 70%;
    position: relative;
    color: #333;
  }
  
  /* Admin message (left-aligned) */
  .adminMessage {
    background-color: #e3f2fd; /* Light blue */
    margin-right: auto;
    border-top-left-radius: 0;
    text-align: left;
  }
  
  /* Customer message (right-aligned) */
  .customerMessage {
    background-color: #e8f5e9; /* Light green */
    margin-left: auto;
    border-top-right-radius: 0;
    text-align: right;
  }
  
  /* Header section for each message */
  .messageHeader {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .sender {
    font-weight: bold;
    color: #333;
  }
  
  .timestamp {
    font-style: italic;
    color: #888;
  }
  
  .messageContent {
    color: #333;
  }
  
  /* Reply box styling */
  .replyBox {
    display: flex;
    padding: 0.8rem;
    border-top: 1px solid #e0e0e0;
    background-color: #f4f6f8;
  }
  
  .replyInput {
    flex: 1;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
    outline: none;
    transition: border-color 0.2s ease;
  }
  
  .replyInput:focus {
    border-color: #4a90e2;
  }
  
  .sendButton {
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .sendButton:hover {
    background-color: #357ab8;
  }
  