@import '../../../../variables.scss';

.attributesWrapper {
  h2 {
    margin-bottom: 20px;
    color: #0d47a1;
  }
}

.addAttribute,
.addValue {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  input,
  .button {
    height: 40px; 
    border-radius: 4px;
  }

  input {
    flex: 1;
    border: 1px solid #ccc;
    text-indent: 10px;
    padding: 0px;
    &::placeholder {
      color: #ccc;
    }
  }

  .addAttributeButton {
    background-color: #4A90E2;
    color: #FFFFFF;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  
    &:hover {
      background-color: #357ABD;
      transform: translateY(-2px); // Adds a slight lift effect on hover
    }
  
    &:active {
      background-color: #2C6BA1; // Slightly darker for the active state
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.3); // Subtle focus outline
    }
  }
}

.attributes {
  margin-bottom: 20px;

  .attributeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      text-transform: capitalize;
      margin-bottom: 10px;
      margin-top: 15px;
      color: grey;
    }

    
  }
  .delete_button {
    height: 40px;
    padding: 4px 8px;
    background-color: #d32f2f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0px;

    &:hover {
      background-color: #9a0007;
    }
  }
  .update_button {
    height: 40px;
    padding: 4px 8px;
    background-color: #1976d2; /* Primary blue color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0px;

    &:hover {
      background-color: #115293; /* Darker shade of blue for hover state */
    }
  }
}
