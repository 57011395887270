@import "../../../variables.scss";



.card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    }
  }
  
  .imageContainer {
    width: 100%;
    padding-top: 75%;
    position: relative;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info {
    padding: 20px;
    text-align: center;
  }
  
  .name {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #333;
  }
  
  .description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  .priceContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .price {
    font-size: 1.2rem;
    color: #00796b;
    font-weight: bold;
  }
  
  .discountPrice {
    font-size: 1rem;
    color: #e94560;
    text-decoration: line-through;
  }
  