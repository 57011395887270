.qaList {
    width: 100%;
    max-width: 800px;
    margin: auto;
    animation: fadeIn 0.5s ease-out;
  }
  
  .qaItem {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #f9f9f9;
    }
  
    .question {
      display: flex;
      justify-content: space-between;
      font-size: 1.2em;
      color: #333;
  
      .icon {
        font-size: 1.2em;
      }
    }
  
    .answer {
      padding: 10px 0;
      font-size: 1em;
      color: #555;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  