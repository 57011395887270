@import '../../../variables.scss';



.productPictureComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $margin-medium;
    .searchBar {
      width: calc(100% - 10px * 2 - 4px);
      padding: 10px;
      margin-bottom: 20px;
      border: 2px solid #ddd;
      border-radius: 5px;
      font-size: 16px;
      &:hover {
        border-color: $focus-border-color;
        box-shadow: 0 0 5px $focus-border-color;
      }
      &:focus {
        border-color: $focus-border-color;
        box-shadow: 0 0 5px $focus-border-color;
      }  
    }
  
    .productList {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 20px;
      width: calc(100% - 10px * 2);
      padding: 10px;
      padding-top: 0px;
      padding-bottom: 40px;
      padding-left: 0px;
    }
  
    .productCard {
      position: relative;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px;
      min-width: 200px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
  
      &:hover,
      &:focus {
        border-color: $focus-border-color;
        box-shadow: 0 0 5px $focus-border-color;
        outline: none; // Ensure no outline is shown
      }
  
      .productImage {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
  
      .productInfo {
        padding: 10px;
        text-align: center;
  
        h3 {
          margin: 10px 0;
          font-size: 18px;
          color: #333;
        }
  
        p {
          font-size: 14px;
          color: #666;
        }
      }
  
      .selectedIcon,
      .unselectedIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        color: $secondary-color;
      }
  
      .unselectedIcon {
        color: #ccc;
      }
    }
  }
  