.orderSummary {
    background-color: #f9fafb;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    color: #333;

    h4 {
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
        text-align: left;
      }

    .itemList {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        margin-bottom: 12px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

        .itemInfo {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .itemName {
                font-size: 1.1rem;
                font-weight: 500;
                color: #333;
            }

            .itemPrice {
                font-size: 1rem;
                color: #666;
            }
        }

        .itemActions {
            display: flex;
            align-items: center;

            .quantityInput {
                width: 60px;
                padding: 8px;
                margin-right: 10px;
                border: 1px solid #ddd;
                border-radius: 6px;
                font-size: 1rem;
                text-align: center;
            }

            .removeButton {
                background-color: #e74c3c;
                color: #fff;
                padding: 8px 12px;
                border: none;
                border-radius: 6px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #c0392b;
                }
            }
        }
    }

    .summarySection {
        border-top: 1px solid #ddd;
        padding-top: 16px;
        margin-top: 20px;

        .summaryRow, .totalRow {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 1.1rem;
            font-weight: 500;

            &.totalRow {
                font-size: 1.3rem;
                font-weight: 600;
            }
        }
    }
}
