@import '../../../../variables.scss';

.newsletterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - $margin-medium * 2);
    padding: $margin-medium;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: $margin-medium;
    
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
}

.newsletterTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.newsletterDescription {
    font-size: 1rem;
    margin-bottom: 20px;
}

.newsletterForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.newsletterInput {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:focus {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        outline: none;
    }
}

.newsletterButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #ff6f61;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
        background: #ff4b3a;
        transform: translateY(-3px);
    }

    &:focus {
        outline: none;
    }
}
