@import '../../variables.scss';


.termAndConditionPageContainer {
    margin: 40px auto;
    width: calc(100% - $margin-medium * 4);
    padding: $margin-medium;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    color: #333;

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Merriweather', serif;
        color: #222;
        margin-top: 1em;
        margin-bottom: 0.5em;
    }

    p {
        margin-bottom: 1em;
    }

    ul, ol {
        margin: 1em 0;
        padding-left: 1.5em;

        li {
            margin-bottom: 0.5em;
        }
    }

    a {
        color: #0066cc;
        text-decoration: none;
        border-bottom: 1px solid #0066cc;
        transition: color 0.3s ease, border-color 0.3s ease;

        &:hover {
            color: #004999;
            border-color: #004999;
        }
    }

    blockquote {
        margin: 20px 0;
        padding: 10px 20px;
        background-color: #f9f9f9;
        border-left: 4px solid #ddd;
        font-style: italic;
        color: #555;
    }

    .policy-header {
        text-align: center;
        font-size: 2rem;
        color: #444;
        margin-bottom: 20px;
    }
}
