.checkoutButton {
  padding: 15px 30px;
  background-color: #1a3d7a; /* Darker blue for a modern look */
  color: #fff;
  border: none;
  border-radius: 10px; /* Slightly more rounded edges */
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInSlideUp 0.5s ease-out;
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between the text and icon */

  /* Box shadow for depth */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: #163563; /* Slightly darker blue on hover */
    transform: translateY(-4px); /* Raise the button slightly */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: #122a4e; /* Darkest blue on click */
    transform: translateY(0); /* No movement on active */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow when clicked */
  }

  @keyframes fadeInSlideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .icon {
    font-size: 1.5em; /* Slightly larger icon */
    color: inherit; /* Same color as the button text */
  }
}
