@import '../../../variables.scss';

.categoryCard {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    padding: 20px;
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: translateY(-5px);
    }
  
    .cardHeader {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
  
      .categoryImage {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
      }
  
      h3 {
        margin: 0;
        color: #333;
      }
  
      p {
        margin: 5px 0 0;
        color: #777;
      }
  
      .actions {
        margin-left: auto;
        display: flex;
        gap: 10px;
      }
    }
  
    .subCategories {
      h4 {
        margin-bottom: 10px;
        color: $secondary-color;
      }
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
  
        .subCategoryItem {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          padding: 10px;
          background-color: #f9f9f9;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
          .subCategoryImage {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
          }
  
          span {
            flex: 1;
            color: #333;
          }
  
          .subActions {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    
    .buttonEdit {
      padding: 10px;
      background-color: #007bff;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }

    .buttonDelete {
      padding: 10px;
      background-color: #d32f2f;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
    
    
    
    
  }

  