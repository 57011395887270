.incentiveContainer {
    background: linear-gradient(45deg, #f06, #ffba00);
    padding: 40px;
    border-radius: 10px;
    color: white;
    text-align: left;
  
    h2 {
      font-size: 2em;
      margin-bottom: 20px;
      animation: fadeInDown 1s;
    }
  
    p {
      font-size: 1.2em;
      margin-bottom: 30px;
      animation: fadeInUp 1s;
    }
  
    .itemsContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        width: 48%;
        transition: transform 0.3s ease;
  
        &:hover {
          transform: translateY(-10px);
        }
  
        .icon {
          padding: 0px;
          margin: 0px;
          font-size: 2em;
          margin-right: 15px;
          color: #00796b;
        }
  
        .textContent {
          h3 {
            padding: 0px;
            margin: 0px;
            font-size: 1.5em;
          }
  
          p {
            font-size: 1em;
          }
        }
      }
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  