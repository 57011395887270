.container {
  margin: 30px 0; // Space between this container and others
}

.categoryDisplayV1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  background-color: transparent;
  box-shadow: none;
}
.title {
  font-size: 1.8rem; // Slightly larger than default
  font-weight: 600; // Bold
  text-transform: uppercase; // Optional: Uppercase for emphasis
  color: #333; // Darker color for better readability
  border-bottom: 2px solid #e0e0e0; // Optional: Adds a clean underline
  padding-bottom: 10px; // Space between title and content
  margin-bottom: 20px; // Space between the title and the next element
}

.categoryCard {
  background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Subtle gradient for depth */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-8px); /* Slightly less pronounced hover effect */
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }

  .categoryImage {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-radius: 10px 10px 0 0; /* Rounded top corners */
  }

  .categoryContent {
    padding: 20px;
    text-align: center;

    h5 {
      margin: 0;
      font-size: 1rem;
      color: #444; /* Slightly darker for better readability */
      transition: color 0.3s ease; /* Smooth color transition */
    }

    &:hover h5 {
      color: #007bff; /* Change color on hover for interactivity */
    }
  }
}
