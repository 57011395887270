.cartItemList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  width: calc(100% - 20px * 2);
  padding: 20px;
  margin: 0px;
  background-color: white;
  h2 {
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
}

.cartItem {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 0.5s ease-out;
  border: 1px solid #e0e0e0;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .image {
    width: 100px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    flex: 1;

    .name {
      font-size: 1.2rem;
      margin: 0;
      font-weight: bold;
      color: #333;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 8px;

      .quantity {
        width: 50px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #f1f1f1;
        font-size: 1rem;
        text-align: center;
      }

      .price {
        font-size: 1.2rem;
        font-weight: bold;
        color: #007bff;
        ;
      }

      .removeButton {
        background: none;
        border: none;
        color: #ff4d4f;
        font-size: 1.5rem;
        cursor: pointer;
        transition: color 0.3s ease, transform 0.3s ease;

        &:hover {
          color: #ff7875;
          transform: scale(1.1);
        }
      }
    }
  }
}
