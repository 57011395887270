.stepIndicator {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
  
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s;
  
      &.active {
        .icon {
          color: #28a745;
        }
        transform: scale(1.1);
      }
  
      .icon {
        font-size: 2em;
        color: #ccc;
        margin-bottom: 5px;
      }
  
      .label {
        font-size: 1em;
        color: #333;
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  