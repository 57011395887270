@import '../../../variables.scss';


.galleryContainer {
    width: 100%;
    max-width: 600px;
    margin: auto;
    text-align: center;
  }
  
  .mainImage {
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  
  .thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .thumbnail {
    margin: 0 5px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-color 0.3s;
  
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 5px;
    }
  
    &.active,
    &:hover {
      border-color: $secondary-color;
    }
  }
  