.cartItemList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .cartItem {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    animation: fadeIn 0.5s ease-out;
  
    &:hover {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .image {
    width: 80px;
    height: auto;
    margin-right: 20px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .name {
      font-size: 1.2em;
      margin: 0;
      color: #333;
    }
  
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
  
      .quantity {
        width: 50px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      .price {
        font-size: 1.1em;
        color: #333;
      }
  
      .removeButton {
        background: none;
        border: none;
        color: #ff0000;
        font-size: 1.5em;
        cursor: pointer;
        transition: color 0.3s ease;
  
        &:hover {
          color: #ff5050;
        }
      }
    }
  }
  