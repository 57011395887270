.container {
    gap: 2rem;
    background-color: transparent;
    border-radius: 10px;
    padding-bottom: 60px;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  
    .componentPreview {
      flex: 1;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      width: 100%;    
      margin-bottom: 20px;      
    }
  
    .componentEditor {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  
    }
  
    .slides {
      margin-top: 2rem;
  
      .slide {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        padding: 1.5rem;
        gap: 1.5rem;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  
        .slideHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
  
          h4 {
            font-size: 1.2rem;
            font-weight: 600;
            color: #333;
          }
  
          .buttonRemove {
            cursor: pointer;
            font-size: 1.5rem;
            color: #ff4d4f;
            display: flex;
            align-items: center;
          }
  
          .removeButton {
            font-size: 1.25rem;
          }
        }
  
        .inputField {
          margin-bottom: 1rem;
        }
  
        textarea {
          resize: none;
          width: 100%;
          height: 100px;
          padding: 0.75rem;
          font-size: 1rem;
          border-radius: 5px;
          border: 1px solid #e0e0e0;
          margin-top: 0.5rem;
        }
      }
    }
  
    .buttonGroup {
      display: flex;
      gap: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  .mainSettings {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   
    gap: 1.5rem;
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

    @media (min-width: 768px) {
      flex-wrap: nowrap;
      gap: 2rem;
    }
  }
  
.buttonContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}