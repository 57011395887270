.calendarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.navButton {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.navButton:hover {
    background-color: #0056b3;
}

.weekRow {
    display: flex;
    width: 100%;
}

.dayCell {
    flex: 1;
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dayCell:hover {
    background-color: #f0f0f0;
}

.emptyCell {
    background-color: #f9f9f9;
}

.selectedCell {
    background-color: #007BFF;
    color: white;
}