.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  
    .productCard {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s, box-shadow 0.3s;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  
        .productInfo {
          opacity: 1;
        }
      }
  
      .productImage {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 10px;
      }
  
      .productInfo {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        padding: 10px;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s;
  
        .productName {
          margin: 0;
          font-size: 16px;
        }
  
        .productPrice {
          font-size: 14px;
        }
  
        .viewDetails {
          margin-top: 5px;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  