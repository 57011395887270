.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  background-color: white;
  overflow-y: auto;

  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);  // Create 12 responsive columns
    gap: 1rem;  // Space between the grid items
    grid-auto-rows: auto;   // Dynamic row height
    padding: 1rem;
  }

  .gridItem {
    
  }
}

/* Media Query for smaller screens */
@media (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(8, 1fr);  // Use 8 columns for medium-sized screens
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);  // Use 4 columns for tablets
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: 1fr;  // Full width for mobile screens
  }
}
