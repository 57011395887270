.statusHistory {
    background-color: #f4f6f8;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-width: calc(100% - 20px * 2);
    width: 100%;
}

h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.timeline {
    position: relative;
    padding-left: 20px;
    border-left: 3px solid #ddd;
}

.timelineItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
}

.statusCircle {
    position: absolute;
    left: -12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4caf50; /* Green for completed */
    border: 3px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.timelineContent {
    background-color: #fff;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    margin-left: 20px;
}

.status {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

.date {
    font-size: 0.9rem;
    color: #777;
}
