.text_image_banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation-duration: 1s;
  animation-fill-mode: both;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }

  &.top {
    flex-direction: column;
  }

  &.bottom {
    flex-direction: column-reverse;
  }

  &.fade {
    animation-name: fadeIn;
  }

  &.slide {
    animation-name: slideIn;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column; /* On small screens, always display column layout */
    
    .image, .video {
      order: 1; /* Image comes first on top */
    }

    .description {
      order: 2; /* Text comes below the image */
    }
  }
}

.carousel {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carouselItem {
  flex: 1 0 100%;
  position: relative;
  height: 100%;
  width: 100%;
}

.image, .video {
  width: 100%;
  min-height: 400px;
  height: 40vh;
  object-fit: cover;
  overflow: hidden;
}

.button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  
  svg {
    margin: 0px;
    padding: 0px;
    align-self: center;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
}

.description {
  margin: 10px;
  text-align: center;
  z-index: 1;

  h3 {
    font-size: 24px;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    color: #666666;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }
}
