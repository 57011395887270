.incentiveContainer {
    background: white;
    border: 1px solid #ddd;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    h2 {
      font-size: 2em;
      color: #333;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 1.2em;
      color: #666;
      margin-bottom: 30px;
    }
  
    .itemsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        transition: transform 0.3s ease;
  
        &:hover {
          transform: scale(1.05);
        }
  
        .icon {
          font-size: 2em;
          color: #00796b;
          margin-right: 20px;
        }
  
        .textContent {
          h3 {
            font-size: 1.5em;
            color: #333;
          }
  
          p {
            font-size: 1em;
            color: #666;
          }
        }
      }
    }
  }
  