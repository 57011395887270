@import '../../../variables.scss';


.customerForm {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
    padding: $margin-medium;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 0 auto;
    margin-top: $margin-medium;
  
    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  
    .field {
      display: flex;
      flex-direction: column;
      label {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.8rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
      }
    }
  
    .section {
      margin-top: 1.5rem;
  
      h3 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
    }
  
    .actions {
      display: flex;
      justify-content: space-between;
  
      .saveButton {
        background-color: #0070f3;
        color: white;
        padding: 0.8rem 1.2rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          background-color: #005bb5;
        }
      }
  
      .cancelButton {
        background-color: #ccc;
        color: #333;
        padding: 0.8rem 1.2rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          background-color: #aaa;
        }
      }
    }
  }
  