@import '../../../variables.scss';

.table_interactive {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  align-self: center;
  align-items: center;
  padding-bottom: $margin-medium;

  table {
    width: 100%;
    border-collapse: collapse;
    color: $primary-color;
    overflow: hidden;
    table-layout: fixed;

    th, td {
      text-align: left;
      padding: $margin-small;
      padding-left: $margin-medium;
      word-wrap: break-word;
    }

    th {
      background-color: $primary-color;
      color: white;
      padding: $margin-medium;
      text-transform: capitalize;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 0;

        &:hover .filter_icon {
          visibility: visible;
        }

        .filter_icon {
          visibility: hidden;
          margin-left: $margin-small;
        }
      }
    }

    tbody {
      overflow-y: auto;

      tr {
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

        &:hover {
          background-color: #f5f5f5;
          transform: translateY(-3px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        }

        &:nth-child(odd) {
          background-color: #fafafa;
        }
      }

      &.single-row {
        tr {
          height: auto;
        }
      }
    }

    button {
      padding: 8px 16px;
      margin: 0;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      background-color: $accent-color;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;

      &:hover {
        background-color: darken($accent-color, 10%);
        transform: translateY(-2px);
      }

      &:active {
        background-color: darken($accent-color, 20%);
        transform: translateY(0);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba($accent-color, 0.5);
      }
    }
  }

  @media (max-width: 768px) {
    background-color: transparent !important;
    box-shadow: none !important;

    table {
      width: 100%;
      overflow-y: auto;
      background-color: transparent;

      thead, tbody {
        width: 100%;
        background-color: transparent !important;
      }

      th, td {
        display: block;
        width: 100%;
        text-align: left;
        padding: $margin-small;
        padding-left: $margin-medium;
        box-sizing: border-box;
      }

      th {
        position: sticky;
        top: 0;
        background-color: $primary-color;
        color: white;
        z-index: 1;
        border-radius: 0 !important;

        &:nth-child(1) {
          border-top-left-radius: 8px !important;
          border-top-right-radius: 8px !important;
        }
      }

      tbody tr {
        display: block;
        margin-bottom: 10px;
        border: 1px solid $border-color;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

        &:hover {
          background-color: #f5f5f5;
          transform: translateY(-3px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        }
      }

      tbody tr:nth-child(odd) {
        background-color: #fafafa !important;
      }

      tbody.tr.single-row {
        display: block;
        margin-bottom: 10px;
        border: 1px solid $border-color;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      }

      td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: capitalize;
        margin-right: 10px;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.pending {
  color: orange;
}

.verified {
  color: green;
}

.canceled {
  color: red;
}
