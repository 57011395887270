@use '../../../variables.scss' as *;

.slideover_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 50;
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.visible {
        opacity: 1;
    }
}

.slideover_panel {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 30%;
    max-width: 400px;
    background: #ffffff;
    transform: translateX(150%);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 60;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &.open {
        transform: translateX(0);
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
    }
}

.slideover_close_button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    background-color: #f1f1f1;
    cursor: pointer;
    text-align: center;
    color: #6c757d;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: #e2e6ea;
        color: #343a40;
    }
}