.listContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  
    .productItem {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: transform 0.3s;
  
      &:hover {
        transform: translateX(10px);
      }
  
      .productImage {
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
  
      .productInfo {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        .productName {
          font-size: 18px;
          margin-bottom: 10px;
          color: #333;
        }
  
        .productPrice {
          font-size: 16px;
          color: #00796b;
          margin-bottom: 10px;
        }
  
        .detailsButton {
          background: #00796b;
          color: white;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
          cursor: pointer;
          transition: background 0.3s;
  
          &:hover {
            background: #004d40;
          }
        }
      }
    }
  }
  