.notes {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: calc(100% - 20px * 2);
    h2 {
        margin-bottom: 10px;
        font-size: 1.5em;
        color: #333;
    }

    .notesTextArea {
        width: calc(100% - 10px * 2);
        min-height: 120px;
        padding: 10px;
        font-size: 1em;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        resize: vertical;
        transition: border-color 0.3s ease;

        &:focus {
            border-color: #007bff;
            outline: none;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
        }
    }

    .saveButton {
        display: block;
        margin: 20px 0 0 0;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1em;
        transition: background-color 0.3s ease;
        &:hover {
            background-color: #0056b3;
        }
    }
}
