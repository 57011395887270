.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .modalContent {
      background: white;
      padding: 20px;
      border-radius: 8px;
      width: 90%;
      max-width: 800px;
  
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        button {
          background: none;
          border: none;
          font-size: 16px;
          cursor: pointer;
        }
      }
  
      .tabs {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        background-color: lightgrey;
  
        button {
          background: none;
          border: none;
          font-size: 16px;
          cursor: pointer;
          padding: 10px;
  
          &.active {
            border-bottom: 2px solid #333;
          }
        }
      }
  
      .imageGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 10px;
  
        .imageItem {
          cursor: pointer;
          text-align: center;
          
          img {
            max-width: 100%;
            border-radius: 5px;
          }
  
          span {
            display: block;
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
  