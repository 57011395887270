@import '../../../variables.scss';


.orderDetail {
    padding: $margin-medium;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      font-size: 1.8rem;
      color: #333;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  
    .orderInfo {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.2rem;
    }
  
    .infoItem {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      font-size: 1.1rem;
      
      strong {
        font-weight: 600;
        color: #555;
      }
  
      span, .address {
        font-weight: 400;
        color: #777;
      }
  
      .address p {
        margin: 0;
      }
  
      .status {
        font-weight: 600;
        color: #3b82f6; // Change this color based on status (e.g., shipped, processing, etc.)
      }
    }
  }
  