.card {
    width: 100%;
    max-width: 600px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease-in-out;
  
    .header {
      background-color: #00796b;
      color: white;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
  
      h2 {
        margin: 0;
      }
  
      .price {
        font-size: 24px;
      }
  
      .toggleButton {
        font-size: 24px;
        transition: transform 0.3s ease-in-out;
      }
    }
  
    .content {
      padding: 20px;
      background-color: #f9f9f9;
  
      p {
        margin-bottom: 10px;
      }
  
      .features {
        list-style: none;
        padding: 0;
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
  