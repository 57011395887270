@import '../../../variables.scss';

$label-color: #333;  // Dark grey for label color

.customSelect {
    position: relative;
    min-width: 200px;
    font-family: Arial, sans-serif;
    margin-top: $margin-medium;
    .selectBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 4px 10px $box-shadow-color; /* Shadow for elevation */
        border-radius: 6px; /* Smooth rounded corners */
        transition: box-shadow $transition-duration ease, transform $transition-duration ease;
        background-color: white;
        cursor: pointer;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
        color: grey !important;
        height: $input-height;
        padding: 6px;
        &:hover {
            border-color: $focus-border-color;
            box-shadow: 0 0 5px $focus-border-color;
        }
        span {
            color: $border-color;
        }
        span.arrow {
            margin-left: 10px;
            color: $border-color;
        }
    }

    .options {
        position: absolute;
        top: calc(100% + 30px);
        left: 0;
        right: 0;
        background-color: white;
        border: 1px solid $border-color;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(2, 2, 2, 0.1);
        max-height: 200px;
        overflow-y: auto;
        opacity: 1;
        transform: translateY(-20px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        z-index: 1000;
        &.open {
            opacity: 1;
            transform: translateY(0);
            visibility:visible;
        }
        .option {
            padding: 10px 15px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: $focus-border-color;
                color: white;
            }
        }
    }
    .label {
        font-size: $font-size-base;
        color: $label-color;
        font-weight: 600;
        pointer-events: none;
        margin-bottom: $margin-small;
    }

   
}
