@import '../../../variables.scss';

.row {
  display: flex;
}

.label {
  margin-left: $margin-medium;
}
.switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 25px;
    .input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 25px;
  
      &:before {
        position: absolute;
        content: "";
        height: 19px;
        width: 19px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  
    .input:checked + .slider {
      background-color: $secondary-color;
    }
  
    .input:checked + .slider:before {
      transform: translateX(20px);
    }
  }
  