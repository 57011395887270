.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1000px;
    margin: auto;
  
    .reviewCard {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      transition: transform 0.3s, box-shadow 0.3s;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      }
  
      .reviewHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
  
        h3 {
          margin: 0;
          font-size: 18px;
        }
  
        .rating {
          font-size: 14px;
          color: #00796b;
        }
      }
  
      .reviewText {
        font-size: 16px;
        color: #333;
      }
    }
  }
  