@import '../../../variables.scss';

$checkmark-size: 20px; // Size of the checkmark box
$label-color: #333; // Color of the label text

.checkbox {
  margin-top: $margin-medium;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  color: $label-color;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $accent-color;
      border-color: $accent-color;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    display: inline-block;
    width: $checkmark-size;
    height: $checkmark-size;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Soft shadow for depth
    transition: background-color $transition-duration, border-color $transition-duration, box-shadow $transition-duration;

    &:after {
      content: "";
      position: absolute;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }
  }

  .labelText {
    margin-left: 10px;
    transition: color $transition-duration;

    &:hover {
      color: darken($accent-color, 10%);
    }
  }

  &:hover input:not(:disabled) ~ .checkmark {
    border-color: $accent-color;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); // Elevated shadow on hover
  }

  &:active input:not(:disabled) ~ .checkmark {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); // Slightly reduced shadow on click
  }

  input:disabled ~ .checkmark {
    background-color: #e9ecef;
    border-color: #adb5bd;
    cursor: not-allowed;

    &:after {
      border-color: #6c757d;
    }
  }
}
