
    .tableContainer {
      overflow-x: auto;
    }
  
    .inventoryTable {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 2rem;
  
      th, td {
        padding: 1rem;
        border-bottom: 1px solid #ddd;
        text-align: left;
      }
  
      th {
        background-color: #f9f9f9;
        font-weight: bold;
      }
  
      td a {
        color: #3498db;
        text-decoration: underline;
  
        &:hover {
          color: #2980b9;
        }
      }
    }
  
    .graphsSection {
      margin-top: 3rem;
      text-align: center;
    }
  
  