.orderConfirmation {
    padding: 40px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    animation: fadeScaleIn 0.6s ease-out;
    text-align: center;
    color: #fff;
  
    h2 {
      font-size: 2.5em;
      margin-bottom: 10px;
      color: #fff;
    }
  
    p {
      font-size: 1.2em;
      color: #ddd;
    }
  
    .details {
      margin: 30px 0;
  
      p {
        margin: 10px 0;
      }
  
      strong {
        color: #fff;
      }
    }
  
    .items {
      margin: 20px 0;
  
      h3 {
        margin-bottom: 20px;
        font-size: 1.8em;
        color: #fff;
      }
  
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }
    }
  
    button {
      padding: 15px 30px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    @keyframes fadeScaleIn {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  