.orderConfirmation {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
    text-align: center;
  
    h2 {
      font-size: 2em;
      color: #333;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 1em;
      color: #555;
    }
  
    .details {
      margin: 20px 0;
  
      p {
        margin: 5px 0;
      }
  
      strong {
        color: #000;
      }
    }
  
    .items {
      margin: 20px 0;
  
      h3 {
        margin-bottom: 10px;
        font-size: 1.5em;
        color: #444;
      }
  
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  
    button {
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  