.cartSummary {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  .title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
  }

  .itemsList {
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #ddd;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .itemImage {
      width: 60px;
      height: 60px;
      border-radius: 8px;
      margin-right: 15px;
      object-fit: cover;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .itemDetails {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .itemName {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
        color: #333;
      }

      .itemQuantity,
      .itemPrice {
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 5px;
      }

      .itemPrice {
        font-weight: bold;
      }
    }
  }

  .priceDetails {
    font-size: 1rem;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .subtotal,
    .total,
    .taxes,
    .shipping {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-top: 1px solid #f0f0f0;
      font-size: 1rem;

      &:first-child {
        border-top: none;
      }

      &.total {
        font-size: 1.2rem;
        font-weight: 700;
        color: #333;
      }
    }
  }

  .checkoutButton {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }
  }
}
