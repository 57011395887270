@use '../../../variables.scss' as *;

.featureSection {
    padding: 60px 20px;
    background-color: #f7f8fa;
    text-align: center;

    .textContainer {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 50px;

        .sectionTitle {
            font-size: 2.5rem;
            color: #333;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .sectionDescription {
            font-size: 1.2rem;
            color: #666;
            margin-bottom: 0;
        }
    }

    .featuresGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
        max-width: 1200px;
        margin: 0 auto;
    }
}

.featureCard {
    background-color: white;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    cursor: pointer;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    .featureIconContainer {
        background-color: #007bff;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px;

        .featureIcon {
            font-size: 36px;
            color: white;
        }
    }

    .featureTitle {
        font-size: 1.5rem;
        color: #333;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .featureDescription {
        font-size: 1rem;
        color: #666;
    }
}
