@import '../../../variables.scss';


.fileUpload {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    
    &:hover {
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }
  }
  

  .fileUpload p {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    align-self: flex-start;
  }
  
  .uploadButton {
    padding: 12px 20px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    
    &:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }
  }
  
  .fileInput {
    display: none;
  }
  
  .fileDetails {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .fileDetails span {
    color: #555;
    font-size: 14px;
    word-break: break-all;
  }
  
  .deleteButton {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    
    &:hover {
      background-color: #c82333;
      transform: translateY(-2px);
    }
  }
  
.label {
    color: $focus-border-color !important;
    font-weight: normal !important;
}