.returnRequestNotesForm {
    background-color: #f9fafb;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    color: #333;

    h4 {
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
        text-align: left;
      }

    .formGroup {
        margin-bottom: 16px;

        label {
            display: block;
            font-weight: 500;
            margin-bottom: 8px;
            color: #555;
        }

        textarea {
            width: calc(100% - 12px * 2);
            padding: 12px;
            font-size: 1rem;
            border: 1px solid #ddd;
            border-radius: 6px;
            resize: vertical;
            transition: border-color 0.2s ease, box-shadow 0.2s ease;

            &:focus {
                border-color: #4a90e2;
                box-shadow: 0 0 8px rgba(74, 144, 226, 0.2);
            }
        }
    }

    .submitButton {
        width: 100%;
        padding: 12px;
        background-color: #4a90e2;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #357ab8;
        }
    }
}
