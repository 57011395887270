.feedbackStatusUpdate {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100%;
    margin: 20px auto;
    
    .statusContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        .icon {
            color: #4bc0c0;
            font-size: 20px;
        }

        p {
            margin: 0;
            font-size: 16px;
            strong {
                font-weight: 600;
            }
        }
    }

    .updateForm {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;

        label {
            font-size: 14px;
            font-weight: 600;
        }

        select {
            padding: 10px;
            border-radius: 8px;
            border: 1px solid #ccc;
            background-color: #f9f9f9;
            font-size: 14px;
            min-width: 150px;
        }

        .submitButton, .cancelButton {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 10px 15px;
            border: none;
            border-radius: 8px;
            font-size: 14px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            svg {
                font-size: 14px;
            }
        }

        .submitButton {
            background-color: #4bc0c0;
            color: #fff;

            &:hover {
                background-color: darken(#4bc0c0, 10%);
            }
        }

        .cancelButton {
            background-color: #e94560;
            color: #fff;

            &:hover {
                background-color: darken(#e94560, 10%);
            }
        }
    }
}
