.orderTimestamps {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    max-width: calc(100% - 20px * 2);
}

h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}

.timestampRow {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    padding: 8px 0;
    color: #555;
}

.timestampRow span:last-child {
    font-weight: bold;
    color: #000;
}
