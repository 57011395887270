@import '../../../variables.scss'; // Assuming you have a variables.scss file for colors, fonts, etc.

$transition-duration: 0.3s;

.commentBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - $margin-medium * 2);
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn $transition-duration ease-in-out;

    h2 {
        color: $primary-color;
        margin-bottom: 10px;
    }

    .formGroup {
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
            font-weight: bold;
        }

        textarea {
            width: calc(100% - $margin-small * 2);
            padding: $margin-small;
            border: 1px solid $border-color;
            border-radius: 5px;
            resize: vertical;
            transition: border-color $transition-duration ease-in-out;
            min-height: 100px ;

            &:focus {
                border-color: $primary-color;
                outline: none;
                box-shadow: 0 0 5px rgba($primary-color, 0.5);
            }
        }
    }

    .submitButton {
        margin-top: $margin-medium;
        background-color: $accent-color;
        color: #fff;
        padding: 8px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color $transition-duration ease-in-out;

        &:hover {
            background-color: darken($primary-color, 10%);
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
