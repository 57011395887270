@import '../../../variables.scss';

.blog_post_card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }
  
    .image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  
    .content {
      padding: 20px;
  
      .title {
        font-size: 24px;
        margin: 0;
      }
  
      .date {
        font-size: 14px;
        color: #888;
        margin: 5px 0 10px;
      }
  
      .excerpt {
        font-size: 16px;
        margin-bottom: 20px;
      }
  
      .actions {
        display: flex;
        justify-content: space-between;
  
        button {
          flex: 1;
          margin: 0;
          &:nth-child(1) {
            margin-right: 5px;
          }
          &:nth-child(2) {
            margin-left: 5px;
          }
        }
      }
    }
  }
  