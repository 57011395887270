.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
  }
  
  .inline {
   margin-top: 20px;
   display: flex;
  }
  
  .inputGroup {
    display: flex;
    flex-direction: column;
  }
  
  .inputField {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease;
    width: calc(100% - 8px * 2);
  
    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }
  
  .currency {
    font-size: 14px;
    color: #777;
    margin-left: 8px;
  }
  
  .result {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0px;
    margin-top: 8px;

  }
  