.card {
    display: flex;
    background-color: #f8f9fa;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
    animation: fadeIn 0.5s ease-out;
  
    &:hover {
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    }
  
    .photo {
      width: 200px;
      object-fit: cover;
      transition: transform 0.3s;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  
    .info {
      padding: 20px;
  
      h2 {
        margin: 0;
        font-size: 1.6em;
      }
  
      .position {
        color: #777;
        margin: 10px 0;
      }
  
      .bio {
        font-size: 1em;
        color: #555;
      }
  
      .socialLinks {
        margin-top: 15px;
  
        a {
          margin: 0 8px;
          font-size: 1.3em;
          color: #555;
          transition: color 0.3s;
  
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  