.card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeIn 0.5s ease forwards;
    opacity: 0;
  
    h2 {
      margin: 0 0 10px;
    }
  
    p {
      margin: 0 0 20px;
    }
  
    span {
      font-size: 0.9em;
      color: #666;
    }
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  .order {
    background-color: #e3f2fd;
    border-left: 5px solid #2196f3;
  }
  
  .message {
    background-color: #f3e5f5;
    border-left: 5px solid #9c27b0;
  }
  
  .alert {
    background-color: #ffebee;
    border-left: 5px solid #f44336;
  }
  
  .promotion {
    background-color: #e8f5e9;
    border-left: 5px solid #4caf50;
  }
  