.contactInfo {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: fadeIn 0.5s ease-out;
  
    h2 {
      font-size: 1.5em;
      margin-bottom: 20px;
      color: #333;
    }
  
    .infoItem {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-size: 1em;
      color: #555;
  
      svg {
        margin-right: 10px;
        color: #007bff;
      }
  
      p {
        margin: 0;
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  