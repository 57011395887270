.buttonWishList {
  background: none !important;
  border: none;
  color: #ff4081;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: color 0.3s, transform 0.3s;
  outline: none;
  padding: 8px 0px;
  border-radius: 4px;
  margin: 0px;
  &:hover {
    color: #ff80ab;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 64, 129, 0.4);
  }

  .icon {
    font-size: 30px;
    transition: transform 0.3s, color 0.3s;
    padding: 0px;
    margin: 0px;
    margin-right: 10px;
    &:hover {
      transform: scale(1.3);
      color: #ff80ab;
    }
  }

  .label {
    font-size: 18px;
    transition: transform 0.3s;
    padding: 0px;
    margin: 0px;
    &:hover {
      transform: translateX(2px);
    }
  }
}
