.box {
    position: relative;
    height:6vh;
  }
  
  .input {
    padding: 10px;
    width: 6vh;
    height: 6vh;
    background: none;
    border: 4px solid black;
    border-radius: 100px;
    box-sizing: border-box;
    font-size: 14x;
    color: white;
    outline: none;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box:hover .input{
    width: 350px;
    background: #3b3640;
    border-radius: 10px;
  }
  
  .open {
    width: 350px;
    background: #3b3640;
    border-radius: 10px;
  }
  
  .fa_search {
    position: absolute;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vh;
    height:6vh;
    font-size: 3vh;
    color: black;
    transition: .2s;
    text-align: center;
    align-self: center;
  }
  
  .box:hover .fa_search {
    opacity: 0;
    z-index: -1;
  }
  
  .fa_search_close {
    opacity: 0;
    z-index: -1;
  }