@import '../../../variables.scss';

.edit_banner {
    padding: $margin-medium;
    .banner_form {
        padding: $margin-medium;
        border-radius: 10px;
        background-color: #fff;
        width: calc(100% - $margin-medium * 2);
        margin-top: $margin-medium ;
        box-shadow: $box-shadow1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .checkbox {
            margin-top: $margin-medium;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          button {
            margin-top: $margin-large;
            padding: 10px 15px;
            font-size: 14px;
            border: none;
            cursor: pointer;
            background-color: #3498db;
            color: white;
            border-radius: 5px;
        
            &:hover {
              background-color: #2980b9;
            }
        }
    }
}

  