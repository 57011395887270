.addressForm {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 1rem 0;
  
    .field {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
  
      label {
        font-size: 1rem;
        font-weight: bold;
        color: #333;
      }
  
      input {
        padding: 0.8rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: border-color 0.3s ease;
  
        &:focus {
          outline: none;
          border-color: #0070f3;
        }
      }
    }
  }
  