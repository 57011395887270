.colorFilterContainer {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease;
  
    h3 {
      margin-bottom: 10px;
      color: #333;
    }
  
    .colorSwatches {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .colorSwatch {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      transition: transform 0.3s ease, border 0.3s ease;
  
      &.selected {
        border: 2px solid #007bff;
        transform: scale(1.1);
      }
  
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  