@import '../../variables.scss';

.dashboard {
    width: calc(100%);
    
    &Header {
    position: relative;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 0px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: $margin-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    margin-bottom: $margin-medium;

    .h1 {
      font-size: 1.8rem;
      color: #fff;
      font-weight: 700;
      margin: 0;
      text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      animation: float 3s ease-in-out infinite;
    }

  
    .quickActions {
      display: flex;
      flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
    
      button {
        background: rgba(255, 255, 255, 0.25); /* Light semi-transparent white */
        color: #666;
        border: 1px solid rgba(255, 255, 255, 0.3); /* Slight border for depth */
        padding: 10px 15px;
        margin: 10px; /* Margin adjusted for spacing between buttons */
        margin-right: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        backdrop-filter: blur(10px); /* Adds the glassmorphism blur effect */
        border-radius: 12px; /* Rounded corners for a smooth look */
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        transition: all 0.3s ease; /* Smooth transition for hover effects */
    
        svg {
          margin-right: 8px; /* Spacing between icon and text */
          transition: transform 0.3s ease; /* Animate the icon on hover */
        }
    
        &:hover {
          background: rgba(233, 69, 96, 0.8); /* Change background on hover */
          border-color: rgba(233, 69, 96, 0.5); /* Slight change in border color */
          color: #ffffff; /* Ensure text color remains white */
          
          svg {
            transform: scale(1.1); /* Slightly enlarge the icon on hover */
          }
        }
    
        @media (max-width: 600px) {
          width: 100%; /* Make the button take full width on small screens */
          justify-content: center; /* Center-align content on small screens */
        }
      }
    }
           
    }
  
    .keyMetrics {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      max-width: 100%;
      overflow-x: scroll;
  
      .metric {
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        flex: 1;
        margin-right: 20px;
        text-align: center;
  
        h2 {
          margin: 0;
          font-size: 1.2em;
          color: #1a1a2e;
        }
  
        p {
          margin: 10px 0;
          font-size: 2em;
          font-weight: bold;
          color: #0f3460;
        }
  
        .comparison {
          font-size: 0.85em;
          color: red;
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
    }
  
    .charts {
      display: flex;
      justify-content: space-between;
      width: calc(100%);
      flex-wrap: wrap;
      .chart {
        flex-grow:1;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: $margin-medium;
        text-align: center;
        min-width: 400px;
        width: calc(50% - $margin-medium * 2);
        &:not(:nth-child(1)) { margin-top: $margin-medium };
        h3 {
          margin: 0 0 10px 0;
          font-size: 1.2em;
          color: #1a1a2e;
        }
      }
    }
    @media (max-width: 800px) {
      .charts {
        justify-content: center;
    
        .chart {
          width: calc(100% - 40px);
        }
      }
    }
    .recentActivity {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      gap: $margin-small;
    }

    @media (max-width: 800px) {
      .recentActivity {
        width: 100%;
        gap:0px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
      }
    }
    .notification {
        margin-top: 20px;
    }

    .quickLinks {
        margin-top: 20px;
    }
}
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}