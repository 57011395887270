.container {
  margin: 30px 0px;
}

.cardFlipContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.title {
  font-size: 1.8rem; // Slightly larger than default
  font-weight: 600; // Bold
  text-transform: uppercase; // Optional: Uppercase for emphasis
  color: #333; // Darker color for better readability
  border-bottom: 2px solid #e0e0e0; // Optional: Adds a clean underline
  padding-bottom: 10px; // Space between title and content
  margin-bottom: 20px; // Space between the title and the next element
}

.card {
  width: 240px;
  height: 320px;
  perspective: 1000px;
  margin: 10px;
  cursor: pointer;
}

.cardInner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  &:hover {
    transform: rotateY(180deg);
  }
}

.cardFront,
.cardBack {
  position: absolute;
  width: calc(100% - 20px * 2);
  height: calc(100% - 20px * 2);
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cardFront {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.cardBack {
  background: #2c3e50;
  color: white !important;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  h3 {
    margin: 10px 0;
    color: white;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: white;
  }
  
}
// Truncated text for titles and descriptions
.truncateText {
  white-space: nowrap; // Prevents text from wrapping
  overflow: hidden; // Hides overflow content
  text-overflow: ellipsis; // Displays ellipsis (...) when text overflows
  max-width: 100%; // Ensures the text does not exceed container width
}


.productImage {
  width: 100%; // Ensures the image fills the width of its container
  max-width: 200px; // Optional: Set a maximum width for the images
  height: 200px; // Set a fixed height to ensure uniformity
  object-fit: contain; // Ensures the image covers the area while maintaining aspect ratio
  border-radius: 10px;
  margin-bottom: 15px; // Space between the image and the product name
}
