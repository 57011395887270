@import '../../../../variables.scss';

.orderCustomerInfo {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(100% - 40px);    
    margin: 0 auto;
    margin-top: $margin-medium;
    h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }
    h4 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .editButton {
            margin: 0px;
        }
    }
}


.section {
    margin-bottom: 20px;
}


