.card {
    width: 100%;
    max-width: 600px;
    margin: auto;
    height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  
    &:hover {
      transform: scale(1.05);
  
      .overlay {
        opacity: 1;
      }
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      opacity: 0;
      transition: opacity 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
  
    .content {
      text-align: center;
  
      h2 {
        font-size: 28px;
        margin-bottom: 10px;
      }
  
      .price {
        font-size: 24px;
        margin-bottom: 10px;
        color: #ffc107;
      }
  
      p {
        font-size: 16px;
        margin-bottom: 10px;
      }
  
      .features {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
  