.carouselContainer {
  position: relative;
  width: calc(100%);
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  .prevButton,
  .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 10px;
    z-index: 1;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.6);
      transform: scale(1.1);
    }
  }

  .prevButton {
    left: 10px;
  }

  .nextButton {
    right: 10px;
  }

  .carousel {
    display: flex;
    transition: transform 0.5s ease;
    transform: translateX(calc(-100% * var(--current-index)));
    width: 100%;
  }

  .reviewCard {
    min-width: 100%;
    padding: 30px 60px;
    box-sizing: border-box;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: scale(0.95);
    background-color: #f5f7fa;  // Softer background color
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  // Subtle shadow for depth

    &.active {
      opacity: 1;
      transform: scale(1);
    }

    .reviewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
        font-size: 1.4em;
        color: #222;
        font-weight: 600;
      }

      .rating {
        font-size: 20px;
        color: #ffd700;  // Gold color for the rating
        display: flex;
        align-items: center;
        gap: 5px;

        .star {
          font-size: 20px;  // Icon size for stars
        }
      }
    }

    .reviewText {
      font-size: 1.1em;
      color: #444;
      line-height: 1.6;
      letter-spacing: 0.5px;
      margin-top: 10px;
    }

    .reviewerDetails {
      margin-top: 20px;
      font-size: 0.9em;
      color: #777;

      .date {
        font-style: italic;
      }
    }
  }
}
