.bannerContainer {
    background-color: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    animation: zoomIn 1s;
  
    .bannerContent {
      z-index: 1;
  
      h1 {
        font-size: 2.5em;
        margin: 0;
        animation: fadeInDown 1s;
      }
  
      h2 {
        font-size: 1.5em;
        margin: 10px 0;
        animation: fadeInUp 1s;
      }
  
      .callToAction {
        padding: 10px 20px;
        background: #00796b;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        transition: background 0.3s;
  
        &:hover {
          background: #004d40;
        }
      }
    }
  }
  
  @keyframes zoomIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  