.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px; // Adds space between the buttons for a more airy look
}

.button {
  background: linear-gradient(135deg, #6DD5FA, #2980B9); // Subtle gradient for modern look
  color: #fff;
  border: none;
  padding: 12px 20px; // Increased padding for a more substantial look
  margin: 0;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease; // Added transition for transform
  border-radius: 25px; // More rounded corners for a modern look
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Light shadow for floating effect

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
    box-shadow: none; // Remove shadow for disabled state
  }

  &.active {
    background: #2980b9;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); // Slightly stronger shadow for active state
  }

  &:hover:not(:disabled) {
    background: linear-gradient(135deg, #57C1EB, #206BA4); // Slightly different gradient on hover
    transform: translateY(-2px); // Lift effect on hover
  }
}

span {
  margin: 0 10px;
  font-weight: bold; // Make the span text bold for better visibility
  color: #2980b9; // Match the active button color
}