.paymentMethod {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
  
    h2 {
      margin-bottom: 15px;
      font-size: 1.5em;
      color: #333;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      .methodLabel {
        margin-bottom: 10px;
        font-size: 1em;
        color: #555;
  
        input {
          margin-right: 10px;
        }
      }
  
      button {
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  