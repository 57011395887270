@import '../../../../variables.scss';

.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: white;
}

.label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    align-items: center;
    display: flex;
    svg {
        margin: 0px;
        padding: 0px;
        margin-right: $margin-medium;
    }
}

.paymentMethods {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.paymentIconWrapper {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        transform: scale(1.1);
    }
}

.paymentIcon {
    width: 50px;
    height: auto;
}
