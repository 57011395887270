.circular_selector {
  display: flex;
  align-items: center;
  gap: 10px;  // Add space between the buttons and the quantity
  margin: 0;
}

.circular_button {
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;  // Increase size for better readability
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  // Subtle shadow for depth

  &:hover {
    background-color: #ff5722;  // Use a modern accent color
    border-color: #ff5722;
    color: white;
    transform: scale(1.15);  // Slightly larger hover effect
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);  // Enhance shadow on hover
  }

  &:active {
    transform: scale(1.05);  // Smaller scale for a pressing effect
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    border-color: #ff5722;  // Focus state to match hover color
    box-shadow: 0 0 0 3px rgba(255, 87, 34, 0.2);  // Subtle glow for focus
  }
}

.quantity {
  font-size: 20px;  // Larger and more modern size
  font-weight: bold;
  width: 40px;
  text-align: center;
  color: #333;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 8px;  // Add rounded corners for a softer look
  border: 1px solid #ddd;
}
