.trustSignals {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
    text-align: center;
  
    .trustItem {
      margin-bottom: 20px;
  
      .icon {
        font-size: 3em;
        color: #28a745;
        margin-bottom: 10px;
      }
  
      p {
        font-size: 1.2em;
        color: #333;
        font-weight: bold;
      }
    }
  
    .paymentMethods {
      display: flex;
      justify-content: center;
      gap: 20px;
  
      .icon {
        font-size: 2em;
        color: #555;
        transition: color 0.3s;
  
        &:hover {
          color: #000;
        }
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  