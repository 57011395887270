$pastel-colors: (
  pastel-blue: #a8d5e2,
  pastel-green: #b8e4c9,
  pastel-pink: #f5c6d5,
  pastel-purple: #d4c4fb,
  pastel-yellow: #fcefb4,
);

.badgesList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
;}

.badge {
  position: relative;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  white-space: nowrap;
  background-color: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

    .closeButton{
      opacity: 1;
    }
  }

  // Define pastel colors
  @each $color-name, $color-value in $pastel-colors {
    &.#{$color-name} {
      background-color: $color-value;
    }
  }

  .closeButton {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
    padding: 0px;
    margin-left: 10px;
    &:hover {
      background-color: #555;
    }
    svg {
      padding: 0px;
      margin: 0px;
      text-align: center;
    }
  }
}

.default_color {
  background-color: #d4c4fb; // Default pastel color if none is provided
}
