.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-out;

  &.flipped .front {
    transform: rotateY(180deg);
  }

  &.flipped .back {
    transform: rotateY(0);
  }
  .front {
    flex-direction: column;
  }
  .back {
    flex-wrap: wrap;
  }
  .front, .back {
    width: calc(100% - 20px * 2);
    height: calc(100% - 20px * 2);
    position: absolute;
    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #333;
    text-align: center;
  }

  .front {
    background-color: #007bff;
    color: white;
  }

  .back {
    background-color: #fff;
    transform: rotateY(180deg);
    div {
        margin-right: 20px;
    }
    h3 {
      margin: 0px;
      color: #007bff;
    }

    p {
      font-size: 1em;
      color: #555;
      margin: 0px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
