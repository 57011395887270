.stepper_selector {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .stepper_button {
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    transition: background 0.2s, transform 0.2s;
  }
  
  .stepper_button:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
  }
  
  .quantity_input {
    width: 50px;
    font-size: 18px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.2s;
  }
  
  .quantity_input:focus {
    border-color: #333;
    outline: none;
  }
  