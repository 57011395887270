@import '../../../../variables.scss';


.finalizeRefundTable {
    width: 100%;
    max-width: calc(100% - 20px * 2);
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    overflow-x: auto;
    margin-top: 20px;
  
    h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 20px;
      text-align: left;
      font-weight: 600;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      font-family: 'Arial', sans-serif;
  
      thead {
        background-color: #f5f5f5;
  
        th {
          padding: 12px 20px;
          text-align: left;
          font-weight: 600;
          font-size: 0.9rem;
          color: #666;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-bottom: 2px solid #ddd;
        }
      }
  
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f9f9f9;
          }
  
          &:hover {
            background-color: #f0f0f0;
            transition: background-color 0.3s ease;
          }
        }
  
        td {
          padding: 16px 20px;
          font-size: 0.9rem;
          color: #555;
          text-align: left;
          vertical-align: middle;
        }
  
        td:last-child {
          text-align: right;
          color: #333;
          font-weight: 600;
        }
  
        td:first-child {
          font-weight: 500;
        }
      }
  
      /* Add a light border around the table */
      th, td {
        border-bottom: 1px solid #eee;
      }
    }
  
    .totalRefund {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      padding: 16px 0;
      font-size: 1.2rem;
      font-weight: 600;
      color: #4caf50;
      border-top: 2px solid #eee;
      border-bottom: 2px solid #eee;
  
      label {
        color: #333;
      }
  
      span {
        font-weight: 700;
      }
    }
  
    /* Create Refund Button */
    button {
      display: inline-block;
      padding: 12px 20px;
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      background-color: $highlight-color;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: darken($highlight-color, 10);
      }
  
      &:focus {
        outline: none;
      }
  
      &:active {
        background-color: darken($highlight-color, 10);
      }
  
      margin-top: 20px;
      text-align: center;
    }
  
    /* Responsive styling */
    @media (max-width: 768px) {
      table, thead, tbody, th, td, tr {
        display: block;
      }
  
      tr {
        margin-bottom: 15px;
      }
  
      th {
        display: none;
      }
  
      td {
        display: flex;
        justify-content: space-between;
        padding: 12px 15px;
        border-bottom: 1px solid #eee;
        position: relative;
      }
  
      td::before {
        content: attr(data-label);
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.75rem;
        color: #999;
      }
    }
  }
  