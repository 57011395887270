.stepIndicator {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
  
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s;
  
      &.active {
        .number {
          background-color: #007bff;
          color: white;
        }
        transform: scale(1.1);
      }
  
      .number {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        font-size: 1.2em;
      }
  
      .label {
        font-size: 0.9em;
        color: #555;
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  