.tax_rate_card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card_actions {
    display: flex;
    gap: 10px;
  }
  