.tableContainer {
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #e0e0e0;

  th,
  td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
}

// Responsive Styles for Smaller Screens
@media screen and (max-width: 768px) {
  .table {
    border: 0;

    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 20px;
    }

    td {
      display: block;
      text-align: right;
      border-bottom: 1px solid #e0e0e0;
      position: relative;
      padding-left: 50%;

      // Adding a pseudo-element to label each cell with the corresponding header's text
      &::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
        background-color: #f4f4f4;
        border-right: 1px solid #e0e0e0;
      }
    }

    tr:nth-child(even) td {
      background-color: #f9f9f9;
    }
  }
}

.actionButton {
  margin: 0px;
  padding: 5px 10px;
  margin: 5px 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &.disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
}
