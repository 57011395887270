@import '../../../variables.scss';

.settings_form {
    width: calc(100% - $margin-medium * 2);
    margin: 0 auto;
    padding: $margin-medium;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: $margin-medium;
  }
  
  .form_group {
    margin-bottom: 15px;
  }
  
  .form_group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form_group input,
  .form_group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  