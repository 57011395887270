@import '../../variables.scss';

.feedback_page {  
    .content {
      width: calc(100%);
    }
    h1 {
      color: #333;
      margin-bottom: 20px;
    }
  
    .main_content {
      display: flex;
      gap: 20px;
    }
  }
  

  

  