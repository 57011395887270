@import '../../../variables.scss';

.faqsPage {
    background-color: #f9f9f9;
    .content {
      margin-top: 20px;
    }
  
    .faqList {
      list-style-type: none;
      padding: 0px;
    }
  
    .faqItem {
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }
  
    .question {
      font-weight: bold;
    }
  
    .answer {
      margin-top: 5px;
    }
  
    .actions {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }
  }

  .addButton {
    background-color: $primary-color;
    padding: 10px 20px;
    font-size: 16px;
  }
  .editButton {
    background-color: $secondary-color;
    padding: 5px 10px;
    font-size: 12px;
  }
  .deleteButton {
    background-color: $danger-color;
    padding: 5px 10px;
    font-size: 12px;
  }
  .addButton, .editButton, .deleteButton {
    color: white;
    border: none;
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for shadow and transform */
  
    /* Initial box-shadow for a slight elevation effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
      transform: translateY(-2px); /* Slight lift effect on hover */
    }
  
    &:active {
      transform: translateY(0); /* Reset lift effect when the button is pressed */
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Slightly reduced shadow on active state */
    }
  }