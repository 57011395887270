// ComponentList.module.scss

$primary-color: #0f3460;  // Primary accent color
$secondary-color: #4bc0c0; // Secondary accent color
$background-color: #f5f5f5;  // Background color for the sidebar
$text-color: #1a1a2e;  // Main text color
$hover-background: rgba(153, 102, 255, 0.1);  // Hover effect color

.componentList {
    width: 150px;
    background-color: $background-color;
    height: calc(100vh - 20px * 2);
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-y: auto;
}

.componentItem {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 6px;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    color: $text-color;
    font-weight: bold;
    text-transform: capitalize;

    &:hover {
        background-color: $hover-background;
        transform: translateX(5px);
    }

    img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }

    .componentName {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .componentList {
        width: 100%;
        height: auto;
        padding: 10px;
    }
}
