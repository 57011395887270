@import '../../../../variables.scss';

.orderInfo {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(100% - 40px);    
    margin: 0 auto;
    margin-top: $margin-medium;
    h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }
}

.section {
    margin-bottom: 20px;
}

.label {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
}

.separator {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    margin: 20px 0;
}
