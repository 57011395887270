// AccordionDescription.module.scss

.accordionContainer {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
  
    .accordionTitle {
      padding: 15px 20px;
      background-color: #f7f7f7;
      cursor: pointer;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #e7e7e7;
      }
  
      .accordionIcon {
        font-size: 1.2em;
        transition: transform 0.3s ease;
      }
    }
  
    .accordionContent {
      padding: 15px 20px;
      background-color: #fff;
      animation: slideDown 0.3s ease;
  
      p {
        margin: 0 0 10px;
        line-height: 1.5;
      }
  
      .attributes {
        margin-top: 10px;
  
        .attribute {
          margin: 5px 0;
        }
      }
    }
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  