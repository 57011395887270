@use '../../../../variables.scss' as *;

$border-color: #ccc;
$focus-border-color: #007BFF;
$input-height: 40px;
$input-padding: 8px;
$label-color: #999;
$animation-duration: 0.3s;
$font-size-base: 1rem;

.inputPrice {
  margin-bottom: 1.5rem;
  width: 400px;
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: $font-size-base;
    color: $label-color;
    text-align: left;
  }
  

  .input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: 4px;
    

    .icon_container {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      color: #888;
      width: 40px;
    }

    input {
      border: none;
      outline: none;
      box-shadow: none;
      font-size: $font-size-base;
      height: $input-height;
      width: calc(100% - 40px - 40px);
      text-indent: 8px;
      padding: 0px;
  }
    select {
      width: 40px;
      height: $input-height;
      border-left: none;
      border-radius: 0px;
      outline: none;
      border: none;
      font-size: $font-size-base;
      padding: $input-padding;
      background-color: white;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      z-index: 1;
      padding: 0px;
      margin: 0px;
      color: lightgrey;
      &:focus {
        border-color: $focus-border-color;
      }
    }

    select::-ms-expand {
      display: none;
    }
  }
}

.input_wrapper:focus-within {
    border-color: $focus-border-color;
    box-shadow: 0 0 8px rgba($focus-border-color, 0.3);
}