.qaList {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
  }
  
  .qaItem {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #f0f0f0;
    }
  
    .question {
      h3 {
        margin: 0;
        font-size: 1.2em;
        color: #007bff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  
    .answer {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease, opacity 0.3s ease;
      opacity: 0;
      padding: 0 15px;
      font-size: 1em;
      color: #555;
  
      &.show {
        max-height: 200px;
        opacity: 1;
        padding: 15px;
      }
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  