.ticketTableContainer {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    margin: 10px auto;
    overflow: hidden;
  }
  
  .ticketTable {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  
    th, td {
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;
    }
  
    th {
      background-color: #0f3460;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
  
    tr:hover {
      background-color: #f9f9f9;
    }
  
    .attachmentIcon {
      color: #666;
      margin-left: 8px;
    }
  
    .unassignedIcon {
      color: #999;
    }
  
    .viewButton {
      background-color: #1976d2;
      color: #fff;
      padding: 8px 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: darken(#1976d2, 10%);
      }
    }
  
    // Status colors
    .status {
      font-weight: bold;
      text-transform: capitalize;
      &.pending {
        color: #ff9800;
      }
      &.resolved {
        color: #4caf50;
      }
      &.inprogress {
        color: #2196f3;
      }
      &.closed {
        color: #9e9e9e;
      }
    }
  
    // Priority colors
    .priority {
      &.low {
        color: #4caf50;
      }
      &.medium {
        color: #ff9800;
      }
      &.high {
        color: #f44336;
      }
      &.urgent {
        color: #d32f2f;
      }
    }
  }
  