.analyticsPage {
    background-color: #f4f6f8;
    min-height: 100vh;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
    padding: 20px;
}

.card {
    background-color: transparent;
    border-radius: 12px;
    padding: 0px;
}

.chartContainer {
    height: 400px;
    width: 100%;
    position: relative;
}

@media (max-width: 768px) {
    .gridContainer {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }

    .card h3 {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    .card {
        padding: 15px;
    }
}

