.items {
    width: 100%;
    padding: 20px;
    max-width: calc(100% - 20px * 2);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      color: #0f3460;
      margin-bottom: 20px;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
  
      th, td {
        padding: 15px;
        border-bottom: 1px solid #ccc;
      }
  
      th {
        background-color: #0f3460;
        color: #fff;
        text-transform: uppercase;
      }
  
      tbody tr:hover {
        background-color: #f5f5f5;
        transition: background-color 0.3s;
      }
  
      select {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      input[type="checkbox"] {
        transform: scale(1.2);
      }
  
      @media (max-width: 768px) {
        table, thead, tbody, th, td, tr {
          display: block;
          overflow-x: auto;
        }
  
        th, td {
          white-space: nowrap;
        }
      }
    }
  }
  