// ProductPrice.module.scss

$product-color: #333;
$promo-color: #ff4d4f;
$badge-background: #ff4d4f;
$original-price-color: #999;
$font-size: 1.5em;

.productPriceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0px;
  margin: 0px;
  .priceInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .promoBadge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: $badge-background;
    color: #fff;
    font-size: 0.75em;
    padding: 5px 10px;
    border-radius: 5px;
    transform: scale(0);
    animation: scaleUp 0.3s forwards ease-in-out;
  }

  .price {
    font-weight: bold;
    color: $promo-color;
    font-size: $font-size;
    transition: color 0.3s ease;
  }

  .originalPrice {
    text-decoration: line-through;
    color: $original-price-color;
    font-size: $font-size;
    transition: color 0.3s ease;
  }
}

@keyframes scaleUp {
  to {
    transform: scale(1);
  }
}
