@use '../../../../variables.scss' as *;

.stats_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    background-color: #f8f9fa;
    padding: $margin-medium;
    width: calc(100% - 2 * $margin-medium);
    & .title {
        text-align: center;
        font-size: $font-size-title;
        margin-top: $margin-small;
    }
    & .description {
        margin-top: $margin-small;
        text-align: center;
        font-size: $font-size-base;
        color: grey;
    }
    img {
       width: calc(100% - $margin-medium *2); 
       object-fit: cover;
       border-top-left-radius: 8px;
       border-top-right-radius: 8px;
    }
    & .card_list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $margin-medium;
    }
  }

  .stat_card1 {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: box-shadow 0.3s ease;
    max-width: 200px;
    flex: 1;
    .stat_card:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    .stat_icon {
        font-size: 40px;
        color: #007bff;
        margin-bottom: 15px;
    }
    .stat_info {
        text-align: center;
    }
    .stat_title {
        font-size: 1.2em;
        margin-bottom: 5px;
        color: #555;
    }
    .stat_value {
        font-size: 2em;
        font-weight: bold;
        color: #333;
    }
  }
  
  