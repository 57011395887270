@use '../../../variables.scss' as *;

.faqContainer {
  padding: $margin-medium;
  max-width: 100%;
  margin: 0 auto;
}

.faqCard {
  width: 100%;
  padding: $margin-small 0;
  border-bottom: 1px solid lightgrey;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }

  .questionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: $margin-small 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    transition: color 0.3s ease;

    &:hover {
      color: #007bff;
    }

    svg {
      transition: transform 0.3s ease;
    }
  }

  .answerContainer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    padding-left: $margin-small;
    padding-right: $margin-small;

    &.open {
      max-height: 500px; /* Set this to a maximum height that works for your content */
      opacity: 1;
      margin-top: $margin-small;
    }

    p {
      margin: 0;
      font-size: 1rem;
      color: #555;
    }
  }
}
