@import '../../../../variables.scss';

.filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.3); /* Translucent background */
  border-radius: 15px; /* Rounded corners for a smooth look */
  backdrop-filter: blur(10px); /* Blur effect for the glassmorphism */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Light border to enhance the glass effect */
  animation: fadeIn 0.5s ease-out; /* Subtle fade-in animation */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: $margin-medium;
  margin-bottom: $margin-medium;

  &:hover {
    background: rgba(255, 255, 255, 0.35); /* Slight change on hover */
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }

  .selectRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow items to wrap in smaller screens */
    gap: 10px; /* Add some spacing between items */
  }

  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .selectRow {
      flex-direction: column; /* Switch to vertical layout */
      gap: 15px; /* Increase spacing for better readability */
    }
  }
}
