@import '../../../variables.scss';

.email_campaign_editor {
    overflow-y: scroll;
    .content {
        margin-top: $margin-medium;
        background-color: white;
        padding: $margin-medium;
        width: calc(100% - $margin-medium);
    }
    .templates_section {
        .list {
            max-height: 200px;
            overflow-y: scroll;
        }
    }
    .button_container {
        display: flex;
        flex-direction: row;
        margin-top: $margin-large;
        gap: 10px;
        
    }
}