@import '../../../variables.scss';


.returnRequest {
    padding: $margin-medium;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      margin-bottom: 1.5rem;
      color: #333;
    }
  
    .itemsTable {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1rem;
  
      th, td {
        padding: 0.75rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
  
      .selectedRow {
        background-color: #e3f2fd; // Light blue background for selected rows
      }
    }
  
    .formGroup {
      margin-bottom: 1rem;
  
      label {
        display: block;
        margin-bottom: $margin-medium;
        color: #555;
      }
  
      select, textarea {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 6px;
      }
  
      textarea {
        width: 100%;
        height: 100px;
        padding: $margin-medium;
        border-radius: 8px;
        border: 1px solid #ccc;
        margin-bottom: 1rem;
        font-size: 1rem;
        resize: none;
        outline: none;
        transition: border-color 0.3s ease;
        width: calc(100% - $margin-medium * 2);
  
        &:focus {
          border-color: #5a67d8;
        }
      }
    }
  
    .submitButton {
      background-color: #5a67d8;
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  