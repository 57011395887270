
  
    .content {
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        padding: 20px;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 20px;
    }
  
    .feedbackDetails {
        display: flex;
        flex-direction: column;
        gap: 20px;
  
        .detailItem {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 15px;
            border-bottom: 1px solid #e0e0e0;
            font-size: 16px;
            color: #333;
  
            &:last-child {
                border-bottom: none;
            }
  
            p {
                margin: 0;
                line-height: 1.5;
  
                strong {
                    font-weight: 600;
                }
            }
  
            .icon {
                color: gray;
                font-size: 20px;
            }
        }
    }
  