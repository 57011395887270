.contactForm {
  max-width: 600px;
  margin: auto;
  animation: fadeIn 0.5s ease-out;

  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    .cardHeader {
      background-color: #007bff;
      color: #fff;
      padding: 20px;
      border-radius: 10px 10px 0 0;
      text-align: center;

      h2 {
        margin: 0;
      }
    }

    .cardBody {
      padding: 20px;

      .formGroup {
        margin-bottom: 15px;

        .label {
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
          color: #333;
        }

        .input, .textarea, .select {
          width: calc(100% - 20px);
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          transition: border-color 0.3s, box-shadow 0.3s;

          &:focus {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
          }
        }

        .select {
          background-color: #fff;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjE1Ij48cGF0aCBmaWxsPSIjNzk3OTc5IiBkPSJNNSAxMEw4IDEzIDEzIDVMNSAxMHoiLz48L3N2Zz4=');
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 12px;
          appearance: none;
          -webkit-appearance: none; /* For Safari */
          width: 100%;
          cursor: pointer;

          &:focus {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
            background-color: #fff;
          }
        }
      }
    }

    .cardFooter {
      padding: 20px;
      text-align: center;

      .submitButton {
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
