@import '../../../variables.scss';


.footer {
    background-color: #333;
    color: white;
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  
    .footerElement {
      margin: 10px;
      transition: transform 0.3s;
  
      &:hover {
        transform: translateY(-2px);
      }
  
      a {
        color: white;
        text-decoration: none;
        transition: color 0.3s;
  
        &:hover {
          color: #007bff;
        }
      }
  
      p {
        margin: 0;
      }
  
      .socialMedia {
        display: flex;
        gap: 10px;
  
        a {
          display: block;
          width: 40px;
          height: 40px;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
  
      form {
        display: flex;
        gap: 10px;
  
        input {
          padding: 10px;
          border-radius: 4px;
          border: none;
        }
  
        button {
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          background-color: #007bff;
          color: white;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #0056b3;
          }
        }
      } 
    }
    
    .row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
    }

  }
  