@import '../../../variables.scss';


.feedback_stats {
  margin-top: $margin-medium;  
  .cards {
    display: flex;
    gap: 20px;
  }

  .card {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 15px;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .icon {
      font-size: 40px;
      color: #3498db;
    }

    .card_content {
      p {
        margin: 0;
        font-size: 14px;
        color: #666;
      }

      h3 {
        margin: 5px 0 0;
        font-size: 24px;
        color: #333;
      }
    }
  }
}
