@import '../../../../variables.scss';

.htmlContentViewer {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 20px auto;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
    margin-top: $margin-medium;

    .title {
        font-size: 24px;
        font-weight: 600;
        color: #333;
        margin-bottom: 20px;
        text-align: center;
    }

    .contentContainer {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
        overflow: auto;
        max-height: 500px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }

        img {
            max-width: 100%;
            height: auto;
            border-radius: 5px;
        }

        a {
            color: #3498db;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }

        p, h1, h2, h3, h4, h5, h6 {
            color: #555;
            margin-bottom: 1em;
            line-height: 1.6;
        }
    }
}
