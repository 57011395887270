.carouselContainer {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(calc(-100% * var(--current-slide)));
}

.slide {
  min-width: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.active {
    opacity: 1;
  }
}

.productImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.productInfo {
  text-align: center;
  padding: 20px;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}
