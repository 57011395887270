@use '../../../variables.scss' as *;

.review {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: $margin-large;
    background-color: white;
    padding: $margin-large;
}


.reviewCard {
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 0px;
    margin: 20px 0;
    flex: 1;
    
    .header {
      display: flex;
      flex-direction: row;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .user_info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: baseline;
      }
    }
    .body {
      margin: 15px 0;
  
      .review_text {
        font-size: 1em;
        color: #333;
      }
    }
    .footer {
      .date {
        font-size: 0.8em;
        color: #999;
      }
    }
  }