.orderSummary {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  max-width: calc(100% - 20px * 2);
  
  h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }

  .lineItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    color: #333;
    padding: 10px 0;

    .icon {
      margin-right: 10px;
      color: #555;
    }
  }

  .divider {
    border: 0;
    height: 1px;
    background-color: #e0e0e0;
    margin: 10px 0;
  }

  .total {
    font-weight: bold;
    font-size: 1.3rem;
    color: #2c3e50;

    .icon {
      color: #2c3e50;
    }

    span:last-child {
      color: #27ae60; /* Green color for the total */
    }
  }
}
