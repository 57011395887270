// src/components/ProductVariants.module.scss
@import '../../../../../variables.scss';

.variantsWrapper {
    h2 {
        margin-bottom: 20px;
        color: #0d47a1;
    }
}
  
  .addVariant,
  .addOption {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  
    input {
      flex: 1;
      border: 1px solid #ccc;
      border-radius: 4px;
      height: 40px;
      text-indent: 10px;
      font-size: 14px;
      padding: 0px;
      &::placeholder {
        color: #ccc;
      }
    }
  
    button {
      height: 40px;
      padding: 8px 16px;
      background-color: #4A90E2;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin: 0px;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  .variant {
    margin-bottom: 20px;
  
    .variantHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h3 {
        margin: 0;
        text-transform: capitalize;
        margin-bottom: 10px;
        margin-top: 15px;
        color: grey
      }
  
      button {
        padding: 4px 8px;
        background-color: #d32f2f;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          background-color: #9a0007;
        }
      }
    }
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-top: 5px;
  
        button {
          padding: 4px 8px;
          background-color: #d32f2f;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
  
          &:hover {
            background-color: #9a0007;
          }
        }
      }
    }
  }
  