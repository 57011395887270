@use '../../../variables.scss' as *;

$background-color: rgba(255, 255, 255, 0.8);
$border-color: rgba(0, 0, 0, 0.1);
$icon-color: #007BFF;
$close-icon-color: #333;
$animation-duration: 0.4s;

.success_alert {
  display: flex;
  align-items: center;
  background: $background-color;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 16px 20px;
  margin: 10px 0;
  color: $icon-color;
  font-size: 1rem;
  position: relative;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: fadeInUp $animation-duration ease forwards;

  &:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  }

  .success_alert_check_icon {
    margin-right: 12px;

    .icon {
      color: $icon-color;
      font-size: 28px;
      animation: pop $animation-duration ease forwards;
    }
  }

  h3 {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    color: #333;
  }

  .success_alert_message {
    margin: 4px 0 0;
    font-size: 0.95rem;
    color: #555;
    line-height: 1.4;
  }

  .success_alert_close_button {
    margin-left: auto;
    font-size: 1.2rem;
    cursor: pointer;
    color: $close-icon-color;
    transition: color $animation-duration ease, transform $animation-duration ease;

    &:hover {
      color: darken($close-icon-color, 20%);
      transform: scale(1.1);
    }
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
