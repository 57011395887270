$color-1: #0f3460;  // Main text
$color-2: #e94560;  // Accent color for important elements (like pending orders)
$color-4: #4bc0c0;  // Completed and confirmed status
$color-border: hsl(0, 0%, 88%);  // Borders and dividers
$color-5: rgba(153, 102, 255, 1);  // Shipped status

.ordersList {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100;
    height: 100%;
    max-width: 100%;
    margin: 10px auto;
    overflow: hidden;

    table {
        width: 100%;
        border-collapse: collapse;
        table-layout: auto;

        th, td {
            padding: 15px;
            border-bottom: 1px solid $color-border;
            text-align: left;
        }

        th {
            background-color: $color-1;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.05em;
        }

        td {
            font-size: 14px;
            color: $color-1;

            &:first-child {
                font-weight: bold;
            }
        }

        tbody tr {
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease;

            &:hover {
                background-color: lighten($color-border, 30%);
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }

            &:nth-child(odd) {
                background-color: #fafafa;
            }
        }

        .orderStatus {
            &.pending {
                color: $color-2;
            }
            &.confirmed, &.completed {
                color: $color-4;
            }
            &.shipped {
                color: $color-5;
            }
            &.cancelled {
                color: darken($color-2, 10%);
            }
        }

        .paymentStatus {
            &.pending {
                color: $color-2;
            }
            &.completed {
                color: $color-4;
            }
            &.failed {
                color: darken($color-2, 10%);
            }
        }
    }

    @media (max-width: 768px) {
        table, thead, tbody, th, td, tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            margin-bottom: 10px;
            background-color: #fff;
            padding: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }

        td {
            border: none;
            position: relative;
            padding-left: 50%;

            &:before {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 45%;
                white-space: nowrap;
                content: attr(data-label);
                font-weight: bold;
            }
        }
    }
}
