.promotion_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.promotion_item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    h3 {
        margin: 0 0 10px;
        font-size: 18px;
        color: #333;
    }

    p {
        margin: 5px 0;
        font-size: 14px;
        color: #666;
    }

    .button_container {
        display: flex;
        gap: 10px;
        margin-top: 15px;

        button {
            padding: 10px 15px;
            border: none;
            border-radius: 5px;
            background-color: #3498db;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.3s ease;

            &:hover {
                background-color: #2980b9;
                transform: translateY(-2px);
            }

            &:active {
                background-color: #1c669e;
            }
        }

        button:last-of-type {
            background-color: #e74c3c;

            &:hover {
                background-color: #c0392b;
            }

            &:active {
                background-color: #a93226;
            }
        }
    }
}
