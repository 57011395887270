// SimpleDescription.module.scss

.simpleDescription {
    padding: 20px;
    // SimpleDescription.module.scss

    .simpleDescription {
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease;
    
      p {
        margin: 0 0 10px;
        line-height: 1.5;
        font-size: inherit;
      }
    
      .attributes {
        margin-top: 10px;
    
        .attribute {
          margin: 5px 0;
        }
      }
    
      &:hover {
        background-color: #e9e9e9;
      }
    }
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
  
    p {
      margin: 0;
      line-height: 1.5;
      font-size: inherit;
    }
  
    &:hover {
      background-color: #e9e9e9;
    }
  }
  