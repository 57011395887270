@import '../../../variables.scss';

.header {
  position: relative;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 0px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: $margin-medium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: calc(100% - $margin-medium * 2);

  .title {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    margin: 0;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: float 3s ease-in-out infinite;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}



