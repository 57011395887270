.splitScreenBanner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  
    .slide {
      display: flex;
      width: 100%;
      height: 100%;
  
      .imageContainer {
        flex: 1;
        overflow: hidden;
        height: 100%;
  
        .image, video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .textContainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        padding: 20px;
  
        .content {
          text-align: center;
  
          h2 {
            font-size: 2.5rem;
            margin: 0;
          }
  
          p {
            font-size: 1.2rem;
            margin: 10px 0;
          }
  
          .button {
            padding: 10px 20px;
            background: #00796b;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
  
            &:hover {
              background: #005f56;
            }
          }
        }
      }
    }
  }
  