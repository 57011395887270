.listContainer {
    margin: auto;
    padding: 20px;
  
    .reviewItem {
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      cursor: pointer;
  
      &:hover {
        .reviewSummary {
          background-color: #f5f5f5;
        }
      }
  
      .reviewSummary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        transition: background-color 0.3s;
  
        h3 {
          margin: 0;
          font-size: 18px;
        }
  
        .rating {
          font-size: 14px;
          color: #00796b;
        }
  
        .toggleIcon {
          font-size: 24px;
          color: #00796b;
        }
      }
  
      .reviewDetails {
        padding: 10px;
        animation: fadeIn 0.3s ease;
  
        p {
          margin: 0;
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  