.card {
    perspective: 1000px;
    width: 300px;
    height: 400px;
    margin: auto;
  
    &:hover .cardInner {
      transform: rotateY(180deg);
    }
  
    .cardInner {
      position: relative;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: transform 0.8s;
    }
  
    .cardFront,
    .cardBack {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  
    .cardFront {
      background-color: #fff;
      color: #333;
  
      .price {
        font-size: 24px;
        color: #00796b;
        margin-top: 10px;
      }
    }
  
    .cardBack {
      background-color: #00796b;
      color: white;
      transform: rotateY(180deg);
  
      .features {
        list-style: none;
        padding: 0;
      }
    }
  }
  