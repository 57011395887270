@import '../../../variables.scss';

.newsletterV3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 10px;
    background: linear-gradient(135deg, #ff6f61 50%, #d74ef1 50%);
    color: white;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: bounceIn 1s ease-in-out;
  }
  
  @keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }
    100% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
  
  .newsletterContent {
    max-width: 600px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    h2 {
      margin: 0 0 10px;
      font-size: 2rem;
      animation: fadeIn 1s ease-in-out 0.5s forwards;
      opacity: 0;
    }
  
    p {
      margin: 0 0 20px;
      font-size: 1.2rem;
      animation: fadeIn 1s ease-in-out 0.7s forwards;
      opacity: 0;
    }
  
    input {
      padding: 10px;
      border: none;
      border-radius: 5px;
      margin-right: 10px;
      font-size: 1rem;
    }
  
    button {
      margin-top: $margin-medium;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background: white;
      color: #ff6f61;
      cursor: pointer;
      font-size: 1rem;
      transition: background 0.3s, transform 0.3s;
      animation: fadeIn 1s ease-in-out 0.9s forwards;
      opacity: 0;
  
      &:hover {
        background: #ff6f61;
        color: white;
        transform: scale(1.1);
      }
    }
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  