@use '../../../variables.scss' as *;

.tabContainer {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.tabList {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    height: 48px; /* Increased height for better visibility */
    background-color: #f4f4f4; /* Light grey background for contrast */
    border-radius: 8px 8px 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* To ensure rounded corners are properly clipped */
}

.tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    font-weight: 500;
    color: #666;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
    background-color: transparent;
    user-select: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &:hover {
        background-color: rgba(0, 123, 255, 0.1); /* Light blue hover effect */
        color: #007bff; /* Consistent with the hover color */
    }

    &.active {
        background-color: #007bff; /* Active tab color */
        color: #fff;
        font-weight: bold;
    }
}

.tabContent {
    margin-top: $margin-medium;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 0 8px 8px 8px;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: rgba(0, 123, 255, 0.15); /* Subtle blue overlay */
    border-radius: 8px 8px 0 0;
    z-index: 0;
    transition: transform 0.4s ease, width 0.4s ease;
    transform: translateX(var(--tab-offset, 0));
    pointer-events: none;
    will-change: transform, width; /* Ensures smooth animations */
}
