.glassCard {
    padding: 30px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    animation: fadeIn 0.7s ease-out;
  
    .title {
      font-size: 1.6em;
      color: #fff;
      margin-bottom: 25px;
      text-align: center;
    }
  
    .lineItem {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      font-size: 1.1em;
      color: #fff;
    }
  
    .total {
      display: flex;
      justify-content: space-between;
      font-size: 1.5em;
      font-weight: bold;
      margin-top: 30px;
      color: #fff;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  