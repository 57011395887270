.paymentCard {
    padding: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
  
    h2 {
      margin-bottom: 20px;
      font-size: 1.6em;
      color: #444;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      .methodGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 15px;
        margin-bottom: 20px;
      }
  
      .methodItem {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 2px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
        transition: border-color 0.3s;
  
        input {
          display: none;
        }
  
        &.selected {
          border-color: #28a745;
        }
  
        .methodName {
          font-size: 1em;
          color: #555;
        }
      }
  
      button {
        padding: 12px;
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #218838;
        }
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  