// AnalyticCard.module.scss

@import '../../../../variables.scss';

.card {
  display: flex;
  background-color: white;
  align-items: center;
  color: #0f3460;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  z-index: 2;
  cursor: pointer;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3), 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  .icon {
    top: 50%;
    left: 25%;
    font-size: 50px;
    z-index: 0;
    opacity: 0.7;
    color: $secondary-color;
  }
}

.card_content {
  position: relative;
  z-index: 1;
  &.confirmed {
    color: green
  }
  &.pending {
    color: orange
  }
  &.cancelled {
    color: red
  }
  h2 {
    color: #0f3460;
    margin-bottom: 15px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  p {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }
}
