@import '../../../variables.scss';

$color-1: #0f3460;  // Main headings, primary text, key information
$color-3: #e94560;  // Accent elements, alerts, important notifications
$color-border: hsl(0, 0%, 88%);  // Borders, dividers, and outlines
$color-4: #4bc0c0;  // Secondary action buttons, links, highlights

.orderDetailPage {
    border-radius: 10px;
    .content {
        width: calc(100%);
    }
    h1, h2 {
        color: $color-1;
    }

    .header, .orderedItems, .shippingInfo, .emailNotification, .comments, .actions {
        margin-bottom: 20px;
    }

    .orderInfo, .itemsList, .formGroup, .itemDetails {
        display: flex;
        flex-direction: column;
    }

    .orderInfo p, .itemDetails p {
        margin: 5px 0;
    }

    .itemsList {
        display: flex;
        overflow-x: auto;

        .item {
            background-color: #f9f9f9;
            border: 1px solid $color-border;
            border-radius: 10px;
            padding: 10px;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 150px;

            img {
                max-width: 100px;
                margin-bottom: 10px;
            }

            .itemDetails {
                text-align: center;
            }
        }
    }

    .formGroup {
        margin-bottom: 15px;

        label {
            margin-bottom: 5px;
            color: $color-1;
        }

        input, select, textarea {
            padding: 10px;
            border: 1px solid $color-border;
            border-radius: 5px;
            font-size: 14px;
            width: 100%;
        }
    }

    .emailNotification textarea, .comments textarea {
        height: 100px;
    }

    .actions {
        display: flex;
        justify-content: space-between;

        button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }

        .exportButton {
            background-color: $color-4;
            color: white;
        }

        .exportButton:hover {
            background-color: darken($color-4, 10%);
        }
    }

    .editButton, .deleteButton {
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    .editButton {
        background-color: $color-4;
        color: white;
        margin-right: 10px;
    }

    .editButton:hover {
        background-color: darken($color-4, 10%);
    }

    .deleteButton {
        background-color: $color-3;
        color: white;
    }

    .deleteButton:hover {
        background-color: darken($color-3, 10%);
    }
}
