.imagesList {
    .imageGrid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
    }
  
    .imageCard {
      background-color: #fff;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
  
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }
  
      .thumbnail {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 8px;
      }
  
      .imageInfo {
        text-align: center;
        margin-top: 10px;
  
        h4 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
  
        p {
          font-size: 0.9rem;
          color: #666;
        }
      }
    }
  }
  