@import '../../../variables.scss';


.orderHistory {  
    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: #333;
    }
  
    .orders {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  
    .orderCard {
      background-color: #fff;
      border-radius: 12px;
      padding: 1.5rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      cursor: pointer;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }
  
      .orderHeader {
        display: flex;
        align-items: center;
        gap: 1rem;
  
        .orderIcon {
          font-size: 2.5rem;
          margin-right: 1rem;
        }
  
        .iconDelivered {
          color: #4caf50;
        }
  
        .iconShipped {
          color: #ff9800;
        }
  
        .orderInfo {
          h3 {
            margin: 0;
            font-size: 1.6rem;
            color: #333;
          }
  
          .orderDetails {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 1.2rem;
            color: #555;
            margin-top: $margin-medium;
            .iconDate {
              color: #777;
            }
          }
  
          .orderStatus {
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;
            margin-top: $margin-medium;
  
            span {
                margin: 0px;
              color: #555;
            }
          }
        }
      }
  
      .items {
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        overflow-x: auto;
  
        .itemCard {
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: #f9f9f9;
          padding: 1rem;
          border-radius: 8px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  
          .itemImage {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 8px;
          }
  
          .itemInfo {
            h4 {
              margin: 0;
              font-size: 1.2rem;
              color: #333;
            }
  
            p {
              margin: 0.5rem 0 0;
              font-size: 1rem;
              color: #777;
            }
          }
        }
      }
    }
  }
  