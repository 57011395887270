@import '../../../../variables.scss';


.discountCodeInfo{
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 100%;

    h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: #333;
    }
    .row_code {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: flex-end;
        margin: 0px;      
        .icon {
          margin: 0px;
          height: 40px;
          text-align: center;
          border: 1px solid $border-color;
          width: 40px;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: gray;
          cursor: pointer;
          transition: color $animation-duration1 linear;
          &:hover {
            color: black
          }
        }
      }
    .row_calendar {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px
      }
      .checkbox {
        margin-top: $margin-medium;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
     
}