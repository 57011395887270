.priceRangeContainer {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    animation: growIn 0.5s ease;
  
    h3 {
      margin-bottom: 10px;
      color: #333;
    }
  
    .sliderContainer {
      display: flex;
      align-items: center;
      gap: 10px;
  
      .slider {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        background: #ddd;
        height: 5px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
  
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          background: #007bff;
          border-radius: 50%;
          cursor: pointer;
          transition: background 0.3s ease;
  
          &:hover {
            background: #0056b3;
          }
        }
      }
    }
  
    .priceLabels {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      color: #555;
    }
  }
  