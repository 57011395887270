@use '../../../variables.scss' as *;

$pastel-colors: (
  pastel-blue: #a8d5e2,
  pastel-green: #b8e4c9,
  pastel-pink: #f5c6d5,
  pastel-purple: #d4c4fb,
  pastel-yellow: #fcefb4,
);

.badges_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.badge_container {
    display: flex;
    position: relative;
    cursor: pointer;
  &:hover .deleteIcon {
    display: block;
  }
}

.badge {
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  white-space: nowrap;
  @each $color-name, $color-value in $pastel-colors {
    &.#{$color-name} {
      background-color: $color-value;
    }
  }
}

.delete_icon {
  display: none;
  height: 16px;
  text-align: center;
  font-size: 14px;
  align-self: center;
  align-content: center;
  vertical-align:middle;
  color: #fff;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  margin-left: $margin-small;
}

.badge_container:hover .delete_icon {
    display: inline;
}

.default_color {
  background-color: #d4c4fb; // Default pastel color if none is provided
}
