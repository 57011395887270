@import '../../../../variables.scss';

.footerLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    margin-top: 20px;
}

.footerLinksTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.footerLinksList {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footerLink {
    position: relative;
    color: white;
    font-size: 1rem;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #ff6f61;
        transition: width 0.3s ease;
    }
    &:hover::after {
        width: 100%;
    }
    &:hover {
        color: #ff6f61;
        transform: translateY(-3px);
    }
}
