.companyOverview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background: linear-gradient(135deg, #f0f0f0, #ffffff);
    border-radius: 20px;
    animation: fadeIn 0.5s ease-out;
  
    .glassCard {
      backdrop-filter: blur(10px);
      background: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      padding: 30px;
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
      max-width: 800px;
      text-align: center;
      transition: transform 0.3s;
  
      &:hover {
        transform: translateY(-10px);
      }
  
      .image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 20px;
        object-fit: cover;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
  
      .content {
        .title {
          font-size: 2em;
          margin-bottom: 10px;
          color: #333;
        }
  
        .description {
          font-size: 1em;
          color: #555;
          margin-bottom: 10px;
        }
  
        .mission {
          font-style: italic;
          color: #007bff;
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  