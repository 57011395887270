@use '../../../variables.scss' as *;

.search_bar {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0px 2px;
  width: 100%;
  min-width: 100px;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.search_input {
  flex: 1 1 auto;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
  background-color: transparent;
  color: #333;
  min-width: 0;
  &::placeholder {
    color: #666;
  }
}

.search_button {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #333;
  width: 100px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
  flex-shrink: 0;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}