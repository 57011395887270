.search_bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    transition: box-shadow 0.3s ease;
    width: calc(100% - 20px * 2);

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}

.search_icon {
    font-size: 20px;
    color: #888;
    margin-right: 10px;
}

.search_input {
    border: none;
    outline: none;
    font-size: 16px;
    flex: 1;
}
