.returnShipment {
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 100%;

    h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: #333;
    }

    .formGroup {
        margin-bottom: 15px;   

    .saveButton {
        padding: 12px 24px;
        background-color: #4a90e2;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 1em;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    
        &:hover {
          background-color: darken(#4a90e2, 10%);
        }
    
        &:focus {
          outline: none;
          box-shadow: 0 0 0 3px rgba(#4a90e2, 0.3);
        }
    }
 }
}
