@use '../../../variables.scss' as *;

#button_4 {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        top: 0;
        left: 0;
        transform: scale(0);
        transition: transform 0.6s ease-out;
        border-radius: 50%;
        opacity: 0;
    }
    &:active::after {
        transform: scale(4);
        opacity: 1;
        transition: none;
    }
}

