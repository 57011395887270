.customerNav {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(135deg, #1f3b73 0%, #1e88e5 100%); /* Elegant blue gradient */
  padding: 6px; /* Reduced padding for a smaller look */
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  width: calc(100%);
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: space-evenly;

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem; /* Smaller padding for compact tabs */
      font-size: 0.9rem; /* Reduced font size */
      color: #fff;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
      border-radius: 6px;
      position: relative;
      
      svg {
        margin-right: 0.4rem;
        font-size: 1.2rem; /* Smaller icons */
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.15); /* Subtle hover effect */
        transform: translateY(-2px); /* Slight lift on hover */
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: transparent;
        transition: background-color 0.3s ease;
      }

      &:hover::before {
        background-color: #fff; /* Underline animation on hover */
      }
    }

    .active {
      background-color: rgba(0, 0, 0, 0.15); /* Darker background for active tab */
      color: #fff;
      transform: translateY(0); /* No movement for active tab */
      
      &::before {
        background-color: #fff; /* Underline for active tab */
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.2); /* Slightly darker hover for active */
      }
    }
  }

  @media (max-width: 768px) {
    ul {
      flex-direction: row;
      justify-content: space-between;

      li {
        padding: 0.4rem 0.8rem; /* Reduced padding for smaller screens */
        font-size: 0.8rem; /* Even smaller font size for mobile */
      }

      svg {
        font-size: 1rem; /* Smaller icons for mobile */
      }
    }
  }
}
