@import '../../../variables.scss';

.editorContainer {
    width: calc(100% - $margin-medium * 2);
    margin: 0 auto;
    padding: $margin-medium;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;

    h1 {
        text-align: center;
        color: #333;
        margin-bottom: 20px;
    }

    .editorSection {
        margin-bottom: 40px;

        .ql-container {
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .ql-toolbar {
            border-radius: 4px 4px 0 0;
        }

        .ql-editor {
            min-height: 200px;
            border-radius: 0 0 4px 4px;
        }
    }

    .previewSection {
        h2 {
            color: #333;
            border-bottom: 2px solid #ddd;
            padding-bottom: 10px;
            margin-bottom: 20px;
        }

        .previewContent {
            padding: 20px;
            background-color: #f9f9f9;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            min-height: 200px;

            p {
                margin-bottom: 10px;
                line-height: 1.6;
            }
        }
    }
}
