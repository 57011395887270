.grid_container {
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow:dense;
}

.item {
  aspect-ratio: 1;
  background: #ccc;
  display: flex;
  border-radius: 8px;
  transition: transform 0.3s ease;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  &:hover {
    transform: scale(1.05);
  }
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      padding: 10px;
      text-align: center;
    }
}

@media screen and (min-width: 40em) and (max-width: 63.99875em) {
  /* 640 ~ 1023 */
  .grid_container {
    grid-template-columns: repeat(3, 1fr);
  }
  .item:nth-child(6n + 1),
  .item:nth-child(6n + 6){
    grid-area: span 2/span 2;
  }
  .item:nth-child(6n + 5) {
    grid-column: 1;
  }

}

@media print, screen and (min-width: 64em) {
  /* 1024+ */
  .grid_container {
    grid-template-columns: repeat(4, 1fr);
  }
  .item:nth-child(10n + 1),
  .item:nth-child(10n + 10){
    grid-area: span 2/span 2;
  }
  .item:nth-child(10n + 8) {
    grid-column: 1;
  }
  .item:nth-child(10n + 9) {
    grid-column: 2;
  }
}




















