@import '../../../variables.scss';

.editorContainer {
  .ql-toolbar {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
    padding: 10px;
  }

  .ql-container {
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding: 10px;
  }

  .ql-editor {
    min-height: 200px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: #333;
  }

  .ql-editor.ql-blank::before {
    color: #999999;
  }

  .ql-editor:focus {
    border: 1px solid #1e88e5; // Modern blue border on focus
  }

  .label {
    font-size: $font-size-base;
    pointer-events: none;
    text-transform: capitalize;
    text-align: left;
    font-size: $font-size-base;
    font-weight: 600;  // Updated to medium weight
    pointer-events: none;
    margin-bottom: $margin-small;
    color: black
}
}
