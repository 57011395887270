// DisplayRating.module.scss

$primary-color: #ffcc00;
$secondary-color: #cccccc;
$star-size: 24px;

.ratingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  .filledStar {
    color: $primary-color;
    font-size: $star-size;
    margin-right: 2px;
  }

  .halfStar {
    color: $primary-color;
    font-size: $star-size;
    margin-right: 2px;
  }

  .outlineStar {
    color: $secondary-color;
    font-size: $star-size;
    margin-right: 2px;
  }
}
