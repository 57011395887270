@import '../../../../variables.scss';

.social_media_edit {
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px; // Adjusted from $margin-medium for standalone use
  min-width: 500px;
  background-color: white;
  flex: 1 1 0; // Allows the form to grow and shrink
  max-width: 100%; // Ensures it doesn't e

  .switch_container {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    align-items: center;
    font-weight: bold;
  }

  .button_container {
    display: flex;
    margin-top: $margin-medium;
    gap: $margin-medium;
  }
}
  
  