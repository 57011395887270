.ribbonContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .ribbon {
    position: relative;
    background: #00796b;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
  
    &:hover {
      background: #004d40;
      transform: scale(1.05);
    }
  
    span {
      font-size: 16px;
    }
  
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background: inherit;
    }
  
    &:before {
      left: -10px;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
    }
  
    &:after {
      right: -10px;
      clip-path: polygon(0 100%, 0 0, 100% 0);
    }
  }
  