@import '../../../../variables.scss';


.validateRefundContainer {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: $margin-medium;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - $margin-medium * 2);
    margin: 1rem auto;
    h2 {
        color: #0f3460;
        margin-bottom: 20px;
    }
}

.validateRefundContainer h3 {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.2rem;
}

.inputGroup {
    width: 100%;
    margin-bottom: 1rem;
}

.inputGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #333;
}

.refundInput, .infoTextarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.refundInput:focus, .infoTextarea:focus {
    border-color: #007BFF;
    outline: none;
}

.infoTextarea {
    height: 100px;
    resize: none;
}

.button1 {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.button1:hover {
    background-color: #0056b3;
}
