.emailTemplateEditor {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.editorSidebar {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.previewSection {
    flex: 2;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.previewTitle {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #333;
}

.previewContent {
    height: 100%;
}

.field {
    margin-bottom: 20px;
}

.select:focus, .input:focus, .textarea:focus {
    border-color: #007bff;
    outline: none;
}

.textarea {
    min-height: 100px;
    resize: vertical;
}

.placeholderRow {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.placeholderRow .input {
    flex: 1;
}
