.search_bar {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
    &:focus-within {
        border-color: #007bff;
    }
    .search_input {
        flex: 1 1 auto;
        border: none;
        outline: none;
        font-size: 16px;
        background-color: transparent;
        color: #333;
        min-width: 0;
        &::placeholder {
          color: #666;
        }
      }
    
    .search_icon {
        color: #CCC;
    }
      
      
    .search_input::placeholder {
        color: #cccccc;
    }
}
  
  