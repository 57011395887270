.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s;
    animation: fadeIn 0.5s ease-out;
  
    &:hover {
      transform: translateY(-5px);
    }
  
    .photo {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  
    .info {
      padding: 20px;
  
      h2 {
        margin: 0;
        font-size: 1.5em;
      }
  
      .position {
        color: #888;
        margin: 10px 0;
      }
  
      .bio {
        font-size: 0.9em;
        color: #555;
      }
  
      .socialLinks {
        margin-top: 10px;
  
        a {
          margin: 0 5px;
          font-size: 1.2em;
          color: #555;
          transition: color 0.3s;
  
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  