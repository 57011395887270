$primary-color: #6a11cb;
$secondary-color: #2575fc;

#button_10 {
    display: inline-block;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(45deg, $primary-color, $secondary-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    transition: transform 0.5s ease;
    &:hover {
        transform: rotate(360deg);
    }

}