@import '../../../variables.scss';

.subscriber_form {
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .form_group {
    width: 100%;
    margin-bottom: 15px;
  }
  .button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  