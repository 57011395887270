.container {
  background-color: #1f2937; /* Dark background for contrast with chart */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Elevation for a clean, modern look */
  margin: 20px auto;
  max-width: 800px; /* Optional max-width for centering and controlling size */
  color: white; /* Ensure text is readable on dark background */

  h3 {
      margin-bottom: 20px;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      color: #e2e8f0 !important; /* Slightly lighter color for title */
  }

  .chart_container {
      position: relative;
      height: 400px; /* Fixed height to control the size of the chart */
      width: 100%;
  }
}
