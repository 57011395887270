@use '../../../variables.scss' as *;

.alert_dialog_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .alert_dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    min-width: 500px;
  }
  
  .alert_dialog_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        margin: 0;
        font-size: 24px;
        margin-left: $margin-medium;
        margin-top: $margin-small;
      }
  } 
  
  .alert_dialog_content p {
    margin: 16px 0;
    font-size: 16px;
    text-align: center;
  }

  .alert_icon {
    color: green;
    background-color: rgba( green, 0.2);
    border-radius: 100%;
    padding: 10px;
    display: inline;
  }

  .alert_dialog_actions {
    display: flex;
    justify-content: flex-end;
    .button {
        width: 100%;
        border-radius: 6px;
        background-color: $primary-color;
        font-size: $font-size-base;
        font-weight: bold;
        color: white;
        padding: $margin-small 0px;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: rgba($color: $primary-color, $alpha: 0.7);
        }
    }  
}
  
