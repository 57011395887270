@import '../../../variables.scss';

.content {
    padding: $margin-medium;
    width: calc(100% - $margin-medium * 2);
}

.email_template_list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: scroll;
    padding: 10px;
    scroll-behavior: smooth;
    white-space: nowrap;
    width: 100%;
    
    &::-webkit-scrollbar {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #aaa;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 10px;
    }
}

.template_item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: calc(50% - 20px); /* Two items per row with a 20px gap */
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    white-space: normal;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    h3 {
        margin: 0 0 10px;
        font-size: 18px;
        color: #333;
    }

    p {
        margin: 0 0 10px;
        font-size: 14px;
        color: #666;
    }

    button {
        padding: 10px;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
            background-color: #2980b9;
            transform: translateY(-2px);
        }

        &:active {
            background-color: #1c669e;
        }
    }
}
