@import '../../../variables.scss';

.textAreaWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: calc(100% - $margin-small * 2);
    margin-top: $margin-medium;
}

.label {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    transition: color 0.3s ease;
    font-weight: 600;
}

.textArea {
    width: 100%;
    padding: $margin-small;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    outline: none;
    min-height: 200px;
    background-color: #f9f9f9;
    
    &:hover {
        border-color: #bbb;
    }

    &:focus {
        border-color: #888;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        background-color: #fff;
    }

    &.error {
        border-color: #e74c3c;
    }
}

.errorText {
    margin-top: 8px;
    font-size: 14px;
    color: #e74c3c;
}
