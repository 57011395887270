.container {
  margin: 30px 0px;
}
.testimonialV1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    overflow: hidden;
    animation: fadeIn 1s ease-in-out;
    
    
  }

  .title {
    font-size: 1.8rem; // Slightly larger than default
    font-weight: 600; // Bold
    text-transform: uppercase; // Optional: Uppercase for emphasis
    color: #333; // Darker color for better readability
    border-bottom: 2px solid #e0e0e0; // Optional: Adds a clean underline
    padding-bottom: 10px; // Space between title and content
    margin-bottom: 20px; // Space between the title and the next element
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .testimonialCard {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  
    .testimonialContent {
      text-align: center;
  
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 10px;
        object-fit: cover;
      }
  
      .name {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 5px;
        color: #333;
      }
  
      .role {
        font-size: 1rem;
        margin-bottom: 10px;
        color: #777;
      }
  
      .text {
        font-size: 1rem;
        color: #555;
      }
    }
  }
  