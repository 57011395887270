/* CustomerSecurity.module.scss */

@import '../../../../variables.scss';

.security_info {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: $margin-medium;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 0;
      font-size: 24px;
      color: $primary-color;
    }

    .actions {
      display: flex;
      gap: 10px;

      button {
        padding: 8px 12px;
        background-color: $primary-color;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9em;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }
    }
  }

  .info_list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .info_item {
      display: flex;
      align-items: flex-start;
      padding: 10px 0;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      .label {
        font-weight: bold;
        width: 200px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: black;

        svg {
          color: $primary-color;
        }
      }

      .value {
        flex: 1;
        color: black;

        ul {
          list-style: disc;
          margin: 0;
          padding-left: 20px;
        }
      }

      .verified {
        color: green;
        font-weight: bold;
      }

      .notVerified {
        color: red;
        font-weight: bold;
      }

      .enabled {
        color: green;
        font-weight: bold;
      }

      .disabled {
        color: red;
        font-weight: bold;
      }
    }
  }
}
