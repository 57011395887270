.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    margin-top: 20px;
    gap: 20px;
    .permissions {
        padding: 20px;
        margin-top: 20px;
        border-radius: 10px;
        background: linear-gradient(135deg, #222831, #393e46);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        position: relative;
        overflow: hidden;
      
        // Anime glow and border effect
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
          background: linear-gradient(45deg, #ff6ec7, #4f9eff, #ffb64d);
          z-index: -1;
          filter: blur(8px);
          opacity: 0.8;
          animation: borderGlow 6s ease infinite;
        }
      
        // Anime-style border glow
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.3);
          animation: borderPulse 3s ease-in-out infinite;
        }
      
        h4 {
          font-family: 'Roboto', sans-serif;
          color: #ffffff;
          font-weight: 700;
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
      
        .permission-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
          border-radius: 8px;
          background: rgba(34, 40, 49, 0.8);
          margin-bottom: 10px;
          color: #eeeeee;
          transition: transform 0.3s ease, background 0.3s ease;
      
          &:hover {
            transform: translateY(-2px);
            background: rgba(34, 40, 49, 0.9);
          }
        }
      
        .switch {
          margin-left: auto;
          display: flex;
          align-items: center;
          transform: scale(1.1);
        }
      }
      
      // Keyframes for the animations
      @keyframes borderGlow {
        0%, 100% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
      }
      
      @keyframes borderPulse {
        0%, 100% {
          border-color: rgba(255, 255, 255, 0.3);
        }
        50% {
          border-color: rgba(255, 255, 255, 0.6);
        }
      }
      
}