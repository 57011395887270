/* SendEmailToCustomer.module.scss */

@import '../../../../variables.scss';

.sendEmailToCustomer {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
  h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8em;
  }

  .emailForm {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .formGroup {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        margin-bottom: 8px;
        color: #333;
      }

      input[type='text'],
      input[type='email'],
      select {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        font-size: 1em;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
        }
      }

      .invalid {
        border-color: #f44336;
      }

      .error {
        color: #f44336;
        font-size: 0.9em;
        margin-top: 5px;
      }

      .loading {
        color: #666;
        font-size: 1em;
      }
    }

    .ql-container {
      border-radius: 8px;
    }

    .ql-toolbar {
      border-radius: 8px 8px 0 0;
    }

    .ql-editor {
      min-height: 200px;
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-start;

      .sendButton {
        margin: 0px;
        background-color: $primary-color;
        color: #fff;
        border: none;
        padding: 12px 24px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1em;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($primary-color, 10%);
        }

        &:disabled {
          background-color: #6c757d;
          cursor: not-allowed;
        }
      }
    }
  }
}
