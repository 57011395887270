@import '../../../variables.scss';

.newsletterV1 {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 30px;  // Increased padding for more space
    border-radius: 10px;
    background: linear-gradient(135deg, #74ebd5, #acb6e5); /* Soft pastel gradient */
    color: white;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeInStart 1s ease-in-out;
    max-width: 100%; // Limit the width to prevent it from being too wide
    margin: 0 auto; // Center on the page
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes fadeInStart {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.newsletterContent {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    gap: 20px; // Add consistent spacing between the elements
  
    h2 {
        margin: 0;
        font-size: 2.5rem; // Slightly larger for more emphasis
        animation: fadeIn 1s ease-in-out 0.5s forwards;
        opacity: 0;
    }

    p {
        margin: 0;
        font-size: 1.2rem;
        animation: fadeIn 1s ease-in-out 0.7s forwards;
        opacity: 0;
        line-height: 1.5; // Better readability for the paragraph
        max-width: 500px; // Prevent text from spanning too wide
    }

    // Form container for better alignment of input and button
    .formContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px; // Space between input and button
        width: 100%; // Take up the full width of the container
    }
  
    input {
        padding: 12px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        width: 70%; // Ensure the input takes up more space
        max-width: 400px; // Prevent the input from becoming too large
    }

    button {
        padding: 12px 25px;
        border: none;
        border-radius: 5px;
        background: white;
        color: #ff6f61;
        cursor: pointer;
        font-size: 1rem;
        transition: background 0.3s, transform 0.3s;
        animation: fadeIn 1s ease-in-out 0.9s forwards;
        opacity: 0;

        &:hover {
            background: #ff6f61;
            color: white;
            transform: scale(1.05); // Slight hover effect, less aggressive
        }
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
