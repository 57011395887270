@import '../../../variables.scss';

.social_media_settings_page {
    width: calc(100%);
  }
  
.social_media {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    gap: $margin-small;
}