@import '../../../variables.scss';

.saveButton {
    background-color: $accent-color; // Use your defined color or any accent color
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease, transform 0.3s ease;
    outline: none;
    margin: 0px;
    min-width: 200px;
    width: 50%;
    &:hover {
      background-color: darken($accent-color, 10%);
      transform: translateY(-2px);
    }
  
    &:disabled {
      background-color: lighten($accent-color, 20%);
      cursor: not-allowed;
    }
  }
  
  .loading {
    // Disable hover effect during loading
    pointer-events: none;
  
    &:hover {
      transform: none;
    }
  }
  
  .loader {
    border: 4px solid white; /* Light border color */
    border-top: 4px solid $primary-color; /* Primary color for loader */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite; /* Spinning animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  