@import '../../../../variables.scss';

.faqForm {  
    .field {
      margin-bottom: 20px;
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      gap: $margin-medium;
    }
  }

  .submitButton {
    background-color: $secondary-color;
  }
  .cancelButton {
    background-color: $danger-color;
  }
  .submitButton, .cancelButton{
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for shadow and transform */
  
    /* Initial box-shadow for a slight elevation effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
      transform: translateY(-2px); /* Slight lift effect on hover */
    }
  
    &:active {
      transform: translateY(0); /* Reset lift effect when the button is pressed */
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Slightly reduced shadow on active state */
    }
  }