@import '../../../variables.scss';

$modal-width: 400px;
$transition-duration: 0.3s;
$background-color: #fff;
$button-radius: 8px;
$shadow-color: rgba(0, 0, 0, 0.15);
$overlay-opacity: 0.7;

.filterButton {
  background-color: $primary-color;
  color: $background-color;
  border: none;
  border-radius: $button-radius;
  padding: 12px 24px;
  cursor: pointer;
  box-shadow: 0 4px 12px $shadow-color;
  transition: transform $transition-duration ease, background-color $transition-duration ease, box-shadow $transition-duration ease;
  margin: 0px;
  white-space: nowrap;
  width: auto;
  &:hover {
    background-color: darken($primary-color, 10%);
    transform: translateY(-2px);
    box-shadow: 0 6px 16px $shadow-color;
  }
}

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $background-color;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -4px 15px $shadow-color;
  transition: transform 0.3s ease;
  transform: translateY(100%);
  z-index: 1000;
  overflow: hidden;
  &.open {
    transform: translateY(0);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, $overlay-opacity);
  transition: opacity $transition-duration ease;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  z-index: 999;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.modalContent {
  padding: 20px;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #999;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }
}

.selectedChips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  .chip {
    display: flex;
    align-items: center;
    background-color: lighten($primary-color, 40%);
    border-radius: 16px;
    padding: 8px 12px;
    font-size: 0.875rem;
    color: darken($primary-color, 20%);
    cursor: pointer;

    span {
      margin-left: 8px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.filterList {
  max-height: 300px;
  overflow-y: auto;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.2); // Semi-transparent white
  backdrop-filter: blur(10px); // Blur effect for glassmorphism
  border-radius: $button-radius;
  position: absolute; // Position it above the list content
  bottom: 0;
  width: calc(100% - 60px);
  z-index: 1001;

  .applyButton,
  .clearButton {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: $button-radius;
    transition: background-color $transition-duration ease;
  }

  .applyButton {
    background-color: $primary-color;
    color: white;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  .clearButton {
    background-color: #f8f9fa;
    color: $primary-color;

    &:hover {
      background-color: lighten($primary-color, 40%);
    }
  }
}
