.orderError {
    padding: 40px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.6s ease-out;
    text-align: center;
    color: #fff;
  
    .iconContainer {
      margin-bottom: 15px;
    }
  
    .icon {
      font-size: 3em;
      color: #ff5252;
    }
  
    h2 {
      font-size: 2em;
      margin-bottom: 10px;
      color: #ffebee;
    }
  
    p {
      font-size: 1.2em;
      color: #ffcccb;
      margin-bottom: 20px;
    }
  
    button {
      padding: 15px 30px;
      background-color: #ff5252;
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #e53935;
      }
    }
  
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  