.glassButton {
    padding: 15px 40px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    font-size: 1.2em;
    color: lightgrey;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, background 0.3s ease;
    animation: pulse 1.5s infinite;
  
    &:hover {
      background: rgba(255, 255, 255, 0.3);
      transform: translateY(-3px);
    }
  
    &:active {
      background: rgba(255, 255, 255, 0.1);
      transform: translateY(0);
    }
  
    @keyframes pulse {
      0% {
        box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
      }
      50% {
        box-shadow: 0 0 30px rgba(0, 123, 255, 0.7);
      }
      100% {
        box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
      }
    }
  }
  