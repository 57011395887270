@use '../../../variables.scss' as *;

.blogAnalyticsContainer {
  margin-top: $margin-medium;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  background-color: #f9f9f9; // Light background for contrast
}

.analyticsCard {
  flex: 1;
  min-width: 200px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
}

.iconContainer {
  background-color: $primary-color;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.icon {
  font-size: 24px;
  color: white;
}

.metricValue {
  font-size: 28px;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}

.metricLabel {
  font-size: 16px;
  color: #777;
}

.recentPost {
  text-align: left;
  margin-top: 10px;

  h4 {
    margin: 5px 0;
    font-size: 18px;
    color: black
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }

  .snippet {
    margin-top: 5px;
    font-size: 14px;
    color: #444;
  }
}
