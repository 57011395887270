@use '../../../../variables.scss' as *;

$border-color: #ccc;
$focus-border-color: $accent-color;
$input-padding: 10px;
$animation-duration: 0.3s;
$font-size-base: 1rem;
$input-height: 40px;

.input3 {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  height: 60px;

  .input {
    margin-top: 20px;
    width: 100%;
    height: $input-height;
    border: none;
    border-bottom: 1px solid $border-color;
    outline: none;
    font-size: $font-size-base;
    padding: 0px;
    transition: border-color $animation-duration ease;
    text-indent: 10px;
    background: none;

    &:focus {
      border-bottom: 2px solid $focus-border-color;
      outline: none;
    }

    &::placeholder {
      color: transparent;
    }
  }

  .label {
    position: absolute;
    top: calc(50% + 20px);
    left: 10px;
    transform: translateY(-50%);
    font-size: $font-size-base;
    color: grey;
    transition: color $animation-duration ease, top $animation-duration ease, transform $animation-duration ease;
    pointer-events: none;
    color: $focus-border-color;
    transform: translateY(-200%) translateX(-10px);
    font-size: 0.85rem;
    background-color: white;
    border-radius: 6px;
    padding: 2px 4px;
  }


  .border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    height: 2px;
    background: $focus-border-color;
    transition: width $animation-duration ease, left $animation-duration ease;
  }

  .input:focus ~ .border {
    width: 100%;
    left: 0;
  }
}
