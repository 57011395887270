@use '../../../../variables.scss' as *;

$border-color: #ccc;  // Light grey border color
$focus-border-color: #555;  // Muted grey for focus state
$input-background: #f9f9f9;  // Soft light grey background
$hover-border-color: #999;  // Slightly darker grey for hover state
$box-shadow-color: rgba(0, 0, 0, 0.1);  // Subtle shadow for input
$label-color: #333;  // Dark grey for label color
$animation-duration: 0.3s;
$input-padding: 8px;

.calendarInputContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 300px;
    font-family: 'Arial', sans-serif;
    margin-top: $margin-medium;
}

.input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 10px $box-shadow-color;  // Subtle shadow for elevation
    border-radius: 6px;  // Smooth rounded corners
    transition: box-shadow $transition-duration ease, transform $transition-duration ease, border-color 0.3s ease;
    background-color: $input-background;  // Soft light grey background
    cursor: pointer;
    color: grey !important;
    height: $input-height;
    padding: 6px;

    .dateInput {
        border: none;
        outline: none;
        font-size: $font-size-base;
        width: calc(100% - 80px);  // Adjusted width calculation for consistency
        text-indent: 8px;
        background-color: transparent;  // Transparent background to blend with the wrapper
        color: #333;  // Dark grey text color
        box-shadow: none;
        transition: color 0.3s ease, background-color 0.3s ease;
        
        &:focus {
            color: $focus-border-color;  // Darker grey text on focus
        }
    }

    .icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        color: #888;
        width: 40px;
    }
}

.input_wrapper:focus-within {
    border-color: $focus-border-color;  // Muted grey border on focus
    box-shadow: 0 0 8px rgba(85, 85, 85, 0.5);  // Subtle shadow on focus
}

.input_wrapper:hover {
    border-color: $hover-border-color;  // Darker grey border on hover
}

.calendarPopup {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 10;
    background: white;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px $box-shadow-color;  // Subtle shadow for the popup
    width: 100%;
    max-width: 300px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
}

.calendarPopup.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.label {
    text-align: left;
    font-size: $font-size-base;
    font-weight: 600 !important;  // Updated to medium weight
    color: $label-color;
    pointer-events: none;
    transition: color $animation-duration;
    margin-bottom: $input-padding;
}


input:focus + .label {
    color: $focus-border-color;  // Darker grey label color on focus
}
