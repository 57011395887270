.imageEditPage {
    background-color: #f9f9f9;  
    .imagePreview {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
  
      .img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 400px; /* Restrict the height to avoid long images */
        object-fit: cover; /* Ensures the image covers the available space while keeping aspect ratio */
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visuals */
        transition: transform 0.3s ease;
  
        &:hover {
          transform: scale(1.05); /* Adds a zoom effect on hover */
        }
      }
    }
  
    .content {
      width: calc(100% - 40px);
      margin: 20px auto;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
      .inputFileContainer {
        margin-top: 20px;
      }
  
      input[type="file"] {
        padding: 0;
      }
  
      .usageContainer {
        margin-top: 20px;
      }
    }
  
    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
  
      .deleteButton {
        background-color: #e74c3c;
        color: white;
        padding: 10px 20px;
        border-radius: 6px;
        cursor: pointer;
        border: none;
  
        &:hover {
          background-color: #c0392b;
        }
      }
    }
  }
  