.companyOverview {
    position: relative;
    height: 400px;
    overflow: hidden;
    border-radius: 15px;
    animation: fadeIn 0.5s ease-out;
  
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      filter: brightness(0.5);
      transform: scale(1.1);
      transition: transform 0.5s;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      text-align: center;
      transition: background 0.3s;
  
      .content {
        .title {
          font-size: 2.5em;
          margin-bottom: 10px;
        }
  
        .description {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
  
        .mission {
          font-style: italic;
          color: #00bfff;
        }
      }
    }
  
    &:hover .background {
      transform: scale(1.05);
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  