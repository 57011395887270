.orderError {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: bounceIn 0.5s ease-out;
    text-align: center;
  
    .iconContainer {
      margin-bottom: 15px;
  
      .icon {
        font-size: 4em;
        color: #e57373;
      }
    }
  
    h2 {
      font-size: 2em;
      color: #e53935;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 1.2em;
      color: #666;
      margin-bottom: 20px;
    }
  
    button {
      padding: 12px 25px;
      background-color: #e53935;
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #d32f2f;
      }
    }
  
    @keyframes bounceIn {
      from {
        opacity: 0;
        transform: scale(0.95);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  