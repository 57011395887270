@use '../../../variables.scss' as *;


.reviewContainer {
    padding: $margin-medium;
    .title {
        text-align: center;
        font-size: $font-size-title;
    }
    .description {
        margin-top: $margin-small;
        text-align: center;
        font-size: $font-size-base;
        color: grey;
    }
    .cardList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $margin-medium;
    }
}

.reviewCard {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    width: 250px;
    .review-card.highlight {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    .reviewCardHeader {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    
    .reviewCardAvatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
    
    .reviewCardUser {
      margin: 0;
    }
    
    .reviewCardRating {
      display: flex;
      align-items: center;
    }
    
    .reviewCardText {
      margin: 10px 0;
      color: #555;
    }
    
    .reviewCardDate {
      font-size: 0.9em;
      color: #999;
    }
  }
  
  